import React, { useState, useCallback, useEffect, useRef } from 'react';
import itemService from '../../../services/db/ItemService';
import TeamSummary from './TeamSummary';
import UserList from './UserList';
import TeamMemberList from './TeamMemberList';
import { formatDate } from '../../../utils/dateUtils';

const TeamFormation = ({ onComplete, milestoneId, readOnly }) => {
    const [team, setTeam] = useState([]);
    const [users, setUsers] = useState([]);
    const [companyRelations, setCompanyRelations] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [friends, setFriends] = useState([]);
    
    const [teamSummary, setTeamSummary] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const teamRef = useRef(team);

    useEffect(() => {
        teamRef.current = team;
    }, [team]);
    const calculateTeamSummary = useCallback((teamMembers) => {
        const activeMembers = teamMembers.filter(member => !member.leaveDate);
        const roles = activeMembers.map(member => member.roleTypeName).filter(Boolean);
        const uniqueRoles = [...new Set(roles)];
        
        return {
            memberCount: activeMembers.length,
            roles: uniqueRoles,
            averageHourlyRate: activeMembers.reduce((sum, member) => sum + (member.hourlyRate || 0), 0) / activeMembers.length || 0
        };
    }, []);
    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                if (milestoneId) {
                    const data = await itemService.getTeamMemberString('teamformation', milestoneId);
                    if (Array.isArray(data) && data.length > 0) {
                        const teamformation = data[0];
                        
                        const transformMembers = (members, isTeamMember = false) => members.map(member => ({
                            ...member,
                            ...(isTeamMember && {
                                joinDate: formatDate(member.joinDate),
                                leaveDate: formatDate(member.leaveDate),
                            }),
                            milestoneId : milestoneId,
                            isTemporary: false,
                            tableType: "TeamMember",
                        }));

                        const teamMembers = transformMembers(teamformation.teamMembers, true);
                        setTeam(teamMembers);
                        setCompanyRelations(transformMembers(teamformation.companyRelations));
                        setSuppliers(transformMembers(teamformation.suppliers));
                        setFriends(transformMembers(teamformation.friendship));
                        setUsers(transformMembers(teamformation.useCase));

                        const summary = calculateTeamSummary(teamMembers);
                        setTeamSummary(summary);
                    }
                }
            } catch (error) {
                console.error("Error fetching TeamMembers:", error);
            }
        };       
        fetchTeamMembers();
    }, [milestoneId, readOnly,calculateTeamSummary]);



    const handleComplete = useCallback(() => {
        const newSummary = calculateTeamSummary(teamRef.current);
        setTeamSummary(newSummary);
        onComplete(teamRef.current);       
        setShowDetails(false);
    }, [onComplete, calculateTeamSummary]);

    const handleAddMember = useCallback((newMember) => {
        setTeam(prevTeam => [...prevTeam, newMember]);
    }, []);

    const handleUpdateMember = useCallback((updatedMember) => {
        setTeam(prevTeam => {
            const index = prevTeam.findIndex(member => member.id === updatedMember.id);
            if (index !== -1) {
                return [...prevTeam.slice(0, index), updatedMember, ...prevTeam.slice(index + 1)];
            }
            return [...prevTeam, updatedMember];
        });
    }, []);

    const handleRemoveMember = useCallback((memberId) => {
        setTeam(prevTeam => prevTeam.filter(member => member.id !== memberId));
    }, []);

    return (
        <div className="relative">
            <TeamSummary summary={teamSummary} onClick={() => setShowDetails(true)} readOnly={readOnly} />
            {showDetails && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center overflow-hidden">
                    <div className="bg-white w-full max-w-5xl h-5/6 flex flex-col rounded-lg shadow-2xl">
                        <div className="flex justify-between items-center p-4 border-b">
                            <h3 className="text-xl font-bold text-gray-800 flex items-center">
                                <svg className="w-6 h-6 mr-2 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                </svg>
                                組隊
                            </h3>
                            <button
                                onClick={() => setShowDetails(false)}
                                className="text-gray-400 hover:text-gray-600 focus:outline-none transition-colors duration-200"
                            >
                                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex-grow flex overflow-hidden">
                            {!readOnly && (
                                <UserList 
                                    users={users} 
                                    companyRelations={companyRelations}
                                    suppliers={suppliers}
                                    friends={friends}                                    
                                    onAddMember={handleAddMember}
                                    setIsDragging={setIsDragging}
                                    milestoneId={milestoneId}
                                />
                            )}
                            <TeamMemberList 
                                team={team} 
                                onUpdateMember={handleUpdateMember}
                                onRemoveMember={handleRemoveMember}
                                isDragging={isDragging}
                                setIsDragging={setIsDragging}
                                readOnly={readOnly}
                                onComplete={handleComplete}
                                milestoneId={milestoneId}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeamFormation;