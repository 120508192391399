// src/services/utils.js

export const getParentTable = (itemType) => {
  const parentTables = {
    TaskAction: "Mission",
    Mission: "Milestone",
    Milestone: "Project",
  };
  return parentTables[itemType] || null;
};

export const getTableTypeName = (type) => {
  switch (type) {
    case "Project":
      return "專案";
    case "Milestone":
      return "里程碑";
    case "Mission":
      return "任務";
    case "TaskAction":
      return "動作";
    default:
      return type;
  }
};

export const getChildTableTypeName = (type) => {
  switch (type) {
    case "Project":
      return "里程碑";
    case "Milestone":
      return "任務";
    case "Mission":
      return "動作";
    default:
      return "";
  }
};

export const getChildTableType = (type) => {
  switch (type) {
    case "Company":
      return "Project";
    case "Project":
      return "Milestone";
    case "Milestone":
      return "Mission";
    case "Mission":
      return "TaskAction";
    default:
      return "";
  }
};

export const getCostColor = (cost, budget) => {
  const ratio = cost / budget;
  if (ratio < 0.8) return "#1e40af"; // blue
  if (ratio < 1) return "#166534"; // green
  if (ratio === 1) return "#000000";
  return "#991b1b"; // red
};

export const priorityOrder = [
  { id: 1, label: "無", color: "bg-gray-200 text-gray-800" },
  { id: 2, label: "低", color:  "bg-blue-200 text-blue-800" },
  { id: 3, label: "一般", color: "bg-green-200 text-green-800"},
  { id: 4, label: "高", color: "bg-yellow-200 text-yellow-800" },
  { id: 5, label: "緊急", color: "bg-red-200 text-red-800" },
];
export const companyRoleTypes = [
  { value: "BudgetManager", label: "預算管理員" },
  { value: "Administrator", label: "系統管理員" },
  { value: "Accountant", label: "會計人員" },
  { value: "RegularMember", label: "一般成員" },
];

export const supplierRoleTypes = [
  { value: "Professional", label: "工程師" },
  { value: "Sales", label: "業務" },
  { value: "Manager", label: "管理師" },
];

export const getColorStyles = (colorString) => {
  if (!colorString) {
    return {
      backgroundColor: "#e5e7eb",
      color: "#1f2937",
    };
  }
  const [bgColor, textColor] = colorString.split(" ");
  const bgColorMap = {
    "bg-green-200": "#bbf7d0",
    "bg-blue-200": "#bfdbfe",
    "bg-yellow-200": "#fef08a",
    "bg-orange-200": "#fed7aa",
    "bg-red-200": "#fecaca",
    "bg-gray-200": "#e5e7eb",
  };
  const textColorMap = {
    "text-green-800": "#166534",
    "text-blue-800": "#1e40af",
    "text-yellow-800": "#854d0e",
    "text-orange-800": "#9a3412",
    "text-red-800": "#991b1b",
    "text-gray-800": "#1f2937",
  };
  return {
    backgroundColor: bgColorMap[bgColor] || bgColor,
    color: textColorMap[textColor] || textColor,
  };
};
export const formatNumber = (num) => {
  return num != null ? num.toLocaleString() : "-";
};