import React, { useState, useEffect } from "react";
import ResizablePopup from "./ResizablePopup";
import ResizableDrawer from "./ResizableDrawer";
import ProjectEdit from "../Edit/ProjectEdit";
import MilestoneEdit from "../Edit/MilestoneEdit";
import MissionEdit from "../Edit/MissionEdit";
import ActionEdit from "../Edit/ActionEdit";
import CompanyEdit from "../Edit/CompanyEdit";
import MessageEdit from "../Edit/CommentEdit";
import UserCompanyRelationyEdit from "../Edit/UserCompanyRelationyEdit";
import SupplierEdit from "../Edit/SupplierEdit";
import TransactionEdit from "../Edit/TransactionEdit";
const CUSTOM_EVENT_NAME = "dataUpdated";
const ItemEditModal = ({
  currentItem,
  setCurrentItem,
  ItemEditComponent,
  readOnly,
  tableType,
  parentId,
  setItemList,
  setErrorMessage,
  parentContainerWidth,
  onSave,
  onClose,
}) => {
  const [modalSize, setModalSize] = useState({ width: 800, height: 600 });
  const [drawerWidth, setDrawerWidth] = useState(50);
  const [EditComponent, setEditComponent] = useState(null);

  useEffect(() => {
    if (currentItem) {
      const savedSize = localStorage.getItem(`${tableType}ModalSize`);
      if (savedSize) {
        const parsedSize = JSON.parse(savedSize);
        const editMode = localStorage.getItem("editMode");
        if (editMode === "popup") {
          setModalSize(parsedSize);
        } else {
          const widthPercentage =
            (parsedSize.width / parentContainerWidth) * 100;
          setDrawerWidth(Math.min(Math.max(widthPercentage, 20), 95));
        }
      }
    }

    // Set the EditComponent
    if (typeof ItemEditComponent === "string") {
      setEditComponent(() => getEditComponent(tableType));
    } else {
      setEditComponent(() => ItemEditComponent);
    }
  }, [currentItem, tableType, parentContainerWidth, ItemEditComponent]);

  const handleSaveItem = async (updatedItem) => {
    try {
      window.dispatchEvent(
        new CustomEvent(CUSTOM_EVENT_NAME, { detail: updatedItem })
      );
      await onSave(updatedItem);
      if (tableType === "Company") return;
      if (tableType === "UserCompanyRelation") return;
      if (setCurrentItem) setCurrentItem(null);
      let updatedItems = [];
      if (setItemList) setItemList(updatedItems);
    } catch (error) {
      console.error(`Error in handleSaveItem (${tableType}):`, error);
      setErrorMessage(`Failed to save ${tableType}`);
    }
  };

  const handleCancelItem = () => {
    if (setCurrentItem) setCurrentItem(null);
    if (onClose) onClose();
  };

  const getEditComponent = (tableType) => {
    switch (tableType) {
      case "Project":
        return ProjectEdit;
      case "Milestone":
        return MilestoneEdit;
      case "Mission":
        return MissionEdit;
      case "TaskAction":
        return ActionEdit;
      case "Comment":
        return MessageEdit;
      case "Company":
        return CompanyEdit;
      case "UserCompanyRelation":
        return UserCompanyRelationyEdit;
      case "Supplier":
        return SupplierEdit;
      case "Transaction":
        return TransactionEdit;
      default:
        return null;
    }
  };

  const handleResize = (newSize) => {
    if (localStorage.getItem("editMode") === "popup") {
      setModalSize(newSize);
      localStorage.setItem(`${tableType}ModalSize`, JSON.stringify(newSize));
    } else {
      const widthPercentage = (newSize / parentContainerWidth) * 100;
      setDrawerWidth(widthPercentage);
      localStorage.setItem(
        `${tableType}ModalSize`,
        JSON.stringify({ width: newSize, height: modalSize.height })
      );
    }
  };

  if (!EditComponent) {
    return null;
  }

  const content = (
    <EditComponent
      item={currentItem ? { ...currentItem, tableType } : null}
      onSave={handleSaveItem}
      onCancel={handleCancelItem}
      isNew={currentItem ? !currentItem.id : true}
      editMode={localStorage.getItem("editMode")}
      readOnly={readOnly}
    />
  );

  if (localStorage.getItem("editMode") === "popup") {
    return (
      <ResizablePopup
        isOpen={!!currentItem}
        onClose={handleCancelItem}
        initialWidth={modalSize.width}
        initialHeight={modalSize.height}
        minWidth={400}
        minHeight={300}
        onResize={handleResize}
      >
        {content}
      </ResizablePopup>
    );
  } else {
    return (
      <ResizableDrawer
        isOpen={!!currentItem}
        onClose={handleCancelItem}
        initialWidth={drawerWidth}
        minWidth={20}
        maxWidth={95}
        backgroundColor="bg-green-100"
        windowWidth={parentContainerWidth}
        topOffset={false}
      >
        {React.cloneElement(content, {
          drawerWidth: (drawerWidth * parentContainerWidth) / 100,
        })}
      </ResizableDrawer>
    );
  }
};

export default ItemEditModal;
