import React from 'react';

const TeamSummary = ({ summary, onClick, readOnly }) => {
    return (
        <div
            onClick={onClick}
            className={`w-full h-12 px-4 bg-white border border-gray-300 rounded-lg ${
                !readOnly ? 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 cursor-pointer' : 'bg-gray-100'
            } overflow-hidden flex items-center shadow-sm transition-all duration-200`}
        >
            <div className="w-full flex flex-col justify-center">
                {summary ? (
                    <>
                        <div className="truncate font-medium text-lg">
                            {`${summary.memberCount} 位成員`}
                        </div>
                        <div className="truncate text-gray-500 text-sm">
                            {summary.roles.join(', ')}
                        </div>
                    </>
                ) : (
                    <div className="truncate text-gray-500 text-lg">尚未組建團隊</div>
                )}
            </div>
        </div>
    );
};

export default TeamSummary;