import { formatDate, formatDateTimeForDB } from '../utils/dateUtils';

/**
 * @typedef {Object} ProjectDTO
 * @property {number|null} id - 專案 ID
 * @property {number|null} parentId - 父項 ID
 * @property {string|null} name - 名稱
 * @property {number|null} assigneeId - 受派人 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {number|null} budget - 預算
 * @property {number|null} cost - 成本
 * @property {string|null} status - 狀態
 * @property {number|null} statusId - 狀態 ID
 * @property {number|null} priority - 優先級
 * @property {string|null} gcsfolderUrl - GCS 文件夾 URL
 * @property {Date|null} startDate - 開始日期
 * @property {Date|null} endDate - 結束日期
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 * @property {string|null} gcsdescriptionUrl - GCS 描述 URL
 * @property {string|null} type - 類型
 * @property {number|null} typeId - 類型 ID
 */

/**
 * @typedef {Object} MilestoneDTO
 * @property {number|null} id - 里程碑 ID
 * @property {number|null} parentId - 父項 ID
 * @property {string|null} name - 名稱
 * @property {number|null} assigneeId - 受派人 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {number|null} budget - 預算
 * @property {number|null} cost - 成本
 * @property {string|null} status - 狀態
 * @property {number|null} statusId - 狀態 ID
 * @property {number|null} priority - 優先級
 * @property {string|null} gcsfolderUrl - GCS 文件夾 URL
 * @property {number|null} version - 版本
 * @property {Date|null} startDate - 開始日期
 * @property {Date|null} endDate - 結束日期
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 * @property {string|null} gcsdescriptionUrl - GCS 描述 URL
 * @property {string|null} type - 類型
 * @property {number|null} typeId - 類型 ID
 */

/**
 * @typedef {Object} MissionDTO
 * @property {number|null} id - 任務 ID
 * @property {number|null} parentId - 父項 ID
 * @property {string|null} name - 名稱
 * @property {string|null} gcsdescriptionUrl - GCS 描述 URL
 * @property {number|null} assigneeId - 受派人 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {string|null} type - 類型
 * @property {number|null} typeId - 類型 ID
 * @property {number|null} budget - 預算
 * @property {number|null} cost - 成本
 * @property {string|null} status - 狀態
 * @property {number|null} statusId - 状态 ID
 * @property {number|null} priority - 優先級
 * @property {string|null} gcsfolderUrl - GCS 文件夾 URL
 * @property {number|null} version - 版本
 * @property {Date|null} startDate - 開始日期
 * @property {Date|null} endDate - 結束日期
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} TeamMemberDTO
 * @property {number|null} id - 團隊成員 ID
 * @property {number|null} milestoneId - 里程碑 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {string|null} roleType - 角色類型
 * @property {number|null} hourlyRate - 時薪
 * @property {Date|null} joinDate - 加入日期
 * @property {Date|null} leaveDate - 離開日期
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} TaskActionDTO
 * @property {number|null} id - 任務行動 ID
 * @property {number|null} parentId - 父項 ID
 * @property {string|null} name - 名稱
 * @property {number|null} assigneeId - 受派人 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {string|null} type - 類型
 * @property {number|null} typeId - 類型 ID
 * @property {string|null} gcsdescriptionUrl - GCS 描述 URL
 * @property {Date|null} startDate - 開始日期
 * @property {Date|null} endDate - 結束日期
 * @property {number|null} cost - 成本
 * @property {number|null} version - 版本
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} UserDTO
 * @property {number|null} accountId - 帳戶 ID
 * @property {string|null} username - 用戶名
 * @property {string|null} email - 電子郵件
 * @property {string|null} pictureUrl - 頭像 URL
 */

/**
 * @typedef {Object} UserProfileDTO
 * @property {number|null} userId - 用戶 ID
 * @property {string|null} username - 用戶名
 * @property {string|null} email - 電子郵件
 * @property {string|null} givenName - 名字
 * @property {string|null} familyName - 姓氏
 * @property {string|null} pictureUrl - 頭像 URL
 * @property {number|null} membershipLevelId - 會員等級 ID
 * @property {number|null} referredByUserId - 推薦人用戶 ID
 * @property {string|null} advancedSettings - 進階設定（JSON 字串）
 * @property {number|null} walletBalance - 錢包餘額
 * @property {number|null} approvalProcessesCount - 審批流程數
 * @property {number|null} commentsCount - 評論數
 * @property {number|null} friendshipCount - 好友數
 * @property {number|null} notificationsCount - 通知數
 * @property {number|null} portfoliosCount - 作品集數
 * @property {number|null} taskRatingsCount - 任務評分數
 * @property {number|null} teamMembersCount - 團隊成員數
 * @property {number|null} userCertificationsCount - 用戶認證數
 * @property {number|null} userCompanyRelationsCount - 用戶公司關係數
 */

/**
 * @typedef {Object} StatusOptionDTO
 * @property {number|null} statusId - 狀態 ID
 * @property {string|null} statusName - 狀態名稱
 * @property {string|null} appliesTo - 適用於
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} MissionRatingDTO
 * @property {number|null} ratingId - 評分 ID
 * @property {number|null} missionId - 任務 ID
 * @property {number|null} RatedBy - 評分者 ID
 * @property {string|null} comment - 評論
 * @property {number|null} score - 分數
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} TagDTO
 * @property {number|null} tagId - 標籤 ID
 * @property {string|null} tagName - 標籤名稱
 * @property {string|null} tagType - 標籤類型
 * @property {Date|null} createdAt - 創建時間
 */

/**
 * @typedef {Object} TagRelationDTO
 * @property {number|null} relationId - 關係 ID
 * @property {number|null} tagId - 標籤 ID
 * @property {string|null} relatedTo - 關聯對象
 * @property {number|null} relatedId - 關聯 ID
 * @property {Date|null} createdAt - 創建時間
 */

/**
 * @typedef {Object} TypeDTO
 * @property {number|null} typeId - 類型 ID
 * @property {string|null} typeName - 類型名稱
 * @property {string|null} typeCategory - 類型類別
 * @property {string|null} description - 描述
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} CommentDTO
 * @property {number|null} commentId - 評論 ID
 * @property {number|null} accountId - 帳戶 ID
 * @property {string|null} relatedTo - 關聯對象
 * @property {number|null} relatedId - 關聯 ID
 * @property {string|null} content - 內容
 * @property {Date|null} createdAt - 創建時間
 * @property {Date|null} updatedAt - 更新時間
 */

/**
 * @typedef {Object} AccessListDTO
 * @property {string|null} itemLevel - 項目級別
 * @property {number|null} itemId - 項目 ID
 * @property {string|null} permission - 權限
 */

/**
 * 創建一個 ProjectDTO 物件
 * @param {Object} data - 原始數據
 * @returns {ProjectDTO}
 */
export function createProjectDTO(data) {
  return {
    id: data.id || null,
    parentId: data.parentId || null,
    name: data.name || null,
    assigneeId: data.assigneeId || null,
    accountId: data.accountId || null,
    budget: data.budget || 0.0,
    cost: data.cost || 0.0,
    status: data.status || null,
    statusId: data.statusId || null,
    priority: data.priority || null,
    gcsfolderUrl: data.gcsfolderUrl || null,
    startDate: formatDate(data.startDate),
    endDate: formatDate(data.endDate),
    createdAt: formatDateTimeForDB(data.createdAt),
    updatedAt: formatDateTimeForDB(data.updatedAt),
    gcsdescriptionUrl: data.gcsdescriptionUrl || null,
    type: data.type || null,
    typeId: data.typeId || null
  };
}

/**
 * 創建一個 MilestoneDTO 物件
 * @param {Object} data - 原始數據
 * @returns {MilestoneDTO}
 */
export function createMilestoneDTO(data) {
  return {
    id: data.id ?? null,
    parentId: data.parentId ?? null,
    name: data.name ?? null,
    assigneeId: data.assigneeId ?? null,
    accountId: data.accountId ?? null,
    budget: data.budget ?? 0.0,
    cost: data.cost ?? 0.0,
    status: data.status ?? null,
    statusId: data.statusId ?? null,
    priority: data.priority ?? null,
    gcsfolderUrl: data.gcsfolderUrl ?? null,
    version: data.version !== undefined ? data.version : null,
    startDate: data.startDate ? new Date(data.startDate) : null,
    endDate: data.endDate ? new Date(data.endDate) : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null,
    gcsdescriptionUrl: data.gcsdescriptionUrl ?? null,
    type: data.type ?? null,
    typeId: data.typeId ?? null
  };
}

/**
 * 創建一個 MissionDTO 物件
 * @param {Object} data - 原始數據
 * @returns {MissionDTO}
 */
export function createMissionDTO(data) {
  return {
    id: data.id ?? null,
    parentId: data.parentId ?? null,
    name: data.name ?? null,
    gcsdescriptionUrl: data.gcsdescriptionUrl ?? null,
    assigneeId: data.assigneeId ?? null,
    accountId: data.accountId ?? null,
    type: data.type ?? null,
    typeId: data.typeId ?? null,
    budget: data.budget ?? 0.0,
    cost: data.cost ?? 0.0,
    status: data.status ?? null,
    statusId: data.statusId ?? null,
    priority: data.priority ?? null,
    gcsfolderUrl: data.gcsfolderUrl ?? null,
    version: data.version !== undefined ? data.version : null,
    startDate: data.startDate ? new Date(data.startDate) : null,
    endDate: data.endDate ? new Date(data.endDate) : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 TeamMemberDTO 物件
 * @param {Object} data - 原始數據
 * @returns {TeamMemberDTO}
 */
export function createTeamMemberDTO(data) {
  return {
    id: data.id ?? null,
    milestoneId: data.milestoneId ?? null,
    accountId: data.accountId ?? null,
    roleType: data.roleType ?? null,
    hourlyRate: data.hourlyRate ?? null,
    joinDate: data.joinDate ? new Date(data.joinDate) : null,
    leaveDate: data.leaveDate ? new Date(data.leaveDate) : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 TaskActionDTO 物件
 * @param {Object} data - 原始數據
 * @returns {TaskActionDTO}
 */
export function createTaskActionDTO(data) {
  return {
    id: data.id || null,
    parentId: data.parentId || null,
    name: data.name || null,
    assigneeId: data.assigneeId || null,
    accountId: data.accountId || null,
    type: data.type || null,
    typeId: data.typeId || null,
    gcsdescriptionUrl: data.gcsdescriptionUrl || null,
    startDate: data.startDate ? new Date(data.startDate) : null,
    endDate: data.endDate ? new Date(data.endDate) : null,
    cost: data.cost || 0.0,
    version: data.version !== undefined ? data.version : null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 UserDTO 物件
 * @param {Object} data - 原始數據
 * @returns {UserDTO}
 */
export function createUserDTO(data) {
  return {
    accountId: data.accountId || null,
    username: data.username || null,
    email: data.email || null,
    pictureUrl: data.pictureUrl || null
  };
}

/**
 * 創建一個 UserProfileDTO 物件
 * @param {Object} data - 原始數據
 * @returns {UserProfileDTO}
 */
export function createUserProfileDTO(data) {
  return {
    userId: data.userId || null,
    username: data.username || null,
    email: data.email || null,
    givenName: data.givenName || null,
    familyName: data.familyName || null,
    pictureUrl: data.pictureUrl || null,
    membershipLevelId: data.membershipLevelId || null,
    referredByUserId: data.referredByUserId || null,
    advancedSettings: data.advancedSettings || null,
    walletBalance: data.walletBalance || null,
    approvalProcessesCount: data.approvalProcessesCount || null,
    commentsCount: data.commentsCount || null,
    friendshipCount: data.friendshipCount || null,
    notificationsCount: data.notificationsCount || null,
    portfoliosCount: data.portfoliosCount || null,
    taskRatingsCount: data.taskRatingsCount || null,
    teamMembersCount: data.teamMembersCount || null,
    userCertificationsCount: data.userCertificationsCount || null,
    userCompanyRelationsCount: data.userCompanyRelationsCount || null
  };
}

/**
 * 創建一個 StatusOptionDTO 物件
 * @param {Object} data - 原始數據
 * @returns {StatusOptionDTO}
 */
export function createStatusOptionDTO(data) {
  return {
    statusId: data.statusId || null,
    statusName: data.statusName || null,
    appliesTo: data.appliesTo || null,
    color: data.color || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 MissionRatingDTO 物件
 * @param {Object} data - 原始數據
 * @returns {MissionRatingDTO}
 */
export function createMissionRatingDTO(data) {
  return {
    ratingId: data.ratingId || null,
    missionId: data.missionId || null,
    RatedBy: data.RatedBy || null,
    comment: data.comment || null,
    score: data.score || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 TagDTO 物件
 * @param {Object} data - 原始數據
 * @returns {TagDTO}
 */
export function createTagDTO(data) {
  return {
    tagId: data.tagId || null,
    tagName: data.tagName || null,
    tagType: data.tagType || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null
  };
}

/**
 * 創建一個 TagRelationDTO 物件
 * @param {Object} data - 原始數據
 * @returns {TagRelationDTO}
 */
export function createTagRelationDTO(data) {
  return {
    relationId: data.relationId || null,
    tagId: data.tagId || null,
    relatedTo: data.relatedTo || null,
    relatedId: data.relatedId || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null
  };
}

/**
 * 創建一個 TypeDTO 物件
 * @param {Object} data - 原始數據
 * @returns {TypeDTO}
 */
export function createTypeDTO(data) {
  return {
    typeId: data.typeId || null,
    typeName: data.typeName || null,
    typeCategory: data.typeCategory || null,
    description: data.description || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 CommentDTO 物件
 * @param {Object} data - 原始數據
 * @returns {CommentDTO}
 */
export function createCommentDTO(data) {
  return {
    commentId: data.commentId || null,
    accountId: data.accountId || null,
    relatedTo: data.relatedTo || null,
    relatedId: data.relatedId || null,
    content: data.content || null,
    createdAt: data.createdAt ? new Date(data.createdAt) : null,
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : null
  };
}

/**
 * 創建一個 AccessListDTO 物件
 * @param {Object} data - 原始數據
 * @returns {AccessListDTO}
 */
export function createAccessListDTO(data) {
  return {
    itemLevel: data.itemLevel || null,
    itemId: data.itemId || null,
    permission: data.permission || null
  };
}


// 定義一個映射對象，將 tableType 映射到對應的 DTO 創建函數
const dtoCreators = {
  Project: createProjectDTO,
  Milestone: createMilestoneDTO,
  Mission: createMissionDTO,
  TeamMember: createTeamMemberDTO,
  TaskAction: createTaskActionDTO,
  User: createUserDTO,
  UserProfile: createUserProfileDTO,
  StatusOption: createStatusOptionDTO,
  MissionRating: createMissionRatingDTO,
  Tag: createTagDTO,
  TagRelation: createTagRelationDTO,
  Type: createTypeDTO,
  Comment: createCommentDTO,
  AccessList: createAccessListDTO
};
/**
 * 將 syncItem 轉換為相應的 DTO
 * @param {Object} syncItem - 同步項目
 * @param {string} syncItem.tableType - 表類型
 * @param {number} syncItem.itemId - 項目 ID
 * @param {Object} syncItem.data - 原始數據
 * @returns {Object} 轉換後的 DTO
 */
export function convertToDTO(syncItem) {
  const { tableType, data } = syncItem;
  const createDTO = dtoCreators[tableType];

  if (createDTO) {
    return createDTO(data);
  } else {
    console.warn(`未知的表類型: ${tableType}`);
    return data; // 如果沒有對應的 DTO，直接返回原始數據
  }
}