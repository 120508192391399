import React, { useState, useMemo } from "react";
import {
  Paper,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Typography,
  TableSortLabel,
  IconButton,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { formatDate } from "../../utils/dateUtils";

const SOURCE_ENUM = ["淘寶", "JLCPCB", "JLC3DP", "發票"];
const STATUS_ENUM = ["下訂", "出貨", "集運", "收貨", "結案"];

const TreeList = ({ orders, onUpdateOrder }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [filters, setFilters] = useState({
    source: "",
    orderNumber: "",
    logisticNumber: "",
    status: "",
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const sortedAndFilteredOrders = useMemo(() => {
    let result = orders.filter(
      (order) =>
        order.source.includes(filters.source) &&
        order.orderNumber
          .toLowerCase()
          .includes(filters.orderNumber.toLowerCase()) &&
        (order.logisticNumber || "")
          .toLowerCase()
          .includes(filters.logisticNumber.toLowerCase()) &&
        order.status.includes(filters.status)
    );

    if (sortConfig.key) {
      result.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    return result;
  }, [orders, filters, sortConfig]);

  const getTempContent = () => {
    const headers = [
      "商品名稱",
      "數量",
      "物流號碼",
      "體積重",
      "物流日期",
      "訂單日期",
      "訂單號碼",
      "總金額",
      "總金額-台幣",
      "公司",
      "集運運費",
      "總金額",
    ];
    const rows = [headers];

    sortedAndFilteredOrders.forEach((order) => {
      let firstrow = false;
      let maxweight =
        (order.weight > order.volume ? order.weight : order.volume) || 0;
      order.orderItems.forEach((item, index) => {
        const row = [
          order.source === "JLCPCB"
            ? `${item.productName}-${item.specification}`
            : item.productName,
          item.quantity,
          order.logisticNumber || "",
          firstrow ? "" : maxweight,
          "",
          formatDate(order.orderDate),
          order.orderNumber,
          firstrow ? "" : order.totalAmount,
          firstrow ? "" : order.totalAmount * 5,
          "",
          firstrow ? "" : maxweight * 35 * 5,
          firstrow ? "" : maxweight * 35 * 5 + order.totalAmount * 5,
        ];
        rows.push(row);
        firstrow = true;
      });
    });

    return rows.map((row) => row.join("\t")).join("\n");
  };
  const handleCopyTempTable = () => {
    const content = getTempContent();
    navigator.clipboard.writeText(content).then(
      () => {
        alert("表格內容已複製到剪貼板");
      },
      (err) => {
        console.error("無法複製表格內容: ", err);
      }
    );
  };

  const getExcelContent = () => {
    const headers = [
      "來源",
      "訂單號碼",
      "日期",
      "總金額",
      "重量 (kg)",
      "體積 (m³)",
      "物流號碼",
      "狀態",
      "發往倉庫",
      "商品名稱",
      "規格",
      "數量",
      "價格",
    ];

    const rows = [headers];

    sortedAndFilteredOrders.forEach((order) => {
      order.orderItems.forEach((item, index) => {
        const row = [
          order.source,
          order.orderNumber,
          formatDate(order.orderDate),
          order.totalAmount,
          item.currency,
          (order.weight > order.volume ? order.weight : order.volume) || "",
          order.logisticNumber || "",
          order.status,
          order.destination,
          item.productName,
          item.specification || "",
          item.quantity,
          item.price,
        ];
        rows.push(row);
      });
    });

    return rows.map((row) => row.join("\t")).join("\n");
  };

  const handleCopyTable = () => {
    const content = getExcelContent();
    navigator.clipboard.writeText(content).then(
      () => {
        alert("表格內容已複製到剪貼板");
      },
      (err) => {
        console.error("無法複製表格內容: ", err);
      }
    );
  };
  const getCSVContent = () => {
    const headers = [
      "來源",
      "訂單號碼",
      "日期",
      "總金額",
      "幣別",
      "體積重",
      "物流號碼",
      "狀態",
      "目的地",
      "商品名稱",
      "規格",
      "數量",
      "價格",
    ];

    const csvRows = [headers.join(",")];

    sortedAndFilteredOrders.forEach((order) => {
      order.orderItems.forEach((item, index) => {
        const row = [
          order.source,
          order.orderNumber,
          formatDate(order.orderDate),
          order.totalAmount,
          item.currency,
          (order.weight > order.volume ? order.weight : order.volume) || "",
          order.logisticNumber || "",
          order.status,
          order.destination,
          item.productName,
          item.specification || "",
          item.quantity,
          item.price,
        ].map((cell) => `"${cell}"`); // Wrap each cell in quotes to handle commas within cells

        csvRows.push(row.join(","));
      });
    });

    return csvRows.join("\n");
  };
  const handleDownloadTable = () => {
    const content = getCSVContent();
    const blob = new Blob(["\ufeff", content], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "orders_table.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "下訂":
        return "default";
      case "出貨":
        return "primary";
      case "集運":
        return "secondary";
      case "收貨":
        return "info";
      case "結案":
        return "success";
      default:
        return "default";
    }
  };

  const OrderCard = ({ order }) => (
    <Paper
      elevation={3}
      style={{ margin: "16px 0", padding: "16px", borderRadius: "8px" }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body2"
          style={{ marginRight: 24, whiteSpace: "nowrap" }}
        >
          {formatDate(order.orderDate)}
        </Typography>
        <Box display="flex" alignItems="center" style={{ flex: 1 }}>
          <Box display="flex" alignItems="center" style={{ marginRight: 16 }}>
            <Chip label={order.source} color="primary" size="small" />
            <Typography
              variant="body2"
              style={{ marginRight: 8, whiteSpace: "nowrap" }}
            >
              {order.orderNumber}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" style={{ flex: 1 }}>
          <Typography
            variant="body2"
            style={{ marginRight: 8, whiteSpace: "nowrap" }}
          >
            物流：{order.logisticNumber || "無"}
          </Typography>
          <Chip
            label={order.destination}
            color="primary"
            size="small"
            style={{ marginRight: 4 }}
          />
          {(order.weight || order.volume) && (
            <Chip
              label={`體積重=${
                order.weight > order.volume ? order.weight : order.volume
              }`}
              size="small"
              style={{ marginRight: 4 }}
            />
          )}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{ flex: 1 }}
        >
          <Chip
            label={order.status}
            color={getStatusColor(order.status)}
            size="small"
            style={{ marginRight: 8 }}
          />
          <Button
            variant="outlined"
            size="small"
            onClick={() => onUpdateOrder(order)}
          >
            更新
          </Button>
        </Box>
      </Box>
      <Table size="small" style={{ marginTop: 16 }}>
        <TableBody>
          {order.orderItems.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: "45%", paddingLeft: 0 }}>
                {item.productName}
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                {item.specification || "-"}
              </TableCell>
              <TableCell style={{ width: "10%" }}>{item.quantity}</TableCell>
              <TableCell style={{ width: "10%" }}>
                {item.price.toFixed(2)}
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                {(item.quantity * item.price).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end" marginTop={1}>
        <Typography variant="subtitle2" style={{ color: "#1976d2" }}>
          總金額：{order.totalAmount.toFixed(2)}
        </Typography>
        <Chip label={order.currency} size="small" style={{ marginLeft: 8 }} />
      </Box>
    </Paper>
  );

  return (
    <div>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <TableSortLabel
          style={{
            marginRight: 20,
            marginLeft: 10,
            border: "1px solid",
            borderRadius: "4px",
            padding: "4px 8px",
          }}
          variant="outlined"
          active={sortConfig.key === "orderDate"}
          direction={
            sortConfig.key === "orderDate" ? sortConfig.direction : "asc"
          }
          onClick={() => handleSort("orderDate")}
        >
          訂單日期
        </TableSortLabel>
        <TableSortLabel
          active={sortConfig.key === "source"}
          direction={sortConfig.key === "source" ? sortConfig.direction : "asc"}
          onClick={() => handleSort("source")}
        >
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 120 }}
          >
            <InputLabel>來源</InputLabel>
            <Select
              name="source"
              value={filters.source}
              onChange={handleFilterChange}
              label="來源"
            >
              <MenuItem value="">
                <em>全部</em>
              </MenuItem>
              {SOURCE_ENUM.map((source) => (
                <MenuItem key={source} value={source}>
                  {source}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableSortLabel>
        <TableSortLabel
          active={sortConfig.key === "status"}
          direction={sortConfig.key === "status" ? sortConfig.direction : "asc"}
          onClick={() => handleSort("status")}
        >
          <FormControl variant="outlined" size="small" style={{ minWidth: 80 }}>
            <InputLabel>狀態</InputLabel>
            <Select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              label="狀態"
            >
              <MenuItem value="">
                <em>全部</em>
              </MenuItem>
              {STATUS_ENUM.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableSortLabel>
        <TextField
          name="orderNumber"
          label="篩選訂單號碼"
          value={filters.orderNumber}
          onChange={handleFilterChange}
          size="small"
          style={{ marginLeft: 10, marginRight: 10 }}
        />
        <TextField
          name="logisticNumber"
          label="篩選物流號碼"
          value={filters.logisticNumber}
          onChange={handleFilterChange}
          size="small"
          style={{ marginRight: 10 }}
        />

        <Box flexGrow={1} />
        <IconButton
          onClick={handleCopyTable}
          size="small"
          aria-label="複製表格"
        >
          <ContentCopyIcon />
        </IconButton>
        <IconButton
          onClick={handleDownloadTable}
          size="small"
          aria-label="下載表格"
        >
          {" "}
          <FileDownloadIcon />
        </IconButton>
        <IconButton
          onClick={handleCopyTempTable}
          size="small"
          aria-label="複製到淘寶表格"
        >
          <ContentCopyIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "45%", fontWeight: "bold" }}>
                商品名稱
              </TableCell>
              <TableCell style={{ width: "25%", fontWeight: "bold" }}>
                規格
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                數量
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                單價
              </TableCell>
              <TableCell style={{ width: "10%", fontWeight: "bold" }}>
                總價
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      {sortedAndFilteredOrders.map((order) => (
        <OrderCard key={order.orderId} order={order} />
      ))}
    </div>
  );
};

export default TreeList;
