import React, { useState, useEffect } from "react";
import { Typography, IconButton, TextField, Popover } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import itemService from "../../services/db/ItemService";
import { convertToDTO } from "../../types/projectsTypes";

const BudgetEdit = ({ item, onSave, onCancel, anchorEl }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemBudget, setItemBudget] = useState(null);
  const [editValue, setEditValue] = useState("");


  useEffect(() => {
    const loadBudget = async () => {
      try {
        setLoading(true);
        const budget = await itemService.getBudgetByString(item.tableType, item.id);
        if (budget) {
          setItemBudget(budget);
          setEditValue(budget.budget.toString());
        } else {
          setError("無法獲取預算信息");
        }
      } catch (error) {
        console.error("加載預算失敗:", error);
        setError("加載預算失敗");
      } finally {
        setLoading(false);
      }
    };

    loadBudget();
  }, [item]);

  const handleCancelBudget = () => {
    onCancel();
  };

  const handleSaveBudget = async () => {
    const numericValue = calculateValue(editValue);
    if (isNaN(numericValue)) {
      setError("請輸入有效的數字");
      return;
    }
    if (numericValue < itemBudget.cost) {
      setError("不得小於花費");
      return;
    }
    if (numericValue < 1) {
      setError("預算至少要為1");
      return;
    }
    if (numericValue !== itemBudget.budget) {
      if (numericValue <itemBudget.minBudget) {
        setError("預算不足");
        return;
      }
      try {
        const dto = await convertToDTO({
          tableType: item.tableType,
          data: {
            ...item,
            type: null,
            status: null,
            assignee: null,
            subRows: null,
            budget: numericValue,
          },
        });
        await itemService.updateItem("Budget", dto, item.tableType);
        onSave();
      } catch (error) {
        console.error("更新預算失敗:", error);
        setError("更新預算失敗");
      }
    } else {
      onCancel();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCancelBudget();
    } else if (event.key === "Enter") {
      handleSaveBudget();
    }
  };

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };

  const calculateValue = (input) => {
    try {
      const cleanInput = input.replace(/[\s,]+/g, "");
      const parts = cleanInput.split(/([+-])/);
      let result = parseFloat(parts[0]) || 0;

      for (let i = 1; i < parts.length; i += 2) {
        const operator = parts[i];
        const value = parseFloat(parts[i + 1]) || 0;
        if (operator === "+") {
          result += value;
        } else if (operator === "-") {
          result -= value;
        }
      }
      return result;
    } catch (error) {
      console.error("Invalid mathematical expression");
      return null;
    }
  };

  if (loading) {
    return (
      <Typography variant="body2" align="center">
        加載中...
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="body2" align="center" color="error">
        錯誤: {error}
      </Typography>
    );
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCancelBudget}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="p-2">
        <div className="flex items-center space-x-2">
          <IconButton
            size="small"
            onClick={() => setEditValue(itemBudget.minBudget.toString())}
            title="最小預算"
          >
            <Typography variant="body2">
              {new Intl.NumberFormat("en-US").format(itemBudget.minBudget)}
            </Typography>
          </IconButton>
          <Typography variant="body2">{"<"}</Typography>
          <TextField
            value={editValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            size="small"
            autoFocus
            type="text"
            className="w-24"
            error={
              calculateValue(editValue) < itemBudget.minBudget ||
              calculateValue(editValue) > itemBudget.maxBudget
            }
            helperText={
              calculateValue(editValue) < itemBudget.minBudget ||
              calculateValue(editValue) > itemBudget.maxBudget
                ? "預算超出範圍"
                : ""
            }
          />
          <Typography variant="body2">{"<"}</Typography>
          <IconButton
            size="small"
            onClick={() => setEditValue(itemBudget.maxBudget.toString())}
            title="最大預算"
          >
            <Typography variant="body2">
              {new Intl.NumberFormat("en-US").format(itemBudget.maxBudget)}
            </Typography>
          </IconButton>
          <IconButton
            size="small"
            onClick={handleSaveBudget}
            title="儲存"
            disabled={
              calculateValue(editValue) < itemBudget.minBudget ||
              calculateValue(editValue) > itemBudget.maxBudget ||
              calculateValue(editValue) === itemBudget.budget
            }
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={handleCancelBudget} title="取消">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </Popover>
  );
};

export default BudgetEdit;