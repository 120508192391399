import itemService from "../../services/db/ItemService";
import { accessCheck } from "../common/Access";

class HistoryDataService {
  static async fetchHistoriesItems(ids, type) {
    try {
      let items = [];
      const idsString = ids.map((item) => item.id).join(","); // 提取 id 屬性並連接
      switch (type) {
        case "Project":
          items = await itemService.getProjectString("history", idsString);
          break;
        case "Milestone":
          items = await itemService.getMilestoneString("history", idsString);
          break;
        case "Mission":
          items = await itemService.getMissionString("history", idsString);
          break;
        default:
          throw new Error(`Unsupported type: ${type}`);
      }
      return items;
    } catch (error) {
      console.error(`Error in fetchHistoryItems:`, error);
      throw error;
    }
  }

  static async fetchHistoryItems(id, type) {
    try {
      let items = [];
      try {
        const accessLevel = await accessCheck(type, id);
        if (accessLevel.access != null) {
          switch (type) {
            case "Project":
              items = await this.fetchProjectItems(id);
              break;
            case "Milestone":
              items = await this.fetchMissions(id);
              break;
            case "Mission":
              items = await this.fetchActions(id, accessLevel);
              break;
            default:
              throw new Error(`Unsupported type: ${type}`);
          }
        }
      } catch (error) {
        console.error(`Error in fetchHistoryItems for ${type} ${id}:`, error);
        throw error;
      }

      return items;
    } catch (error) {
      console.error(`Error in fetchHistoryItems:`, error);
      throw error;
    }
  }

  static async fetchProjectItems(projectId) {
    try {
      const comments = await itemService.getCommentString(
        "relatedId",
        `Project-${projectId}`
      );
      const filteredMilestones = await itemService.getMilestoneString(
        "parentId",
        projectId
      );
      const items = [];
      for (let milestone of filteredMilestones) {
        try {
          const milestoneAccess = await accessCheck("Milestone", milestone.id);
          if (milestoneAccess != null) {
            const child = await this.fetchMissions(milestone.id);
            milestone.children = child.children;
            milestone.comments = child.comments;
            milestone.access = milestoneAccess;
            milestone.tableType = "Milestone";
            milestone.tableTypeName = "里程碑";
            milestone.ChildtableTypeName = "任務";
            milestone.ChildtableType = "Mission";
            items.push(milestone);
          }
        } catch (error) {
          console.error(`Error processing milestone ${milestone.id}:`, error);
        }
      }

      return { children: items, comments: comments };
    } catch (error) {
      console.error(
        `Error in fetchProjectItems for project ${projectId}:`,
        error
      );
      throw error;
    }
  }

  static async fetchMissions(milestoneId) {
    try {
      const missions = await itemService.getMissionString(
        "parentId",
        milestoneId
      );
      const comments = await itemService.getCommentString(
        "relatedId",
        `Milestone-${milestoneId}`
      );

      const items = [];
      for (let mission of missions) {
        try {
          const missionAccess = await accessCheck("Mission", mission.id);
          if (missionAccess != null) {
            mission.access = missionAccess;
            mission.tableType = "Mission";
            mission.tableTypeName = "任務";
            mission.ChildtableTypeName = "動作";
            mission.ChildtableType = "TaskAction";
            if (missionAccess.readChild) {
              const child = await this.fetchActions(mission.id, missionAccess);
              mission.children = child.children;
              mission.comments = child.comments;
            } else {
              mission.children = [];
              mission.comments = [];
            }
            items.push(mission);
          }
        } catch (error) {
          console.error(`Error processing mission ${mission.id}:`, error);
        }
      }
      return { children: items, comments: comments };
    } catch (error) {
      console.error(
        `Error in fetchMissions for milestone ${milestoneId}:`,
        error
      );
      throw error;
    }
  }

  static async fetchActions(missionId, parentAccessLevel) {
    try {
      if (!parentAccessLevel.readChild) {
        return [];
      }
      const actions = await itemService.getTaskActionString(
        "parentId",
        missionId
      );
      const comments = await itemService.getCommentString(
        "relatedId",
        `Mission-${missionId}`
      );
      const items = actions.map((action) => {
        action.access = parentAccessLevel;
        action.tableType = "TaskAction";
        action.tableTypeName = "動作";
        return action;
      });
      return { children: items, comments: comments };
    } catch (error) {
      console.error(`Error in fetchActions for mission ${missionId}:`, error);
      throw error;
    }
  }
}

export default HistoryDataService;
