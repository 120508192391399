import React, { useState, useEffect, useCallback } from 'react';
import { 
  IconButton, 
  Paper, 
  Typography, 
  Box,
  Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// ResizablePopup component
const ResizablePopup = ({ 
  isOpen, 
  onClose, 
  initialWidth = 600, 
  initialHeight = 400,
  minWidth = 200, 
  minHeight = 150,
  children 
}) => {
  const [size, setSize] = useState({ width: initialWidth, height: initialHeight });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });


  const handleMouseDown = (event, action) => {
    if (action === 'drag') setIsDragging(true);
    if (action === 'resize') setIsResizing(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  const handleMouseMove = useCallback((event) => {
    if (isDragging) {
      const dx = event.clientX - dragStart.x;
      const dy = event.clientY - dragStart.y;
      setPosition(prev => ({
        x: prev.x + dx,
        y: prev.y + dy
      }));
      setDragStart({ x: event.clientX, y: event.clientY });
    } else if (isResizing) {
      const dx = event.clientX - dragStart.x;
      const dy = event.clientY - dragStart.y;
      setSize(prev => ({
        width: Math.max(minWidth, prev.width + dx),
        height: Math.max(minHeight, prev.height + dy)
      }));
      setDragStart({ x: event.clientX, y: event.clientY });
    }
  }, [isDragging, isResizing, dragStart, minWidth, minHeight]);

  useEffect(() => {
    if (isDragging || isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, isResizing, handleMouseMove]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="resizable-popup-title"
    >
      <Paper
        sx={{
          position: 'absolute',
          width: size.width,
          height: size.height,
          top: `calc(50% + ${position.y}px)`,
          left: `calc(50% + ${position.x}px)`,
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 0.5, // 減少上下 padding
            px: 1,
            cursor: 'move',
            bgcolor: 'background.default',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
          onMouseDown={(e) => handleMouseDown(e, 'drag')}
        >
          <DragIndicatorIcon sx={{ mr: 1, fontSize: '1rem' }} />
          <Typography variant="subtitle1" component="h2" id="resizable-popup-title">
            編輯
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ ml: 'auto', p: 0.5 }} // 減少按鈕 padding
            aria-label="close"
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}> {/* 減少內容區域的 padding */}
          {children}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: 20,
            height: 20,
            cursor: 'se-resize',
            '&:hover': { bgcolor: 'action.hover' },
          }}
          onMouseDown={(e) => handleMouseDown(e, 'resize')}
        />
      </Paper>
    </Modal>
  );
};

export default ResizablePopup ;