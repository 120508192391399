import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import itemService from '../../services/db/ItemService';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const Home = () => {
  const [projectStats, setProjectStats] = useState({
    totalProjects: 0,
    projectsByStatus: [],
    milestonesByStatus: [],
    tasksByStatus: [],
    taskCompletion: { completed: 0, total: 0 },
    upcomingMilestones: [],
    workHoursByProject: [],
    workHoursByType: [],
    totalWorkHours: 0,
    workHoursTrend: [],
    roleTypeDistribution: [],
  });

  useEffect(() => {
    const fetchProjectStats = async () => {
      try {
        const data = await itemService.getProjectString('Home', "");

        if (!Array.isArray(data) || data.length === 0) {
          console.error('Invalid or empty data received');
          return;
        }

        const stats = data.reduce((acc, item) => {
          acc[item.statisticType] = item.value;
          return acc;
        }, {});

        setProjectStats({
          totalProjects: stats.ProjectCount || 0,
          projectsByStatus: processStatusDistribution(stats.StatusDistribution?.projects),
          milestonesByStatus: processStatusDistribution(stats.StatusDistribution?.milestones),
          tasksByStatus: processStatusDistribution(stats.StatusDistribution?.missions),
          taskCompletion: {
            completed: stats.MissionCompletionRate || 0,
            total: 100
          },
          upcomingMilestones: [],
          workHoursByProject: processWorkHours(stats.ProjectWorkTime).sort((a, b) => b.value - a.value),
          workHoursByType: processWorkHours(stats.WorkStatusDistribution).sort((a, b) => b.value - a.value),
          totalWorkHours: stats.TotalTaskTime || 0,
          workHoursTrend: processWorkHoursTrend(stats.WeeklyWorkTrend),
          roleTypeDistribution: processRoleTypeDistribution(stats.RoleTypeDistribution),
        });
      } catch (error) {
        console.error('載入專案統計資料時發生錯誤:', error);
      }
    };

    fetchProjectStats();
  }, []);

  const processStatusDistribution = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => ({
      name: item.name,
      value: item.count
    }));
  };

  const processWorkHours = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => ({
      name: item.projectName || item.statusName,
      value: item.totalTime
    }));
  };

  const processWorkHoursTrend = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => ({
      week: item.yearWeek,
      hours: item.totalTime
    }));
  };

  const processRoleTypeDistribution = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => ({
      name: item.name,
      value: item.value
    }));
  };

  const renderPieChart = (data, title) => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>參與過的專案總覽</Typography>
              <Typography variant="h3" color="primary">{projectStats.totalProjects}</Typography>
              <Typography variant="subtitle1" color="textSecondary">進行中的專案</Typography>
            </Paper>
          </Box>
          <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>相關任務完成率</Typography>
              <Typography variant="h3" color="secondary">
                {projectStats.taskCompletion.completed.toFixed(2)}%
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">任務完成率</Typography>
            </Paper>
          </Box>
          <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>相關總工時</Typography>
              <Typography variant="h3" color="error">{projectStats.totalWorkHours.toFixed(1)}</Typography>
              <Typography variant="subtitle1" color="textSecondary">小時</Typography>
            </Paper>
          </Box>
        </Box>

        <Box my={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>專案、里程碑與任務狀態分佈</Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
                {renderPieChart(projectStats.projectsByStatus, "專案狀態")}
              </Box>
              <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
                {renderPieChart(projectStats.milestonesByStatus, "里程碑狀態")}
              </Box>
              <Box flexBasis={{ xs: '100%', md: '32%' }} mb={2}>
                {renderPieChart(projectStats.tasksByStatus, "任務狀態")}
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box my={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>各專案工時統計</Typography>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={projectStats.workHoursByProject} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" name="總工時" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Box>

        <Box my={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>工作狀態工時分佈</Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box flexBasis={{ xs: '100%', md: '48%' }} mb={2}>
                {renderPieChart(projectStats.roleTypeDistribution, "工作狀態工時分佈 (圓餅圖)")}
              </Box>
              <Box flexBasis={{ xs: '100%', md: '48%' }} mb={2}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={projectStats.workHoursByType} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={100} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#82ca9d" name="工時" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box my={4}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>工時趨勢 (每週)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={projectStats.workHoursTrend}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="hours" fill="#8884d8" name="每週工時" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Box>

        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box m={1}>
            <Button component={Link} to="/gantt" variant="contained" color="primary">
              查看甘特圖
            </Button>
          </Box>
          <Box m={1}>
            <Button component={Link} to="/missions" variant="contained" color="secondary">
              查看任務列表
            </Button>
          </Box>
          <Box m={1}>
            <Button component={Link} to="/kanbans" variant="contained" color="warning">
              查看看板
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Home;