// dateUtils.js
import { format as dateFnsFormat , parseISO, isValid } from 'date-fns';

const padNumber = (num) => String(num).padStart(2, '0');

export const isISODateTimeString = (value) => {
  try {
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?(?:Z|[+-]\d{2}:\d{2})?$/;
    return typeof value === 'string' && isoDateTimeRegex.test(value);
  } catch (error) {
    console.error('Error in isISODateTimeString:', error);
    return false;
  }
};

export const parseDate = (dateString) => {
  try {
    if (!dateString) return null;
    if (dateString instanceof Date) return dateString;
    
    if (typeof dateString === 'string') {
      if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
      }
      if (isISODateTimeString(dateString) || /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) return date;
      }
    }
    console.error('Invalid date format');
    return null;
  } catch (error) {
    console.error('Error in parseDate:', error);
    return null;
  }
};

export const formatDateTimeForDB = (dateTime) => {
  try {
    if (!dateTime) return '';
    const date = parseDate(dateTime);
    if (!date) throw new Error('Invalid date');
    return `${date.getFullYear()}-${padNumber(date.getMonth() + 1)}-${padNumber(date.getDate())} ${padNumber(date.getHours())}:${padNumber(date.getMinutes())}:${padNumber(date.getSeconds())}`;
  } catch (error) {
    console.error('Error in formatDateTimeForDB:', error);
    return '';
  }
};

const parseAndConvertToLocal = (dateTime) => {
  if (!dateTime) {
    return null;
  }
  if (dateTime instanceof Date) {
    return dateTime;
  }
  
  const parsedDate = parseISO(dateTime);

  if (!isValid(parsedDate)) {
    console.error('Invalid date format');
    return null;
  }
  
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC to local time
  const localDate = new Date(parsedDate.toLocaleString('en-US', { timeZone }));

  return localDate;
};

export const formatDateTime = (dateTime) => {
  try {
    const date = parseAndConvertToLocal(dateTime);
    if (!date) return '';
    const formatted = dateFnsFormat(date, 'yyyy-MM-dd HH:mm');
    return formatted;
  } catch (error) {
    console.error('Error in formatDateTime:', error);
    return '';
  }
};

export const formatTime = (dateTime) => {
  try {
    const date = parseAndConvertToLocal(dateTime);
    if (!date) return '';
    const formatted = dateFnsFormat(date, 'HH:mm');
    return formatted;
  } catch (error) {
    console.error('Error in formatTime:', error);
    return '';
  }
};

export const formatDate = (date) => {
  try {
    const d = parseAndConvertToLocal(date);
    if (!d) return '';
    const formatted = dateFnsFormat(d, 'yyyy-MM-dd');
    return formatted;
  } catch (error) {
    console.error('Error in formatDate:', error);
    return '';
  }
};


export const getCurrentLocalDateTime = () => {
  try {
    const now = new Date();
    return formatDateTimeForDB(now);
  } catch (error) {
    console.error('Error in getCurrentLocalDateTime:', error);
    return '';
  }
};

export const getRelativeTime = (date) => {
  const now = new Date();
  const diff = now - new Date(date);
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) return `${seconds}秒前`;
  if (minutes < 60) return `${minutes}分鐘前`;
  if (hours < 24) return `${hours}小時前`;
  if (days === 0) return '今天';
  if (days === 1) return '昨天';
  if (days === 2) return '前天';
  if (days < 7) return `${days}天前`;
  if (weeks < 4) return `${weeks}週前`;
  if (months < 12) return `${months}個月前`;
  return `${years}年前`;
};

export const getCurrentLocalDate = () => {
  try {
    const now = new Date();
    return formatDate(now);
  } catch (error) {
    console.error('Error in getCurrentLocalDate:', error);
    return '';
  }
};


export const generateUniqueId = () => {
  try {
    return Date.now() * 1000 + Math.floor(Math.random() * 1000);
  } catch (error) {
    console.error('Error in generateUniqueId:', error);
    return null;
  }
};


export const formatElapsedTime = (startDate) => {
  try {
    const start = parseDate(startDate);
    if (!start) return '00:00:00';
    const now = new Date();
    const diff = now - start;
    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  } catch (error) {
    console.error('Error in formatElapsedTime:', error);
    return '00:00:00';
  }
};