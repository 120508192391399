import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  IconButton,
  Divider,
  Paper,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomDateTimePicker from "../common/DatetimePicker";
import itemService from "../../services/db/ItemService";

const SOURCE_ENUM = ["淘寶", "JLCPCB", "JLC3DP", "發票"];
const STATUS_ENUM = ["下訂", "出貨", "集運", "收貨", "結案"];
const CURRENCY_ENUM = ["TWD", "RMB", "USD"];
const DESTINATION_ENUM = ["集運倉", "台灣", "工廠"];

const UpdateOrderDialog = ({ open, order, onClose, onUpdate }) => {
  const [updatedOrder, setUpdatedOrder] = useState(order);
  const [updatedItems, setUpdatedItems] = useState(order.orderItems);
  const [isOrderChange,setIsOrderChange] = useState(false);
  useEffect(() => {
    if (open) {
      setUpdatedOrder(order);
      setUpdatedItems(order.orderItems);
      setIsOrderChange(false);
    }
  }, [open, order]);
  const handleOrderChange = (field) => (event) => {
    setUpdatedOrder({
      ...updatedOrder,
      [field]: event.target.value,
    });
    setIsOrderChange(true);
  };


  const handleItemChange = (index, field) => (event) => {
    const newItems = [...updatedItems];
    newItems[index] = {
      ...newItems[index],
      [field]: event.target.value,
    };
    setUpdatedItems(newItems);
  };

  const handleDeleteOrder = (order) => {
    setIsOrderChange(false);
    onClose();
  };

  const handleUpdateOrder = async (order) => {
    const result = await itemService.createItem("Order", order);
    setIsOrderChange(!result.success);
    onUpdate(order);
  };

  const handleDeleteItem = (index) => {
    const newItems = updatedItems.filter((_, i) => i !== index);
    setUpdatedItems(newItems);
  };

  const handleAddItem = () => {
    setUpdatedItems([
      ...updatedItems,
      { productName: "", specification: "", quantity: 0, price: 0 },
    ]);
  };
  const handleUpdateItem = () => {
    setUpdatedItems([
      ...updatedItems,
      { productName: "", specification: "", quantity: 0, price: 0 },
    ]);
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        編輯訂單
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
            <Typography variant="h6">訂單詳情</Typography>
            <Box>
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteOrder(updatedOrder.id)}
                style={{ marginRight: '10px' }}
              >
                刪除訂單
              </Button>
              {isOrderChange&&(
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateOrder(updatedOrder)}
              >
                更新訂單
              </Button>)}
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="flex-end">
            {/* 訂單字段 */}
            <Box flexBasis="48%" marginBottom={2}>
              <TextField
                fullWidth
                margin="dense"
                label="訂單號碼"
                value={updatedOrder.orderNumber}
                onChange={handleOrderChange("orderNumber")}
                required
              />
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <FormControl fullWidth margin="dense">
                <InputLabel>來源</InputLabel>
                <Select
                  value={updatedOrder.source}
                  onChange={handleOrderChange("source")}
                  label="來源"
                  required
                >
                  {SOURCE_ENUM.map((source) => (
                    <MenuItem key={source} value={source}>
                      {source}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <CustomDateTimePicker
                value={updatedOrder.orderDate}
                onChange={(newValue) => handleOrderChange("orderDate")({ target: { value: newValue } })}
                label="訂單日期"
                height={50}
              />
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <FormControl fullWidth margin="dense">
                <InputLabel>狀態</InputLabel>
                <Select
                  value={updatedOrder.status}
                  onChange={handleOrderChange("status")}
                  label="狀態"
                  required
                >
                  {STATUS_ENUM.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <TextField
                fullWidth
                margin="dense"
                label="物流單"
                value={updatedOrder.logisticNumber || ""}
                onChange={handleOrderChange("logisticNumber")}
              />
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <FormControl fullWidth margin="dense">
                <InputLabel>目的地</InputLabel>
                <Select
                  value={updatedOrder.destination}
                  onChange={handleOrderChange("destination")}
                  label="目的地"
                  required
                >
                  {DESTINATION_ENUM.map((destination) => (
                    <MenuItem key={destination} value={destination}>
                      {destination}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <TextField
                fullWidth
                margin="dense"
                label="總金額"
                type="number"
                value={updatedOrder.totalAmount}
                onChange={handleOrderChange("totalAmount")}
                required
              />
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <FormControl fullWidth margin="dense">
                <InputLabel>貨幣</InputLabel>
                <Select
                  value={updatedOrder.currency}
                  onChange={handleOrderChange("currency")}
                  label="貨幣"
                  required
                >
                  {CURRENCY_ENUM.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <TextField
                fullWidth
                margin="dense"
                label="重量 (kg)"
                type="number"
                value={updatedOrder.weight || ""}
                onChange={handleOrderChange("weight")}
              />
            </Box>
            <Box flexBasis="48%" marginBottom={2}>
              <TextField
                fullWidth
                margin="dense"
                label="體積 (m³)"
                type="number"
                value={updatedOrder.volume || ""}
                onChange={handleOrderChange("volume")}
              />
            </Box>
          </Box>
        </Paper>

        <Paper elevation={3} style={{ padding: "20px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
          >
            <Typography variant="h6">商品列表</Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddItem}
                style={{ marginRight: '10px' }}
              >
                新增商品
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateItem(updatedItems)}
              >
                更新商品
              </Button>
            </Box>
          </Box>
          {updatedItems.map((item, index) => (
            <Box key={index} marginBottom={2}>
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <Box flexBasis="23%" marginRight={2}>
                  <TextField
                    fullWidth
                    label="商品名稱"
                    value={item.productName}
                    onChange={handleItemChange(index, "productName")}
                    required
                  />
                </Box>
                <Box flexBasis="23%" marginRight={2}>
                  <TextField
                    fullWidth
                    label="規格"
                    value={item.specification || ""}
                    onChange={handleItemChange(index, "specification")}
                  />
                </Box>
                <Box flexBasis="15%" marginRight={2}>
                  <TextField
                    fullWidth
                    label="數量"
                    type="number"
                    value={item.quantity}
                    onChange={handleItemChange(index, "quantity")}
                    required
                  />
                </Box>
                <Box flexBasis="15%" marginRight={2}>
                  <TextField
                    fullWidth
                    label="價格"
                    type="number"
                    value={item.price}
                    onChange={handleItemChange(index, "price")}
                    required
                  />
                </Box>
                <Box flexBasis="10%">
                  <IconButton
                    onClick={() => handleDeleteItem(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {index < updatedItems.length - 1 && (
                <Divider style={{ margin: "10px 0" }} />
              )}
            </Box>
          ))}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateOrderDialog;