import React, { useState, useEffect, useRef, useCallback } from 'react';
import TextField from '@mui/material/TextField';

const DropdownSelector = ({ 
  currentValue,
  onValueChange,
  readOnly,
  allowedOptions,
  allOptions,
  placeholder,
  showDescription = true,
  allowManualInput = false,
  inputHeight = '40px',
  fontSize = '14px',
  maxDropdownHeight = '300px',
  maxDropdownWidth = '150%',
  minDropdownWidth = '100%',
  label // Parameter for label
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [dropdownWidth, setDropdownWidth] = useState('100%');
  const selectorRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (currentValue) {
      setInputValue(currentValue.label);
      setSelectedColor(currentValue.color || '');
    }
  }, [currentValue, allOptions]);

  const filterOptions = useCallback((value) => {
    if (!Array.isArray(allOptions) || !Array.isArray(allowedOptions)) {
      return [];
    }
    if (value === ' ') {
      return allOptions;
    }
    if (value.trim() === '') {
      return allowedOptions;
    }
    return allOptions.filter(option => 
      option.label.toLowerCase().includes(value.toLowerCase()) ||
      (option.description && option.description.toLowerCase().includes(value.toLowerCase()))
    );
  }, [allOptions, allowedOptions]);

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = filterOptions(value);
    setFilteredOptions(filtered);
    setIsOpen(true);
  }, [filterOptions]);

  const handleItemChange = useCallback((item) => {
    setInputValue(item.label);
    setSelectedColor(item.color || '');
    onValueChange(item);
    setIsOpen(false);
  }, [onValueChange]);

  const handleInputFocus = useCallback(() => {
    if (!readOnly) {
      setIsOpen(true);
      setFilteredOptions(allowedOptions);
    }
  }, [readOnly, allowedOptions]);

  const handleInputKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      const matchedOption = filteredOptions.find(option => 
        option.label.toLowerCase() === inputValue.toLowerCase()
      );
      if (matchedOption) {
        handleItemChange(matchedOption);
      } else if (allowManualInput) {
        onValueChange({ id: null, label: inputValue, color: '' });
        setSelectedColor('');
      }
      setIsOpen(false);
    }
  }, [filteredOptions, inputValue, handleItemChange, allowManualInput, onValueChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const calculateDropdownWidth = useCallback(() => {
    if (inputRef.current && dropdownRef.current && Array.isArray(filteredOptions)) {
      const inputWidth = inputRef.current.offsetWidth;
      const maxLabelWidth = Math.max(...filteredOptions.map(option => {
        const tempElement = document.createElement('span');
        tempElement.style.visibility = 'hidden';
        tempElement.style.position = 'absolute';
        tempElement.style.fontSize = fontSize;
        tempElement.textContent = option.label;
        document.body.appendChild(tempElement);
        const width = tempElement.offsetWidth;
        document.body.removeChild(tempElement);
        return width;
      }));

      const newWidth = Math.max(
        parseFloat(minDropdownWidth),
        Math.min(
          Math.max(inputWidth, maxLabelWidth + 40),
          inputWidth * (parseFloat(maxDropdownWidth) / 100)
        )
      );
      setDropdownWidth(`${newWidth}px`);
    }
  }, [filteredOptions, minDropdownWidth, maxDropdownWidth, fontSize]);

  useEffect(() => {
    if (isOpen) {
      calculateDropdownWidth();
    }
  }, [isOpen, calculateDropdownWidth]);

  return (
    <div className="relative" ref={selectorRef}>
      <TextField
        inputRef={inputRef}
        label={label}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleInputKeyDown}
        placeholder={placeholder}
        disabled={readOnly}
        fullWidth
        InputProps={{
          style: {
            height: inputHeight,
            fontSize: fontSize,
          },
          className: selectedColor,
        }}
      />
      {isOpen && Array.isArray(filteredOptions) && filteredOptions.length > 0 && (
        <div 
          ref={dropdownRef}
          className="absolute left-0 mt-1 bg-white shadow-lg rounded-md overflow-hidden z-10 overflow-y-auto"
          style={{ 
            width: dropdownWidth, 
            maxWidth: dropdownWidth,
            maxHeight: maxDropdownHeight
          }}
        >
          {filteredOptions.map(item => (
            <button
              key={item.id}
              type="button"
              onClick={() => handleItemChange(item)}
              className={`block w-full text-left px-3 py-2 hover:bg-gray-100 ${item.color || ''}`}
              title={showDescription ? item.description : ''}
              style={{ fontSize: fontSize }}
            >
              <div>{item.label}</div>
              {showDescription && item.description && (
                <div className="text-xs opacity-70">{item.description}</div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelector;