const transactionTypeTranslations = {
    Deposit: "存款",
    Withdrawal: "提款",
    Receive: "收款",
    Pay: "付款",
    Refund: "退款",
    Transfer: "轉帳"
  };
  
  const relatedEntityTypeTranslations = {
    // 使用者相關
    ProjectIncome: "專案收入",
    ProjectExpense: "專案支出",
    Wallet: "錢包",
  
    // 費用相關
    PlatformFee: "平台費用",
    Bonus: "獎勵金",
    TransactionFee: "手續費",
    Tax: "稅金",
  
    // 其他
    Adjustment: "帳務調整",
    Escrow: "託管"
  };
  
  const transactionTypeMapping = {
    // 專案相關
    ProjectIncome: {
      type1: "Receive",  // 收到專案款項
      type2: "Refund",   // 退回部分專案款項
      needTax: true,
      needFee: true,
      fee1: 0.2, // 20% 收入手續費
      fee2: -0.2, // 退款時返還手續費
    },
    ProjectExpense: {
      type1: "Pay",     // 支付專案款項
      type2: "Refund",  // 收到專案退款
      needTax: false,
      needFee: true,
      fee1: 0.1, // 10% 付款手續費
      fee2: -0.1, // 退款時返還手續費
    },
    // 錢包操作
    Wallet: {
      type1: "Withdrawal",  //出金
      type2: "Deposit",     //入金
      needTax: true,
      needFee: false,
    },
    // 平台互動
    PlatformFee: {
      type1: "Pay",    // 支付平台費用
      type2: "Refund", // 退還平台費用
      needTax: false,
      needFee: false,
    },
    Bonus: {
      type1: "Receive",  // 收到獎勵金
      type2: "Refund",   // 退回獎勵金（罕見情況）
      needTax: true,
      needFee: false,
    },
    // 其他
    Adjustment: {
      type1: "Pay",     // 向平台支付（如補交費用）
      type2: "Receive", // 從平台收到（如多收費用的退還）
      needTax: false,
      needFee: false,
    },
    Escrow: {
      type1: "Pay",     // 支付到託管帳戶
      type2: "Receive", // 從託管帳戶釋放
      needTax: false,
      needFee: true,
    },
  };
  
  // 翻譯函數
  const translate = (value, translations) => {
    return translations[value] || value;
  };
  
  export {
    transactionTypeTranslations,
    relatedEntityTypeTranslations,
    transactionTypeMapping,
    translate,
  };