import { DB_NAME, DB_VERSION } from './IndexedDBConfig';

export const clearIndexedDB = async () => {

  if (!window.indexedDB) {
    console.error("此瀏覽器不支持 IndexedDB");
    return Promise.reject(new Error("瀏覽器不支持 IndexedDB"));
  }

  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(DB_NAME, DB_VERSION);

    const timeoutId = setTimeout(() => {
      console.error("打開數據庫操作超時");
      reject(new Error('打開資料庫超時'));
    }, 5000);  // 5秒超時

    openRequest.onblocked = (event) => {
      clearTimeout(timeoutId);
      console.warn("數據庫操作被阻塞，可能是由於其他標籤頁仍在使用該數據庫");
    };

    openRequest.onerror = (event) => {
      clearTimeout(timeoutId);
      console.error(`打開數據庫時出錯: ${event.target.error}`);
      reject(new Error('打開資料庫失敗'));
    };

    openRequest.onupgradeneeded = (event) => {
    };

    openRequest.onsuccess = (event) => {
      clearTimeout(timeoutId);
      const db = event.target.result;

      const objectStoreNames = Array.from(db.objectStoreNames);

      if (objectStoreNames.length === 0) {
        db.close();
        resolve();
        return;
      }

      const transaction = db.transaction(objectStoreNames, 'readwrite');
      transaction.onerror = (event) => {
        console.error("事務錯誤:", event.target.error);
        reject(new Error('清空表格失敗'));
      };

      let completedStores = 0;
      objectStoreNames.forEach(storeName => {
        const objectStore = transaction.objectStore(storeName);
        const clearRequest = objectStore.clear();
        
        clearRequest.onsuccess = () => {
          completedStores++;
          if (completedStores === objectStoreNames.length) {
            db.close();
            resolve();
          }
        };

        clearRequest.onerror = (event) => {
          console.error(`清空表格 ${storeName} 失敗:`, event.target.error);
          reject(new Error(`清空表格 ${storeName} 失敗`));
        };
      });

      transaction.oncomplete = () => {
        db.close();
        resolve();
      };
    };
  }).then(() => {
    if ('caches' in window) {
      return caches.keys().then((cacheNames) => {
        return Promise.all(cacheNames.map(name => {
          return caches.delete(name);
        }));
      });
    }
  }).then(() => {
  }).catch(error => {
    console.error("清理過程中發生錯誤:", error);
    throw error;
  });
};

const TIMEOUT_MS = 100; // 5 秒超時
export const deleteIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
    let completed = false;

    const timeoutId = setTimeout(() => {
      if (!completed) {
        completed = true;
        console.warn('刪除資料庫操作超時');
        resolve(); // 即使超時也繼續執行
      }
    }, TIMEOUT_MS);

    deleteRequest.onerror = (event) => {
      if (!completed) {
        completed = true;
        clearTimeout(timeoutId);
        console.error('刪除資料庫失敗:', event.target.error);
        resolve(); // 即使失敗也繼續執行
      }
    };

    deleteRequest.onsuccess = (event) => {
      if (!completed) {
        completed = true;
        clearTimeout(timeoutId);
        resolve();
      }
    };

    deleteRequest.onblocked = (event) => {
      console.warn('刪除資料庫操作被阻塞，可能是因為有其他連接尚未關閉');
      // 不在這裡 resolve，讓它繼續等待直到成功或超時
    };
  });
};