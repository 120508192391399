import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchUserData, updateUserData } from '../../services/db/userService';
import '../../styles/ProfileComponent.css';

const ProfileComponent = () => {
  const location = useLocation();
  const [profileUser, setProfileUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('public');
  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const token = useMemo(() => localStorage.getItem('token'), []);
  const currentUser = useMemo(() => JSON.parse(localStorage.getItem('user')), []);

  const queryUserID = useMemo(() => {
    return currentUser.membershipLevelId > 1 
      ? (location.state?.userId || currentUser?.userId) 
      : currentUser?.userId;
  }, [currentUser, location.state]);

  const loadUserData = useCallback(async () => {
    if (!queryUserID) {
      setError('找不到使用者 ID');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const userDTO = await fetchUserData(queryUserID, token, currentUser);
      setProfileUser(userDTO);
      setEditedUser(userDTO);
    } catch (error) {
      setError('載入使用者資料失敗: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  }, [queryUserID, token, currentUser]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await updateUserData(queryUserID, editedUser, token, currentUser);
      setProfileUser(editedUser);
      setIsEditing(false);
    } catch (error) {
      setError('更新使用者資料失敗: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  }, [editedUser, queryUserID, token, currentUser]);

  const handleAvatarClick = () => {
    if (isEditing) {
      setShowAvatarModal(true);
    }
  };

  const handleAvatarChange = (newUrl) => {
    setEditedUser(prev => ({ ...prev, pictureUrl: newUrl }));
    setShowAvatarModal(false);
  };

  if (loading) {
    return <div className="loading-overlay"><div className="loading-spinner"></div></div>;
  }
  
  if (error) {
    return <div className="error-message">錯誤: {error}</div>;
  }

  if (!profileUser) {
    return <div className="error-message">找不到使用者資料</div>;
  }

  return (
    <div className="profile-page">
      <header className="profile-header">
        <div className="profile-header-content">
          <div className="profile-avatar-container" onClick={handleAvatarClick}>
            <img src={profileUser.pictureUrl} alt="個人頭像" className="profile-avatar" />
            {isEditing && <div className="avatar-edit-overlay">點擊編輯</div>}
          </div>
          <div className="profile-info">
            {isEditing ? (
              <input
                type="text"
                name="username"
                value={editedUser.username}
                onChange={handleInputChange}
                className="name-input"
                placeholder="輸入使用者名稱"
              />
            ) : (
              <h1>{profileUser.username}</h1>
            )}
            {profileUser.membershipLevelId && <p>會員等級: {profileUser.membershipLevelId}</p>}
          </div>
        </div>
      </header>

      <div className="profile-body">
        <nav className="profile-nav">
          <button 
            className={`nav-item ${activeTab === 'public' ? 'active' : ''}`}
            onClick={() => setActiveTab('public')}
          >
            &#127757; 公開資料
          </button>
          <button 
            className={`nav-item ${activeTab === 'private' ? 'active' : ''}`}
            onClick={() => setActiveTab('private')}
          >
            &#128274; 私密資料
          </button>
        </nav>

        <main className="profile-content">
          {activeTab === 'public' && (
            <section className="profile-section">
              <h2>公開資料</h2>
              <InfoItem label="電子信箱" name="email" value={profileUser.email} isEditing={isEditing} onChange={handleInputChange} />
              <InfoItem label="名字" name="givenName" value={profileUser.givenName} isEditing={isEditing} onChange={handleInputChange} />
              <InfoItem label="姓氏" name="familyName" value={profileUser.familyName} isEditing={isEditing} onChange={handleInputChange} />
              <h3>統計數據</h3>
              <div className="stats-grid">
                <StatItem label="評論數" value={profileUser.commentsCount} link="/comments" />
                <StatItem label="作品數" value={profileUser.portfoliosCount} link="/portfolios" />
                <StatItem label="任務評分數" value={profileUser.taskRatingsCount} link="/task-ratings" />
                <StatItem label="團隊成員數" value={profileUser.teamMembersCount} link="/team-members" />
                <StatItem label="使用者認證數" value={profileUser.userCertificationsCount} link="/certifications" />
              </div>
            </section>
          )}

          {activeTab === 'private' && (
            <section className="profile-section">
              <h2>私密資料</h2>
              <InfoItem label="推薦人 ID" name="referredByUserId" value={profileUser.referredByUserId} isEditing={isEditing} onChange={handleInputChange} />
              <InfoItem label="進階設定" name="advancedSettings" value={profileUser.advancedSettings} isEditing={isEditing} onChange={handleInputChange} />
              <h3>統計數據</h3>
              <div className="stats-grid">
                <StatItem label="錢包餘額" value={profileUser.walletBalance} link="/wallet" />
                <StatItem label="審核流程數" value={profileUser.approvalProcessesCount} link="/approval-processes" />
                <StatItem label="好友數" value={profileUser.friendshipCount} link="/friends" />
                <StatItem label="通知數" value={profileUser.notificationsCount} link="/notifications" />
                <StatItem label="使用者公司關聯數" value={profileUser.userCompanyRelationsCount} link="/company-relations" />
              </div>
            </section>
          )}

          {queryUserID === currentUser.userId && (
            <div className="action-buttons">
              {isEditing ? (
                <>
                  <button onClick={handleSubmit} className="btn btn-primary">&#128190; 儲存</button>
                  <button onClick={() => setIsEditing(false)} className="btn btn-secondary">&#10060; 取消</button>
                </>
              ) : (
                <button onClick={() => setIsEditing(true)} className="btn btn-primary">&#9998; 編輯資料</button>
              )}
            </div>
          )}
        </main>
      </div>

      {showAvatarModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>更改頭像</h2>
            <input
              type="text"
              value={editedUser.pictureUrl}
              onChange={(e) => handleAvatarChange(e.target.value)}
              placeholder="輸入新的頭像 URL"
            />
            <button onClick={() => setShowAvatarModal(false)}>關閉</button>
          </div>
        </div>
      )}
    </div>
  );
};

const InfoItem = ({ label, name, value, isEditing, onChange }) => {
  if (value === null) return null;
  
  return (
    <div className="info-item">
      <span className="info-label">{label}:</span>
      {isEditing ? (
        <input 
          type="text" 
          name={name}
          value={value} 
          onChange={onChange}
          className="info-input"
        />
      ) : (
        <span className="info-value">{value}</span>
      )}
    </div>
  );
};

const StatItem = ({ label, value, link }) => (
  <a href={link} className="stat-item">
    <div className="stat-label">{label}</div>
    <div className="stat-value">{value}</div>
  </a>
);

export default ProfileComponent;