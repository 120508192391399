import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import QuillEditor from "../common/QuillEditor";

export const parseTaobaoShippingOrders = (text) => {
  const orders = [];

  // 創建一個臨時的DOM元素來解析HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;

  // 找到所有的表格行
  const rows = tempDiv.querySelectorAll("tr");

  // 跳過表頭行
  for (let i = 1; i < rows.length; i++) {
    const cells = rows[i].querySelectorAll("td");

    if (cells.length >= 7) {
      // 確保行有足夠的單元格
      const orderNumber = cells[1].textContent.trim();
      const logisticCompany = cells[cells.length - 2].textContent.trim();
      const logisticNumber = cells[cells.length - 1].textContent.trim();

      if (orderNumber && logisticCompany && logisticNumber) {
        orders.push({
          orderNumber,
          logisticCompany,
          logisticNumber,
          source: "淘寶",
        });
      }
    }
  }
  return orders;
};
const parseVolumeWeightShippingOrders = (text) => {
  const orders = [];

  // 創建一個臨時的DOM元素來解析HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;

  // 使用正則表達式匹配快遞單號、重量和體積重
  const regex =
    /快遞單號：(\S+)[\s\S]*?重量：(\d+\.?\d*)[\s\S]*?体积重：(\d+\.?\d*)/g;
  let match;

  while ((match = regex.exec(text)) !== null) {
    let [, logisticNumber, weight, volumeWeight] = match;
    // 清理 logisticNumber
    logisticNumber = logisticNumber.split("<")[0]; // 移除 </span> 及之後的內容
    logisticNumber = logisticNumber.split("-")[0]; // 如果有 '-'，只保留 '-' 之前的部分

    orders.push({
      logisticNumber,
      weight: parseFloat(weight),
      volume: parseFloat(volumeWeight),
      source: "集運",
    });
  }
  return orders;
};

const parseCSVData = (text) => {
  const lines = text.split("\n");
  const headers = lines[0].split(",").map((header) => header.trim());

  return lines
    .slice(1)
    .map((line) => {
      const values = line.split(",");
      const order = {};
      headers.forEach((header, index) => {
        order[header] = values[index] ? values[index].trim() : "";
      });
      return {
        orderNumber: order["訂單"] || "",
        weight: parseFloat(order["種"]) || 0,
        logisticNumber: order["運"] || "",
        logisticCompany: "-",
        source: "舊數據",
      };
    })
    .filter((order) => order.orderNumber && order.logisticNumber);
};

export const ConsolidatedShippingInputDialog = ({
  open,
  onClose,
  onOrdersParsed,
}) => {
  const [textContent, setTextContent] = useState("");
  const [error, setError] = useState("");

  const handleParseWeightVolume = () => {
    setError("");
    try {
      const parsedOrders = parseVolumeWeightShippingOrders(textContent);
      if (parsedOrders.length === 0) {
        setError("沒有找到任何訂單，請確保貼上了正確的內容。");
      } else {
        onOrdersParsed(parsedOrders);
        onClose();
      }
    } catch (err) {
      console.error("Error parsing orders:", err);
      setError("解析訂單時出錯: " + err.message);
    }
  };

  const handleParseTaobao = () => {
    setError("");
    try {
      const parsedOrders = parseTaobaoShippingOrders(textContent);
      if (parsedOrders.length === 0) {
        setError("沒有找到任何訂單，請確保貼上了正確的內容。");
      } else {
        onOrdersParsed(parsedOrders);
        onClose();
      }
    } catch (err) {
      console.error("Error parsing orders:", err);
      setError("解析訂單時出錯: " + err.message);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const text = e.target.result;
          const parsedOrders = parseCSVData(text);
          if (parsedOrders.length === 0) {
            setError("沒有找到任何訂單，請確保上傳了正確的 CSV 檔案。");
          } else {
            onOrdersParsed(parsedOrders);
            onClose();
          }
        } catch (err) {
          console.error("Error parsing CSV:", err);
          setError("解析 CSV 時出錯: " + err.message);
        }
      };
      reader.readAsText(file);
    }
  };
  /*       
 {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            上傳 CSV 檔案
          </Button>
        </label>
         */
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>輸入集運訂單</DialogTitle>
      <DialogContent>
        <QuillEditor
          value={textContent}
          onChange={setTextContent}
          minHeight={400}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button
          onClick={handleParseTaobao}
          variant="contained"
          disabled={!textContent}
        >
          匯入淘寶物流
        </Button>
        <Button
          onClick={handleParseWeightVolume}
          variant="contained"
          disabled={!textContent}
        >
          匯入材積
        </Button>
      </DialogActions>
    </Dialog>
  );
};
