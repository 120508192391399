import React, { useEffect, useRef, useState, useCallback } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import '../../styles/QuillEditor.css';
import gcsService from '../../services/db/GcsService';

const QuillEditor = ({ 
  url, 
  value, 
  onChange, 
  readOnly = false, 
  renderMode = false,
  maxLength = 150 
}) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const [editorHeight, setEditorHeight] = useState(300);
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncatedContent, setTruncatedContent] = useState('');
  const [fullContent, setFullContent] = useState('');

  const initializeQuill = useCallback(async () => {
    if (editorRef.current && !quillRef.current) {
      const theme = renderMode ? 'bubble' : 'snow';
      const modules = renderMode ? { toolbar: false } : {
        toolbar: readOnly ? false : [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image', 'video'],
          ['clean']
        ],
      };

      quillRef.current = new Quill(editorRef.current, {
        theme,
        modules,
        readOnly: readOnly || renderMode,
      });

      let content = '';
      if (url) {
        try {
          content = url.endsWith('.html')
            ? await gcsService.readContent(url)
            : url;
        } catch (error) {
          console.error("Error loading content:", error);
          content = url;
        }
      } else if (value) {
        content = value;
      }

      quillRef.current.clipboard.dangerouslyPasteHTML(content);

      if (renderMode) {
        const fullText = quillRef.current.getText();
        setFullContent(content);
        setTruncatedContent(
          fullText.length > maxLength
            ? fullText.slice(0, maxLength) + '...'
            : fullText
        );
      }
    }
  }, [url, value, readOnly, renderMode, maxLength]);

  useEffect(() => {
    initializeQuill();
  }, [initializeQuill]);

  useEffect(() => {
    if (quillRef.current && !readOnly && !renderMode) {
      const handleTextChange = (delta, oldContents, source) => {
        if (source === "user") {
          const newContent = quillRef.current.root.innerHTML;
          onChange(newContent, source);
        }
      };
      quillRef.current.on('text-change', handleTextChange);
      return () => {
        quillRef.current.off('text-change', handleTextChange);
      };
    }
  }, [onChange, readOnly, renderMode]);

  const handleResize = useCallback((e) => {
    if (!renderMode) {
      const newHeight = e.clientY - editorRef.current.getBoundingClientRect().top;
      setEditorHeight(newHeight);
    }
  }, [renderMode]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  if (renderMode) {
    return (
      <div>
        <div 
          ref={editorRef} 
          className={isExpanded ? '' : 'hidden'} 
        />
        {!isExpanded && <p>{truncatedContent}</p>}
        {fullContent.length > maxLength && (
          <button 
            onClick={toggleExpand} 
            className="text-blue-500 hover:underline mt-2"
          >
            {isExpanded ? '收起' : '查看更多'}
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="editor-container">
      <div 
        ref={editorRef} 
        style={{ height: `${editorHeight}px` }} 
        className={`quill-editor ${readOnly ? 'read-only' : ''}`} 
      />
      {!readOnly && (
        <div 
          className="resize-handle" 
          onMouseDown={(e) => {
            e.preventDefault();
            document.addEventListener('mousemove', handleResize);
            document.addEventListener('mouseup', () => {
              document.removeEventListener('mousemove', handleResize);
            }, { once: true });
          }} 
        />
      )}
    </div>
  );
};

export default QuillEditor;