import React, { useState, useEffect, useCallback } from 'react';
import { 
  Drawer, 
  IconButton, 
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


// ResizableDrawer component
const ResizableDrawer = ({ 
  isOpen, 
  onClose, 
  initialWidth = 400, 
  minWidth = 200, 
  maxWidth = 600, 
  topOffset = true,
  children 
}) => {
  const [drawerWidth, setDrawerWidth] = useState(initialWidth);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const newWidth = window.innerWidth - e.clientX;
      setDrawerWidth(Math.max(minWidth, Math.min(maxWidth, newWidth)));
    }
  }, [isDragging, minWidth, maxWidth]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      variant="persistent"
      PaperProps={{
        sx: {
          width: drawerWidth,
          height: topOffset ? 'calc(100% - 64px)' : '100%',
          top: topOffset ? 64 : 0,
          overflow: 'hidden',
        },
      }}
    >
      <Box 
        sx={{ 
          position: 'absolute', 
          left: 0, 
          top: 0, 
          bottom: 0, 
          width: '4px', 
          cursor: 'ew-resize',
          '&:hover': { backgroundColor: 'action.hover' },
        }} 
        onMouseDown={handleMouseDown}
      />
      <Box sx={{ height: '100%', overflow: 'auto', p: 2 }}>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ mt: 5 }}>
          {React.cloneElement(children, { drawerWidth })}
        </Box>
      </Box>
    </Drawer>
  );
};
export default ResizableDrawer;
