import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField } from '@mui/material';

const parseJLC3DPrintOrders = (text) => {

  // 提取訂單日期
  const dateRegex = /提交订单\s*(\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2})/;
  const dateMatch = dateRegex.exec(text);
  const orderDate = dateMatch ? dateMatch[1] : '';

  // 找到訂單信息的開始位置
  const orderInfoStart = text.indexOf(" 订单信息\n订单编号：");
  if (orderInfoStart === -1) {
    return [];
  }

  // 只解析訂單信息部分
  const orderText = text.slice(orderInfoStart);

  const orderRegex = /订单编号：(\w+)\s*\('样品'\)/;
  const itemRegex = /(\d+)\s+预览图3D预览\s+([\w.]+)\s+材料:\s*(.*?)\s+材料颜色:\s*(.*?)\s+(?:表面处理:\s*(.*?)\s+)?尺寸:\s*(.*?)\s+体积:\s*([\d.]+)cm³\s+表面积:\s*([\d.]+)cm²\s+面片:\s*(\d+)\s+破损面片:\s*(\d+)\s+交期:\s*(.*?)\s+产品应用场景:.*?￥([\d.]+)\s+(\d+)\s+￥([\d.]+)(?:\s+表处优惠：\s+-(￥[\d.]+))?\s+已发货/gs;
  const totalAmountRegex = /实付款：￥([\d.]+)/;

  const orderMatch = orderRegex.exec(orderText);
  if (!orderMatch) {
    return [];
  }
  const [, orderNumber] = orderMatch;
  const items = [];
  let itemMatch;
  let totalVolume = 0;
  while ((itemMatch = itemRegex.exec(orderText)) !== null) {
    const [, serialNumber, productName, material, color, surfaceTreatment, size, volume, surfaceArea, meshCount, brokenMeshCount, deliveryTime, unitPrice, quantity, price, discount] = itemMatch;
    
    const specification = `材料: ${material}\n材料颜色: ${color}${surfaceTreatment ? `\n表面处理: ${surfaceTreatment}` : ''}`;
    
    items.push({
      serialNumber: parseInt(serialNumber),
      productName,
      specification,
      size,
      volume: parseFloat(volume),
      surfaceArea: parseFloat(surfaceArea),
      meshCount: parseInt(meshCount),
      brokenMeshCount: parseInt(brokenMeshCount),
      deliveryTime,
      unitPrice: parseFloat(unitPrice),
      quantity: parseInt(quantity),
      price: parseFloat(price),
      discount: discount ? parseFloat(discount.replace('￥', '')) : 0
    });
    totalVolume += parseFloat(volume) * parseInt(quantity);
  }
  console.log("商品解析完成，共找到", items.length, "個商品");

  const volumeInCubicMeters = totalVolume / 1000000; // Convert cm³ to m³
  const weightInKg = volumeInCubicMeters * 1000; // Assuming density of 1000 kg/m³ (water's density)

  const totalAmountMatch = totalAmountRegex.exec(orderText);
  const totalAmount = totalAmountMatch ? parseFloat(totalAmountMatch[1]) : 0;

  const order = {
    source:"JLC3DP",
    orderNumber,
    totalAmount,
    orderDate,
    weight: weightInKg.toFixed(4), // kg
    volume: volumeInCubicMeters.toFixed(6), // m³
    orderItems: items,
    toWarehouse: true,
  };
  return [order];
};

export const JLC3DPrintOrderDialog = ({ open, onClose, onOrdersParsed }) => {
  const [textContent, setTextContent] = useState('');
  const [error, setError] = useState('');

  const handleParse = () => {
    setError('');
    try {
      const parsedOrders = parseJLC3DPrintOrders(textContent);
      if (parsedOrders.length === 0) {
        setError('沒有找到任何訂單，請確保貼上了正確的內容。');
      } else {
        onOrdersParsed(parsedOrders);
        onClose();
      }
    } catch (err) {
      console.error('解析過程中出現錯誤:', err);
      setError('解析訂單時出錯: ' + err.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>導入嘉立創3D打印訂單</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          請從嘉立創3D打印訂單頁面複製訂單數據並貼到下面的文本框中。
          確保包含完整的訂單信息。
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          label="貼上3D列印訂單文字內容"
          value={textContent}
          onChange={(e) => setTextContent(e.target.value)}
          sx={{ mb: 2 }}
        />
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleParse} variant="contained" disabled={!textContent}>
          解析訂單
        </Button>
      </DialogActions>
    </Dialog>
  );
};