import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";

import itemService from "../../services/db/ItemService";
import { getUser, getToken } from "../../services/authService";
import ItemEditModal from "../common/ItemEditModal";
import { convertToDTO } from "../../types/relationsTypes";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const CUSTOM_EVENT_NAME = "dataUpdated";
const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [access, setAccess] = useState(false);
  const [parentContainerWidth, setParentContainerWidth] = useState(
    window.innerWidth
  );

  const loadClients = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const user = getUser();
      const token = getToken();

      let fetchedClients = [];
      let companyData = [];
      if (user.membershipLevelId > 10) {
        setAccess(true);
        companyData = await itemService.getCompany(token, user);
      } else {
        companyData = await itemService.getCompanyByAccount(user.userId);
      }
      fetchedClients = companyData.map((company) =>
        convertToDTO({ tableType: "Company", data: company })
      );
      setClients(fetchedClients);
    } catch (error) {
      console.error("Failed to load clients:", error);
      setError("Failed to load clients. Please try again.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadClients();
    const handleDataUpdated = () => {
      loadClients();
    };
    const handleResize = () => setParentContainerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [loadClients]);

  const handleOpenEditModal = (client = null) => {
    setSelectedItem(client || { tableType: "Company" });
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const handleSave = async () => {
    handleCloseEditModal();
  };

  const columns = useMemo(
    () => [
      { id: "registerId", label: "統一編號", minWidth: 100 },
      { id: "industry", label: "產業", minWidth: 100 },
      { id: "companyName", label: "公司名稱", minWidth: 170 },
      { id: "shortName", label: "簡稱", minWidth: 100 },
      { id: "address", label: "地址", minWidth: 200 },
      { id: "phoneNumber", label: "電話", minWidth: 130 },
      { id: "website", label: "網站", minWidth: 150 },
      { id: "actions", label: "操作", minWidth: 100, align: "center" },
    ],
    []
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%", mb: 2 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={loadClients}>重試</Button>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Typography variant="h4" gutterBottom component="div">
        客戶管理
      </Typography>
      {access && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenEditModal()}
          sx={{ mb: 2 }}
        >
          新增客戶
        </Button>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={client.companyID}
              >
                {columns.map((column) => {
                  if (column.id === "actions") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {access && (
                          <>
                            <IconButton
                              onClick={() => handleOpenEditModal(client)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </StyledTableCell>
                    );
                  }
                  const value = client[column.id];
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.id === "website" ? (
                        <Link
                          href={value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value}
                        </Link>
                      ) : (
                        value
                      )}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent={"Company"}
          readOnly={false}
          tableType={"Company"}
          setItemList={loadClients}
          setErrorMessage={setError}
          parentContainerWidth={parentContainerWidth}
          onSave={handleSave}
          onClose={handleCloseEditModal}
        />
      )}
    </Box>
  );
};

export default ClientManagement;
