import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import DropdownSelector from './DropdownSelector';
import itemService from "../../../services/db/ItemService";

const colorPalette = [
  { bg: 'bg-red-200', text: 'text-red-800' },
  { bg: 'bg-pink-200', text: 'text-pink-800' },
  { bg: 'bg-rose-200', text: 'text-rose-800' },
  { bg: 'bg-orange-200', text: 'text-orange-800' },
  { bg: 'bg-amber-200', text: 'text-amber-800' },
  { bg: 'bg-yellow-200', text: 'text-yellow-800' },
  { bg: 'bg-lime-200', text: 'text-lime-800' },
  { bg: 'bg-green-200', text: 'text-green-800' },
  { bg: 'bg-emerald-200', text: 'text-emerald-800' },
  { bg: 'bg-teal-200', text: 'text-teal-800' },
  { bg: 'bg-cyan-200', text: 'text-cyan-800' },
  { bg: 'bg-sky-200', text: 'text-sky-800' },
  { bg: 'bg-blue-200', text: 'text-blue-800' },
  { bg: 'bg-indigo-200', text: 'text-indigo-800' },
  { bg: 'bg-violet-200', text: 'text-violet-800' },
  { bg: 'bg-purple-200', text: 'text-purple-800' },
  { bg: 'bg-fuchsia-200', text: 'text-fuchsia-800' },
  { bg: 'bg-slate-200', text: 'text-slate-800' },
  { bg: 'bg-gray-200', text: 'text-gray-800' },
  { bg: 'bg-zinc-200', text: 'text-zinc-800' },
  { bg: 'bg-neutral-200', text: 'text-neutral-800' },
  { bg: 'bg-stone-200', text: 'text-stone-800' },
];

const generateRandomColor = () => {
  const randomColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];
  return `${randomColor.bg} ${randomColor.text}`;
};

const StatusSelector = React.memo(({ currentStatusId, onStatusChange, readOnly, itemType,height ,label}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [error, setError] = useState(null);
  
  const lastValidPropsRef = useRef({ currentStatusId, readOnly, itemType });
  const lastSelectedStatusRef = useRef(null);

  const fetchStatuses = useCallback(async () => {
    try {
      const statusOptions = await itemService.getStatus('appliesTo', itemType);
      const mappedStatuses = statusOptions.map(status => ({
        id: status.statusId,
        label: status.statusName,
        description: status.description || '',
        color: status.color || generateRandomColor()
      }));
      
      setStatuses(prevStatuses => {
        const mergedStatuses = [...mappedStatuses];
        prevStatuses.forEach(prevStatus => {
          if (!mergedStatuses.some(s => s.id === prevStatus.id)) {
            mergedStatuses.push(prevStatus);
          }
        });
        return mergedStatuses;
      });
      
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching statuses:', error);
      setError('載入狀態選項失敗');
      setIsLoading(false);
    }
  }, [itemType]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    if (currentStatusId !== undefined && readOnly !== undefined && itemType !== undefined) {
      lastValidPropsRef.current = { currentStatusId, readOnly, itemType };
    }
  }, [currentStatusId, readOnly, itemType]);

  useEffect(() => {
    if (!isLoading && currentStatusId) {
      const currentStatus = statuses.find(status => status.id === currentStatusId) || lastSelectedStatusRef.current;
      if (currentStatus) {
        setSelectedStatus(currentStatus);
        lastSelectedStatusRef.current = currentStatus;
      }
    }
  }, [currentStatusId, statuses, isLoading]);

  const handleStatusChange = useCallback((newValue) => {
    let updatedStatus = newValue;
    if (typeof newValue === 'string' || !newValue.id) {
      const tempId = `temp_${Date.now()}`;
      const randomColor = generateRandomColor();
      updatedStatus = {
        id: tempId,
        label: typeof newValue === 'string' ? newValue : newValue.label,
        color: randomColor,
        StatusOption: {
          statusId: tempId,
          statusName: newValue.label,
          appliesTo: itemType,
          color: randomColor,
          tableType: 'StatusOption'
        }
      };
      
      setStatuses(prevStatuses => [...prevStatuses, updatedStatus]);
    } 
    setSelectedStatus(updatedStatus);
    lastSelectedStatusRef.current = updatedStatus;
    onStatusChange(updatedStatus);
  }, [onStatusChange, itemType]);

  const memoizedContent = useMemo(() => {
    const renderReadOnlyStatus = () => {
      if (!selectedStatus && !lastSelectedStatusRef.current) {
        return null;
      }
      const status = selectedStatus || lastSelectedStatusRef.current;
      return (
        <span className={`px-2 py-1 rounded-full text-xs ${status.color}`}>
          {status.label}
        </span>
      );
    };

    if (isLoading) {
      return <div className="text-gray-500">正在載入狀態選項...</div>;
    }

    if (error) {
      return <div className="text-red-500">{error}</div>;
    }

    if (readOnly) {
      return renderReadOnlyStatus();
    }

    return (
      <DropdownSelector
        key={statuses.length}
        currentValue={selectedStatus || lastSelectedStatusRef.current}
        onValueChange={handleStatusChange}
        readOnly={false}
        allowedOptions={statuses}
        allOptions={statuses}
        placeholder="輸入或選擇狀態"
        showDescription={false}
        allowManualInput={true}
        inputHeight={height}
        label={label}
      />
    );
  }, [isLoading, error, selectedStatus, handleStatusChange, statuses, readOnly, height, label]);

  return memoizedContent;
});


StatusSelector.propTypes = {
  currentStatusId: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string
};
export default StatusSelector;