import React, { useState, useEffect } from 'react';
import {
  Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Collapse, IconButton, Typography, TextField, Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatDate } from '../../utils/dateUtils';

const EditableTreeList = ({ orders, onUpdateOrder }) => {
  const [expandedItems, setExpandedItems] = useState({});
  const [editableOrders, setEditableOrders] = useState(orders);

  useEffect(() => {
    setEditableOrders(orders);
  }, [orders]);

  const handleToggle = (orderId) => {
    setExpandedItems(prev => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const handleOrderChange = (orderId, field, value) => {
    const updatedOrders = editableOrders.map(order => 
      order.orderId === orderId ? { ...order, [field]: value } : order
    );
    setEditableOrders(updatedOrders);
    onUpdateOrder(updatedOrders.find(order => order.orderId === orderId));
  };

  const handleItemChange = (orderId, itemIndex, field, value) => {
    const updatedOrders = editableOrders.map(order => {
      if (order.orderId === orderId) {
        const updatedItems = order.orderItems.map((item, index) => 
          index === itemIndex ? { ...item, [field]: value } : item
        );
        return { ...order, orderItems: updatedItems };
      }
      return order;
    });
    setEditableOrders(updatedOrders);
    onUpdateOrder(updatedOrders.find(order => order.orderId === orderId));
  };

  const EditableCell = ({ value, onChange, type = 'text' }) => (
    <TextField
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      variant="standard"
      type={type}
      fullWidth
    />
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>來源</TableCell>
            <TableCell>訂單號碼</TableCell>
            <TableCell>日期</TableCell>
            <TableCell>總金額</TableCell>
            <TableCell>重量 (kg)</TableCell>
            <TableCell>體積 (m³)</TableCell>
            <TableCell>物流號碼</TableCell>
            <TableCell>發往倉庫</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editableOrders?.map((order) => (
            <React.Fragment key={order.orderId}>
              <TableRow>
                <TableCell>
                  <IconButton size="small" onClick={() => handleToggle(order.orderId)}>
                    {expandedItems[order.orderId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.source}
                    onChange={(value) => handleOrderChange(order.orderId, 'source', value)}
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.orderNumber}
                    onChange={(value) => handleOrderChange(order.orderId, 'orderNumber', value)}
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={formatDate(order.orderDate)}
                    onChange={(value) => handleOrderChange(order.orderId, 'orderDate', value)}
                    type="date"
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.totalAmount}
                    onChange={(value) => handleOrderChange(order.orderId, 'totalAmount', value)}
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.weight}
                    onChange={(value) => handleOrderChange(order.orderId, 'weight', value)}
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.volume}
                    onChange={(value) => handleOrderChange(order.orderId, 'volume', value)}
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <EditableCell
                    value={order.logisticNumber}
                    onChange={(value) => handleOrderChange(order.orderId, 'logisticNumber', value)}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={order.toWarehouse}
                    onChange={(e) => handleOrderChange(order.orderId, 'toWarehouse', e.target.checked)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                  <Collapse in={expandedItems[order.orderId]} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>商品名稱</TableCell>
                            <TableCell>規格</TableCell>
                            <TableCell>數量</TableCell>
                            <TableCell>價格</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.orderItems?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <EditableCell
                                  value={item.productName}
                                  onChange={(value) => handleItemChange(order.orderId, index, 'productName', value)}
                                />
                              </TableCell>
                              <TableCell>
                                <EditableCell
                                  value={item.specification}
                                  onChange={(value) => handleItemChange(order.orderId, index, 'specification', value)}
                                />
                              </TableCell>
                              <TableCell>
                                <EditableCell
                                  value={item.quantity}
                                  onChange={(value) => handleItemChange(order.orderId, index, 'quantity', value)}
                                  type="number"
                                />
                              </TableCell>
                              <TableCell>
                                <EditableCell
                                  value={item.price}
                                  onChange={(value) => handleItemChange(order.orderId, index, 'price', value)}
                                  type="number"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {order.logistics && (
                        <Box mt={2}>
                          <Typography variant="subtitle1">物流信息</Typography>
                          <Typography>
                            追蹤號碼: 
                            <EditableCell
                              value={order.logistics.trackingNumber}
                              onChange={(value) => handleOrderChange(order.orderId, 'logistics', { ...order.logistics, trackingNumber: value })}
                            />
                          </Typography>
                          <Typography>
                            物流公司: 
                            <EditableCell
                              value={order.logistics.logisticsCompany}
                              onChange={(value) => handleOrderChange(order.orderId, 'logistics', { ...order.logistics, logisticsCompany: value })}
                            />
                          </Typography>
                          <Typography>
                            發貨日期: 
                            <EditableCell
                              value={order.logistics.shippingDate}
                              onChange={(value) => handleOrderChange(order.orderId, 'logistics', { ...order.logistics, shippingDate: value })}
                              type="date"
                            />
                          </Typography>
                          <Typography>
                            預計送達日期: 
                            <EditableCell
                              value={order.logistics.estimatedDeliveryDate}
                              onChange={(value) => handleOrderChange(order.orderId, 'logistics', { ...order.logistics, estimatedDeliveryDate: value })}
                              type="date"
                            />
                          </Typography>
                          <Typography>
                            實際送達日期: 
                            <EditableCell
                              value={order.logistics.actualDeliveryDate}
                              onChange={(value) => handleOrderChange(order.orderId, 'logistics', { ...order.logistics, actualDeliveryDate: value })}
                              type="date"
                            />
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EditableTreeList;