import React, { useState, useEffect, useCallback, useMemo } from "react";
import { List, ListItem, Typography, Chip, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import itemService from "../../services/db/ItemService";
import { formatDateTime, formatDate, formatTime } from "../../utils/dateUtils";
import {
  getTableTypeName,
  getColorStyles,
  priorityOrder,
} from "../../utils/utils";
import ItemEditModal from "../common/ItemEditModal";
import { getUser } from "../../services/authService";
const CUSTOM_EVENT_NAME = "dataUpdated";
const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const formatNumber = (num) => {
  return num != null ? num.toLocaleString() : "-";
};
const ItemHeader = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const ItemName = styled(Typography)({
  fontWeight: "bold",
  flexGrow: 1,
});

const ActionButtons = styled(Box)({
  display: "flex",
});

const ItemDetails = styled(Typography)({
  marginTop: "4px",
  color: "rgba(0, 0, 0, 0.6)",
});

const StyledChip = styled(Chip)(({ colorstyle }) => ({
  marginRight: "4px",
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));

const ChildItemsList = ({
  childItemType,
  setError,
  parentId,
  parentItem,
  preVersion,
  readOnly = false,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const currentUser = useMemo(() => getUser(), []);
  const [childItem, setChildItem] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const children = await itemService.getChildString(
          childItemType,
          String(parentId)
        );
        if (Array.isArray(children) && children.length > 0) {
          setChildItem(children);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load necessary data");
      }
    };
    fetchData();
    const handleDataUpdated = () => {
      fetchData();
    };
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      // 移除自定義事件監聽器
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [setError, parentId, childItemType]);

  const updateChildItems = useCallback((newChildren) => {
    setChildItem((prevItems) => {
      // 創建一個 Map 來快速查找現有項目
      const existingItemMap = new Map(prevItems.map((item) => [item.id, item]));
      const updatedItems = [];
      const addedItems = [];

      newChildren.forEach((newItem) => {
        const existingItem = existingItemMap.get(newItem.id);
        if (existingItem) {
          // 如果項目存在，檢查是否有更新
          if (JSON.stringify(existingItem) !== JSON.stringify(newItem)) {
            updatedItems.push(newItem);
          } else {
            updatedItems.push(existingItem); // 保持原有引用
          }
        } else {
          // 新項目
          addedItems.push(newItem);
        }
        // 從 Map 中移除已處理的項目
        existingItemMap.delete(newItem.id);
      });
      Array.from(existingItemMap.keys());

      return [...updatedItems, ...addedItems];
    });
  }, []);

  const handleDeleteChildItem = async (childItemId) => {
    if (readOnly) return;
    if (window.confirm(`確定要刪除這個${childItemType}嗎？`)) {
      try {
        await itemService.deleteItem(childItemType, childItemId);
        setChildItem((prev) => prev.filter((item) => item.id !== childItemId));
      } catch (error) {
        console.error(`${childItemType}刪除失敗:`, error);
        setError(`${childItemType}刪除失敗`);
      }
    }
  };

  const handleNew = () => {
    if (parentItem && parentItem.cost >= parentItem.budget) {
      alert(
        `${getTableTypeName(parentItem.tableType)}預算不足：${
          parentItem.name
        }的成本已超出預算。`
      );
      return;
    }
    setIsEditModalOpen(true);
    setSelectedItem({ parentId, tableType: childItemType });
  };
  const handleEdit = (item) => {
    setIsEditModalOpen(true);
    setSelectedItem({ ...item, tableType: childItemType });
  };
  const handleSave = async () => {
    const children = await itemService.getChildString(
      childItemType,
      String(parentId)
    );
    if (Array.isArray(children) && children.length > 0) {
      updateChildItems(children);
    }
    setIsEditModalOpen(false);
    setSelectedItem(null);
    // Actual save logic would go here
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const renderChips = useCallback(
    (item) => {
      const chips = [];
      chips.push(
        <StyledChip
          key="version"
          label={
            preVersion
              ? `${preVersion}.${item.version}`
              : `v${item.version || "1"}`
          }
          size="small"
          colorstyle={getColorStyles("bg-gray-200 text-gray-800")}
        />
      );
      if (item.typeId != null) {
        chips.push(
          <StyledChip
            key="type"
            label={item.type?.typeName || "未知類型"}
            size="small"
            colorstyle={getColorStyles(
              childItemType === "Project"
                ? "bg-blue-200 text-blue-800"
                : childItemType === "Milestone"
                ? "bg-green-200 text-green-800"
                : childItemType === "Mission"
                ? "bg-orange-200 text-orange-800"
                : "bg-yellow-200 text-yellow-800"
            )}
          />
        );
      }

      if (item.accountId) {
        chips.push(
          <StyledChip
            key="account"
            label={item.assignee?.username || "未知用戶"}
            size="small"
            colorstyle={getColorStyles(
              currentUser.userId === item.accountId
                ? "bg-yellow-200 text-yellow-800"
                : "bg-gray-200 text-gray-800"
            )}
          />
        );
      }

      if (item.statusId) {
        chips.push(
          <StyledChip
            key="status"
            label={item.status?.statusName || "未知狀態"}
            size="small"
            colorstyle={getColorStyles(item.status?.color)}
          />
        );
      }

      if (item.priority) {
        const priorityInfo = priorityOrder.find((p) => p.id === item.priority);
        if (priorityInfo) {
          chips.push(
            <StyledChip
              key="priority"
              label={priorityInfo?.label || "未知優先級"}
              size="small"
              colorstyle={getColorStyles(priorityInfo?.color)}
            />
          );
        }
      }

      return chips;
    },
    [currentUser, preVersion, childItemType]
  );

  const renderDateTimeInfo = (item) => {
    let leftContent, rightContent;

    if (childItemType === "TaskAction") {
      leftContent = `開始時間: ${formatDateTime(item.startDate)}~${formatTime(
        item.endDate
      )}`;
      if (item.typeId === 76) {
        rightContent = `成本: ${formatNumber(item.cost)}`;
      } else {
        rightContent = `工時: ${formatNumber(item.cost)}小時`;
      }
    } else {
      leftContent = `期間: ${formatDate(item.startDate)}~${formatDate(
        item.endDate
      )}`;
      rightContent = `成本/預算: ${formatNumber(item.cost)}/${formatNumber(
        item.budget
      )}`;
    }

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{ mt: 1, flexWrap: "nowrap" }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            flexGrow: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {leftContent}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "right",
            minWidth: "fit-content",
          }}
        >
          {rightContent}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        mt: 4,
        bgcolor: "background.paper",
        p: 3,
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5" component="h3" color="text.primary">
          {getTableTypeName(childItemType)}列表
        </Typography>
        {!readOnly && (
          <button
            type="button"
            onClick={handleNew}
            className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </button>
        )}
      </Box>
      <List>
        {childItem.map((childItem) => (
          <StyledListItem key={childItem.id}>
            <ItemHeader>
              <ItemName variant="subtitle1">{childItem.name}</ItemName>
              <Box>{renderChips(childItem)}</Box>
              {!readOnly && (
                <ActionButtons>
                  <button
                    onClick={() => handleEdit(childItem)}
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => handleDeleteChildItem(childItem.id)}
                    className="text-red-600 hover:text-red-800 transition-colors duration-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </ActionButtons>
              )}
            </ItemHeader>
            <ItemDetails
              variant="body2"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {renderDateTimeInfo(childItem)}
            </ItemDetails>
          </StyledListItem>
        ))}
      </List>
      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent={childItemType}
          readOnly={false}
          tableType={childItemType}
          parentId={selectedItem.parentId}
          setErrorMessage={setError}
          onSave={handleSave}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default ChildItemsList;
