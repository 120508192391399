import React, { useState, useEffect, useCallback, useMemo } from "react";
import DropdownSelector from "./DropdownSelector";
import { getUser, getToken } from "../../../services/authService";
import itemService from "../../../services/db/ItemService";

const CompanySelector = ({ onCompanyChange, readOnly, height, label }) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const user = useMemo(() => getUser(), []);
  const token = useMemo(() => getToken(), []);

  const personalUserOption = useMemo(
    () => ({
      id: null,
      label: "個人專案",
      description: user.username || "",
    }),
    [user.username]
  );

  const fetchCompanies = useCallback(async () => {
    if (!isLoading) return;

    try {
      let fetchedCompanies = [];
      let companyData = [];
      if (user.membershipLevelId > 10) {
        companyData = await itemService.getCompany(token, user);
      } else {
        companyData = await itemService.getCompanyByAccount(user.userId);
      }
      fetchedCompanies = companyData.map((company) => ({
        id: company.companyID,
        label: company.shortName || "",
        description: company.companyName || "",
      }));
      setCompanies([personalUserOption, ...fetchedCompanies]);
    } catch (error) {
      console.error("獲取公司資料時發生錯誤:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user, token, personalUserOption, isLoading]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const allowedOptions = useMemo(() => companies, [companies]);

  if (isLoading) {
    return <div>正在載入公司資料...</div>;
  }

  return (
    <DropdownSelector
      onValueChange={onCompanyChange}
      readOnly={readOnly}
      allowedOptions={allowedOptions}
      allOptions={companies}
      placeholder="選擇公司或個人使用者"
      showDescription={true}
      inputHeight={height}
      label={label}
    />
  );
};

export default CompanySelector;
