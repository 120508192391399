import React, { useState, useEffect, useCallback } from "react";
import { getUser } from "../../services/authService";
import { formatElapsedTime } from "../../utils/dateUtils";
import itemService from "../../services/db/ItemService";
import ItemEditModal from "./ItemEditModal";
const STORAGE_KEY = "runningActionIds";
const CUSTOM_EVENT_NAME = "dataUpdated";

const ActionTimer = () => {
  const [runningActions, setRunningActions] = useState([]);
  const [, forceUpdate] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [, setError] = useState(null);

  const fetchRunningActions = useCallback(async () => {
    try {
      const user = await getUser();
      if (!user) {
        setRunningActions([]);
        return;
      }
      const actionsWithDetails = await itemService.getTaskActionString(
        "actionTimer",
        ""
      );
      setRunningActions(actionsWithDetails);
      const actionIds = actionsWithDetails.map((action) => action.id);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(actionIds));
    } catch (error) {
      console.error("Error fetching running actions:", error);
    }
  }, []);

  const checkAndUpdateActions = useCallback(() => {
    const storedActionIds = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || "[]"
    );
    const currentActionIds = runningActions.map((action) => action.id);
    if (JSON.stringify(storedActionIds) !== JSON.stringify(currentActionIds)) {
      fetchRunningActions();
    }
  }, [runningActions, fetchRunningActions]);

  useEffect(() => {
    const fetchInterval = setInterval(fetchRunningActions, 300000); // 每5分鐘更新一次資料
    const timeUpdateInterval = setInterval(() => {
      forceUpdate({});
      checkAndUpdateActions();
    }, 1000);

    const handleDataUpdated = () => {
      fetchRunningActions();
    };
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      clearInterval(fetchInterval);
      clearInterval(timeUpdateInterval);
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [fetchRunningActions, checkAndUpdateActions]);

  const handleActionClick = (actionId) => {
    const action = runningActions.find((a) => a.id === actionId);
    if (action) {
      setSelectedItem({ ...action, tableType: "TaskAction" });
      setIsEditModalOpen(true);
    }
  };

  const handleSaveEdit = async () => {
    setIsEditModalOpen(false);
    await fetchRunningActions();
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  if (runningActions.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4 relative">
      {runningActions.map((action) => (
        <div key={action.id} className="relative">
          <div
            onClick={() => handleActionClick(action.id)}
            className="flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white p-3 rounded-md shadow-md cursor-pointer hover:from-blue-600 hover:to-purple-700 transition duration-300"
          >
            <div className="font-mono font-bold text-lg mr-4 bg-white bg-opacity-20 rounded px-2 py-1">
              {formatElapsedTime(action.startDate)}
            </div>
            <div className="flex-grow">
              <div className="font-medium text-lg truncate">{action.name}</div>
              <div className="text-sm opacity-75 truncate">
                {`${action.projectName} > ${action.milestoneName} > ${action.taskName}`}
              </div>
            </div>
          </div>
        </div>
      ))}
      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent="TaskAction"
          readOnly={false}
          tableType="TaskAction"
          parentId={selectedItem.parentId}
          setErrorMessage={setError}
          onSave={handleSaveEdit}
          onClose={handleCancelEdit}
        />
      )}
    </div>
  );
};

export default ActionTimer;
