// src/services/db/IndexedDBConfig.js

export const DB_NAME = 'ProjectManagementDB';
export const DB_VERSION = 18; // 增加版本號

export const dbSchema = {
  stores: {
    syncTable: {
      keyPath: 'id',
      autoIncrement: true,
      indexes: [
        { name: 'tableType', unique: false },
        { name: 'itemId', unique: false },
        { name: 'operation', unique: false },
        { name: 'timestamp', unique: false },
        { name: 'status', unique: false },
        { name: 'data', unique: false }
      ]
    },
    Project: {
      keyPath: 'id',
      indexes: [
        { name: 'parentId', unique: false },
        { name: 'name', unique: false },
        { name: 'assigneeId', unique: false },
        { name: 'accountId', unique: false },
        { name: 'budget', unique: false },
        { name: 'cost', unique: false },
        { name: 'status', unique: false },
        { name: 'statusId', unique: false },
        { name: 'priority', unique: false },
        { name: 'gcsfolderUrl', unique: false },
        { name: 'startDate', unique: false },
        { name: 'endDate', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false },
        { name: 'gcsdescriptionUrl', unique: false },
        { name: 'type', unique: false },
        { name: 'typeId', unique: false },
      ]
    },
    Milestone: {
      keyPath: 'id',
      indexes: [
        { name: 'parentId', unique: false },
        { name: 'name', unique: false },
        { name: 'assigneeId', unique: false },
        { name: 'accountId', unique: false },
        { name: 'budget', unique: false },
        { name: 'cost', unique: false },
        { name: 'status', unique: false },
        { name: 'statusId', unique: false },
        { name: 'priority', unique: false },
        { name: 'gcsfolderUrl', unique: false },
        { name: 'version', unique: false },
        { name: 'startDate', unique: false },
        { name: 'endDate', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false },
        { name: 'gcsdescriptionUrl', unique: false },
        { name: 'type', unique: false },
        { name: 'typeId', unique: false }
      ]
    },
    Mission: {
      keyPath: 'id',
      indexes: [
        { name: 'parentId', unique: false },
        { name: 'name', unique: false },
        { name: 'gcsdescriptionUrl', unique: false },
        { name: 'assigneeId', unique: false },
        { name: 'accountId', unique: false },
        { name: 'type', unique: false },
        { name: 'typeId', unique: false },
        { name: 'budget', unique: false },
        { name: 'cost', unique: false },
        { name: 'status', unique: false },
        { name: 'statusId', unique: false },
        { name: 'priority', unique: false },
        { name: 'gcsfolderUrl', unique: false },
        { name: 'version', unique: false },
        { name: 'startDate', unique: false },
        { name: 'endDate', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    TeamMember: {
      keyPath: 'id',
      indexes: [
        { name: 'milestoneId', unique: false },
        { name: 'accountId', unique: false },
        { name: 'roleType', unique: false },
        { name: 'hourlyRate', unique: false },
        { name: 'joinDate', unique: false },
        { name: 'leaveDate', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    TaskAction: {
      keyPath: 'id',
      indexes: [
        { name: 'parentId', unique: false },
        { name: 'name', unique: false },
        { name: 'assigneeId', unique: false },
        { name: 'accountId', unique: false },
        { name: 'type', unique: false },
        { name: 'typeId', unique: false },
        { name: 'gcsdescriptionUrl', unique: false },
        { name: 'startDate', unique: false },
        { name: 'endDate', unique: false },
        { name: 'cost', unique: false },
        { name: 'version', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    User: {
      keyPath: 'accountId',
      indexes: [
        { name: 'username', unique: false},
        { name: 'email', unique: false },
        { name: 'pictureUrl', unique: false }
      ]
    },
    userProfiles: {
      keyPath: 'userId', // Primary key
      autoIncrement: true,
      indexes: [
        { name: 'username', unique: false },
        { name: 'email', unique: false },
        { name: 'givenName', unique: false },
        { name: 'familyName', unique: false },
        { name: 'pictureUrl', unique: false },
        { name: 'membershipLevelId', unique: false },
        { name: 'referredByUserId', unique: false },
        { name: 'advancedSettings', unique: false },
        { name: 'walletBalance', unique: false },
        { name: 'approvalProcessesCount', unique: false },
        { name: 'commentsCount', unique: false },
        { name: 'friendshipCount', unique: false },
        { name: 'notificationsCount', unique: false },
        { name: 'portfoliosCount', unique: false },
        { name: 'taskRatingsCount', unique: false },
        { name: 'teamMembersCount', unique: false },
        { name: 'userCertificationsCount', unique: false },
        { name: 'userCompanyRelationsCount', unique: false }
      ]
    },
    StatusOption: {
      keyPath: 'statusId',
      indexes: [
        { name: 'statusName', unique: false },
        { name: 'appliesTo', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false },
        { name: 'color', unique: false }
      ]
    },
    MissionRating: {
      keyPath: 'ratingId',
      indexes: [
        { name: 'missionId', unique: false },
        { name: 'RatedBy', unique: false },
        { name: 'comment', unique: false },
        { name: 'score', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    Tag: {
      keyPath: 'tagId',
      indexes: [
        { name: 'tagName', unique: false },
        { name: 'tagType', unique: false },
        { name: 'createdAt', unique: false }
      ]
    },
    TagRelation: {
      keyPath: 'relationId',
      indexes: [
        { name: 'tagId', unique: false },
        { name: 'relatedTo', unique: false },
        { name: 'relatedId', unique: false },
        { name: 'createdAt', unique: false }
      ]
    },
    Type: {
      keyPath: 'typeId',
      indexes: [
        { name: 'typeName', unique: false },
        { name: 'typeCategory', unique: false },
        { name: 'description', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    TypeCorrelation: {
      keyPath: 'idTypesToTypesCorrelation',
      indexes: [
        { name: 'parentId', unique: false },
        { name: 'idType', unique: false }
      ]
    },
    Comment: {
      keyPath: 'commentId',
      indexes: [
        { name: 'accountId', unique: false },
        { name: 'relatedTo', unique: false },
        { name: 'relatedId', unique: false },
        { name: 'content', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false }
      ]
    },
    AccessList: {
      keyPath: ['itemLevel', 'itemId'],
      indexes: [
        { name: 'permission', unique: false }
      ]
    },
    UserCompanyRelation: {
      keyPath: 'relationId',
      indexes: [
        { name: 'accountId', unique: false },
        { name: 'companyId', unique: false },
        { name: 'roleType', unique: false },
        { name: 'name', unique: false },
        { name: 'companyEmail', unique: false },
        { name: 'contactNumber', unique: false },
        { name: 'department', unique: false },
        { name: 'createdAt', unique: false },
        { name: 'updatedAt', unique: false },
        { name: 'isDeleted', unique: false },
        { name: 'quitDate', unique: false }
      ]
    },
  }
};
