// MilestoneEdit.js
import React, { useState, useEffect } from "react";
import ItemEdit from "../common/ItemEdit";
import MissionEdit from "./MissionEdit";
import { getCurrentLocalDate } from "../../utils/dateUtils";
import { accessCheck,getMaxAccess } from "../common/Access";
import itemService from "../../services/db/ItemService";
const MilestoneEdit = ({ item, onSave, onCancel, isNew, editMode }) => {
  const [preVersion, setPreVersion] = useState("v");
  const [lastVersion, setLastVersion] = useState(0);
  const [access, setAccess] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [editingItem, setEditingItem] = useState(null);
  useEffect(() => {
    const fetchVersions = async () => {
      if (item.parentId) {
        try {
          const result = await itemService.getVersion("Milestone", item.parentId);
          if (Array.isArray(result) && result.length > 0) {
            const version = result[0];
            if (version.maxVersion) setLastVersion(version.maxVersion);           
          }
        
          if (isNew) {
            setAccess(getMaxAccess());
            setReadOnly(false);
          } else {
            const accessQ = await accessCheck("Milestone", item.id);
            if (accessQ) {
              setAccess(accessQ);
              setReadOnly(accessQ.readOnly);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    const checkItemInfo = async () => {
      let tempItem = { ...item, tableType: "Milestone" };
      if (!item.id) {
        tempItem.name = "新里程碑";
        tempItem.version = lastVersion;
        tempItem.startDate = getCurrentLocalDate();
      }
      setEditingItem(tempItem);
    };
    checkItemInfo();
    fetchVersions();
  }, [item, isNew, lastVersion]);

  const handleSave = (updatedItem) => {
    if (access.write) {
      onSave(updatedItem);
    } else {
      console.warn("權限不足");
    }
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <ItemEdit
      item={editingItem}
      onSave={handleSave}
      onCancel={onCancel}
      isNew={isNew}
      editMode={editMode}
      readOnly={readOnly}
      itemType={item.tableType}
      preVersion={preVersion}
      lastVersion={lastVersion}
      childItemType="Mission"
      ChildItemEdit={MissionEdit}
      access={access}
    />
  );
};

export default MilestoneEdit;

/*
    public enum PermissionType
    {
        DeleteItem = 5,
        DeleteSubItems = 4,
        AddSubItems = 3,
        WriteItem = 2,
        ReadSubItems = 1,
        ReadItem = 0
    }
*/
