/**
 * @typedef {Object} UserQueryDto
 * @property {string} username
 * @property {string} email
 * @property {string|null} [givenName]
 * @property {string|null} [familyName]
 * @property {string} [pictureUrl]
 * @property {number} membershipLevelId
 * @property {number|null} [referredByUserId]
 * @property {string|null} [advancedSettings]
 * @property {number|null} walletBalance
 * @property {number|null} approvalProcessesCount
 * @property {number|null} commentsCount
 * @property {number|null} friendshipCount
 * @property {number|null} notificationsCount
 * @property {number} portfoliosCount
 * @property {number} taskRatingsCount
 * @property {number} teamMembersCount
 * @property {number} userCertificationsCount
 * @property {number|null} userCompanyRelationsCount
 */

/**
 * 創建一個 UserQueryDto 物件
 * @param {Object} data - 原始數據
 * @returns {UserQueryDto}
 */
export function createUserQueryDto(data) {
    return {
        username: data.username,
        email: data.email,
        givenName: data.givenName,
        familyName: data.familyName,
        pictureUrl: data.pictureUrl,
        membershipLevelId: data.membershipLevelId,
        referredByUserId: data.referredByUserId,
        advancedSettings: data.advancedSettings,
        walletCount: data.walletCount,
        approvalProcessesCount: data.approvalProcessesCount,
        commentsCount: data.commentsCount,
        friendshipCount: data.friendshipCount,
        notificationsCount: data.notificationsCount,
        portfoliosCount: data.portfoliosCount,
        taskRatingsCount: data.taskRatingsCount,
        teamMembersCount: data.teamMembersCount,
        userCertificationsCount: data.userCertificationsCount,
        userCompanyRelationsCount: data.userCompanyRelationsCount
    };
}

/**
 * 解析 userData 並返回 UserQueryDto
 * @param {Object} userData - 用戶數據
 * @returns {UserQueryDto}
 */
export function getUserDTO(userData) {
    return {
        username: userData.username,
        email: userData.email,
        givenName: userData.givenName,
        familyName: userData.familyName,
        pictureUrl: userData.pictureUrl,
        membershipLevelId: userData.membershipLevelId,
        referredByUserId: userData.referredByUserId,
        advancedSettings: userData.advancedSettings,
        walletCount: userData.walletCount,
        approvalProcessesCount: userData.approvalProcessesCount,
        commentsCount: userData.commentsCount,
        friendshipCount: userData.friendshipCount,
        notificationsCount: userData.notificationsCount,
        portfoliosCount: userData.portfoliosCount,
        taskRatingsCount: userData.taskRatingsCount,
        teamMembersCount: userData.teamMembersCount,
        userCertificationsCount: userData.userCertificationsCount,
        userCompanyRelationsCount: userData.userCompanyRelationsCount
    };
}