// HistoryView.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import HistoryDataService from "./HistoryDataService";
import HistoryItem from "./HistoryItem";
import { getChildTableType } from "../../utils/utils";
import { Box, Typography, CircularProgress } from "@mui/material";

const HistoriesView = ({ item, type, readOnly, preVersion, access }) => {
  const [childItems, setChildItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const containerRef = useRef(null);



  const fetchHistory = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (item.length > 0) {
        const fetchedData = await HistoryDataService.fetchHistoriesItems(
          item,
          type
        );
        setChildItems(fetchedData);
      } else {
        setChildItems([]);
      }
    } catch (err) {
      console.error(`Error loading history for ${type} ${item.id}:`, err);
      setError(`載入歷史記錄時發生錯誤: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [item,type]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  const handleSave = useCallback(
    async (updatedItem) => {
      try {
        await fetchHistory();
      } catch (err) {
        console.error("保存失敗:", err);
        setError("保存時發生錯誤");
      }
    },
    [fetchHistory]
  );

  const handleUpdateChildItems = useCallback(async () => {
    try {
      await fetchHistory();
    } catch (error) {
      console.error("更新子項目時發生錯誤:", error);
      setError("更新子項目時發生錯誤");
    }
  }, [fetchHistory]);

  const handleRefresh = useCallback(
    (refreshedItemRef, isExpanded) => {
      try {
        fetchHistory().then(() => {
          if (refreshedItemRef && refreshedItemRef.current) {
            refreshedItemRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        });
      } catch (error) {
        console.error("刷新時發生錯誤:", error);
        setError("刷新時發生錯誤");
      }
    },
    [fetchHistory]
  );

  const handleToggleExpand = useCallback((itemId, isExpanded) => {
    try {
      setExpandedItems((prev) => ({ ...prev, [itemId]: isExpanded }));
    } catch (error) {
      console.error("展開/收起項目時發生錯誤:", error);
      setError("展開/收起項目時發生錯誤");
    }
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box maxWidth="2xl" mx="auto" ref={containerRef}>
      {childItems.map((childItem) => (
        <HistoryItem
          key={childItem.id}
          item={{ ...childItem, preVersion: preVersion }}
          onSave={handleSave}
          childItemType={getChildTableType(type)}
          readOnly={false}
          setError={setError}
          containerRef={containerRef}
          access={access}
          onUpdateChildItems={handleUpdateChildItems}
          isParentItem={false}
          canEdit={access.write}
          canAdd={access.createChild}
          onRefresh={handleRefresh}
          isExpanded={expandedItems[childItem.id]}
          onToggleExpand={handleToggleExpand}
        />
      ))}
    </Box>
  );
};

export default HistoriesView;
