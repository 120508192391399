import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Box,
  Chip,
  TextField,
  Autocomplete,
  Tooltip,
  Collapse,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import itemService from "../../services/db/ItemService";
import { formatDate } from "../../utils/dateUtils";
import { getColorStyles, getCostColor,formatNumber } from "../../utils/utils";
import History from "../History/HistoriesView";
const StyledCard = styled(Card)(({ theme, selected }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  transition: "all 0.3s",
  border: selected ? `2px solid ${theme.palette.primary.main}` : "none",
  boxShadow: selected
    ? `0 0 10px ${theme.palette.primary.main}`
    : theme.shadows[1],
}));

const FilterContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: "10px",
  marginBottom: "20px",
});

const StyledChip = styled(Chip)(({ colorstyle }) => ({
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));
const ProjectTag = ({ label, color, onDelete, deleteIcon }) => (
  <Chip
    label={label}
    onDelete={onDelete}
    deleteIcon={deleteIcon}
    sx={{
      borderRadius: "12px",
      height: "24px",
      margin: "0 4px 4px 0",
      backgroundColor: color.backgroundColor,
      color: color.color,
      "& .MuiChip-label": {
        padding: "0 4px 0 8px", // 減少右側內邊距
        fontWeight: "normal",
        fontSize: "0.75rem",
      },
      "& .MuiChip-deleteIcon": {
        color: color.color,
        marginRight: "2px", // 增加右側邊距
        marginLeft: "-4px", // 減少與文字的間距
        fontSize: "16px", // 調整圖標大小
        width: "16px", // 確保寬度一致
        height: "16px", // 確保高度一致
      },
    }}
  />
);
const ProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    statuses: [],
  });
  const [isProjectListExpanded, setIsProjectListExpanded] = useState(true);
  useEffect(() => {
    loadProjects();
    loadStatusOptions();
  }, []);

  const loadProjects = async () => {
    try {
      const projectsData = await itemService.fetch("Project");
      projectsData.map((project) => ({
        id: project.id,
        name: project.name,
        statusId: project.statusId,
      }));
      setProjects(projectsData);
    } catch (error) {
      console.error("加載專案失敗:", error);
    }
  };

  const loadStatusOptions = async () => {
    try {
      const statusOptions = await itemService.getStatus("appliesTo", "Project");

      setStatusOptions(statusOptions);
      setSelectedStatuses(statusOptions.map((option) => option.statusId));
      const types = await itemService.getTypes("typeCategory", "Project");

      setProjectTypes(types);
    } catch (error) {
      console.error("加載狀態選項失敗:", error);
    }
  };

  const statusMap = useMemo(() => {
    return statusOptions.reduce((acc, status) => {
      acc[status.statusId] = status;
      return acc;
    }, {});
  }, [statusOptions]);

  const typesMap = useMemo(() => {
    return projectTypes.reduce((acc, type) => {
      acc[type.typeId] = type;
      return acc;
    }, {});
  }, [projectTypes]);

  const handleProjectSelect = (id) => {
    setSelectedProjectIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((projectId) => projectId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleClearSelection = (id) => {
    setSelectedProjectIds((prevSelected) =>
      prevSelected.filter((projectId) => projectId !== id)
    );
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const filteredProjects = projects.filter((project) => {
    const nameMatch = project.name
      .toLowerCase()
      .includes(filters.name.toLowerCase());
    const statusMatch =
      selectedStatuses.length === 0 ||
      selectedStatuses.includes(project.statusId);

    return nameMatch && statusMatch;
  });

  const renderProjectCard = (project) => {
    const statusInfo = statusMap[project.statusId];
    const typesInfo = typesMap[project.typeId];

    return (
      <Box
        key={project.id}
        width={{
          xs: "100%",
          sm: "calc(50% - 8px)",
          md: "calc(24% - 10.67px)",
        }}
      >
        <StyledCard
          selected={selectedProjectIds.includes(project.id)}
          onClick={() => handleProjectSelect(project.id)}
        >
          <CardContent>
            <Tooltip title={project.name} arrow>
              <Typography
                variant="h6"
                component="h3"
                noWrap
                style={{ width: "100%" }}
              >
                {project.name}
              </Typography>
            </Tooltip>
            <Typography variant="body2" color="text.secondary">
              {formatDate(project.startDate)} ~ {formatDate(project.endDate)}
            </Typography>
            <Typography
              variant="body2"
              color={getCostColor(project.cost, project.budget)}
            >
              花費/預算: {formatNumber(project.cost)}/{formatNumber(project.budget)}
            </Typography>
            {statusInfo && (
              <StyledChip
                label={`${statusInfo.statusName}`}
                size="small"
                colorstyle={getColorStyles(statusInfo.color)}
              />
            )}
            {typesInfo && (
              <StyledChip
                label={`${typesInfo.typeName}`}
                size="small"
                colorstyle={getColorStyles("bg-blue-200 text-gray-800")}
              />
            )}
          </CardContent>
        </StyledCard>
      </Box>
    );
  };

  const selectedProjects = filteredProjects.filter((project) =>
    selectedProjectIds.includes(project.id)
  );

  const toggleProjectList = () => {
    setIsProjectListExpanded(!isProjectListExpanded);
  };

  const renderProjectTags = () => {
    return selectedProjects.map((project) => {
      const statusInfo = statusMap[project.statusId];
      return (
        <ProjectTag
          key={project.id}
          label={project.name}
          color={getColorStyles(statusInfo?.color)}
          onDelete={() => handleClearSelection(project.id)}
          deleteIcon={<CloseIcon style={{ fontSize: "16px" }} />}
        />
      );
    });
  };

  return (
    <Container maxWidth="lg">
      <FilterContainer>
        <TextField
          label="名稱篩選"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
          variant="outlined"
          size="small"
          style={{ flexGrow: 1 }}
        />
        <Autocomplete
          multiple
          options={statusOptions}
          getOptionLabel={(option) => {
            return `${option?.statusName}`;
          }}
          value={selectedStatuses.map((statusId) => {
            const option = statusOptions.find(
              (option) => option?.statusId === statusId
            );

            return option;
          })}
          onChange={(_, newValue) => {
            const newSelectedStatuses = newValue.map(
              (option) => option?.statusId
            );

            setSelectedStatuses(newSelectedStatuses);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="狀態"
              variant="outlined"
              size="small"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledChip
                label={`${option.statusName}`}
                size="small"
                colorstyle={getColorStyles(option.color)}
                style={{ margin: "2px" }}
              />
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <StyledChip
                key={option?.statusId}
                variant="outlined"
                label={`${option?.statusName}`}
                size="small"
                colorstyle={getColorStyles(option.color)}
                {...getTagProps({ index })}
              />
            ))
          }
          style={{ minWidth: 200 }}
        />
      </FilterContainer>
      <Box mb={4}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h5" component="h2">
            專案列表
          </Typography>
          <IconButton onClick={toggleProjectList} size="small">
            {isProjectListExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={isProjectListExpanded}>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {filteredProjects.map(renderProjectCard)}
          </Box>
        </Collapse>
      </Box>

      <Box mb={4}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h5" component="h2" mr={2}>
            已選擇的專案 ({selectedProjects.length})
          </Typography>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {renderProjectTags()}
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1">歷史紀錄</Typography>
        <History item={selectedProjects} type={"Project"} readOnly={false} />
      </Box>
    </Container>
  );
};

export default ProjectManagement;
