import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Chip,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Legend,
} from "recharts";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import itemService from "../../services/db/ItemService";
import { getUser } from "../../services/authService";
import { formatDateTime, formatTime } from "../../utils/dateUtils";
import CustomDateTimePicker from "../common/DatetimePicker";
import SortIcon from "@mui/icons-material/Sort";
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8",
  "#82ca9d",
];
const formatNumber = (num) => {
  return num != null ? num.toLocaleString() : "-";
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  position: "relative",
  "&:hover .resizer": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Resizer = styled("div")({
  position: "absolute",
  right: 0,
  top: 0,
  height: "100%",
  width: "5px",
  background: "rgba(0, 0, 0, 0.1)",
  cursor: "col-resize",
  userSelect: "none",
  touchAction: "none",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
});
const CUSTOM_EVENT_NAME = "dataUpdated";
const TaskActionTimeList = () => {
  const [taskActions, setTaskActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today.toISOString();
  });
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [appliedStartDate, setAppliedStartDate] = useState(selectedStartDate);
  const [appliedEndDate, setAppliedEndDate] = useState(null);

  const [groupBy, setGroupBy] = useState([]);
  const [groupedDataSortOrder, setGroupedDataSortOrder] =
    useState("descending");
  const [access] = useState(getUser().membershipLevelId > 10);
  const [filters, setFilters] = useState({
    name: "",
    companyName: [],
    projectName: [],
    milestoneName: [],
    missionName: [],
    typeName: [],
    assigneeName: [],
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    companyNames: [],
    projectNames: [],
    milestoneNames: [],
    missionNames: [],
    typeNames: [],
    assigneeNames: [],
  });

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const newdaterange = `${appliedStartDate}~${appliedEndDate}`;
      const actions = await itemService.getTaskActionByTimeList(newdaterange);
      setTaskActions(actions);
      // 從返回的數據中提取下拉選項
      const uniqueOptions = actions.reduce(
        (acc, action) => {
          acc.companyNames.add(action.companyName || "");
          acc.projectNames.add(action.projectName || "");
          acc.milestoneNames.add(action.milestoneName || "");
          acc.missionNames.add(action.missionName || ""); // 假設任務名稱現在直接存儲在 name 字段
          acc.typeNames.add(action.typeName || ""); // 假設類型名稱現在直接存儲在 type 字段
          acc.assigneeNames.add(action.assigneeName || ""); // 假設分配者名稱現在直接存儲在 assigneeName 字段
          return acc;
        },
        {
          companyNames: new Set(),
          projectNames: new Set(),
          milestoneNames: new Set(),
          missionNames: new Set(),
          typeNames: new Set(),
          assigneeNames: new Set(),
        }
      );

      setDropdownOptions({
        companyNames: Array.from(uniqueOptions.companyNames).sort(),
        projectNames: Array.from(uniqueOptions.projectNames).sort(),
        milestoneNames: Array.from(uniqueOptions.milestoneNames).sort(),
        missionNames: Array.from(uniqueOptions.missionNames).sort(), // 使用 missionNames
        typeNames: Array.from(uniqueOptions.typeNames).sort(),
        assigneeNames: Array.from(uniqueOptions.assigneeNames).sort(), // 使用 assigneeNames
      });

      setGroupBy([]);
    } catch (err) {
      console.error("加載數據時出錯:", err);
      setError("加載數據失敗。請嘗試刷新頁面。");
    } finally {
      setLoading(false);
    }
  }, [appliedStartDate, appliedEndDate]);

  useEffect(() => {
    loadData();
    const handleDataUpdated = () => {
      loadData();
    };
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [loadData]);

  const handleRefresh = () => {
    setAppliedStartDate(selectedStartDate);
    setAppliedEndDate(selectedEndDate);
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: Array.isArray(value) ? value : [value],
    }));
  }, []);

  const filteredActions = useMemo(() => {
    const filtered = taskActions.filter((action) => {
      if (!action || !action.startDate) {
        return false;
      }
      const nameMatch = action.name
        .toLowerCase()
        .includes(filters.name.toLowerCase());
      const companyNameMatch =
        filters.companyName.length === 0 ||
        filters.companyName.includes(action.companyName);
      const projectNameMatch =
        filters.projectName.length === 0 ||
        filters.projectName.includes(action.projectName);
      const milestoneNameMatch =
        filters.milestoneName.length === 0 ||
        filters.milestoneName.includes(action.milestoneName);
      const missionNameMatch =
        filters.missionName.length === 0 ||
        filters.missionName.includes(action.missionName);
      const typeNameMatch =
        filters.typeName.length === 0 ||
        filters.typeName.includes(action.typeName);
      const assigneeNameMatch =
        filters.assigneeName.length === 0 ||
        filters.assigneeName.includes(action.assigneeName);

      return (
        nameMatch &&
        companyNameMatch &&
        projectNameMatch &&
        milestoneNameMatch &&
        missionNameMatch &&
        typeNameMatch &&
        assigneeNameMatch
      );
    });
    return filtered;
  }, [taskActions, filters]);

  const totalTime = useMemo(() => {
    return filteredActions.reduce(
      (total, action) => total + (action.cost || 0),
      0
    );
  }, [filteredActions]);

  const groupedData = useMemo(() => {
    const grouped = filteredActions.reduce((acc, action) => {
      let key = groupBy
        .map((group) => {
          // 檢查 action[group] 是否存在，如果不存在則使用 "未分類"
          return action[group] != null ? action[group] : "未分類";
        })
        .join(" - ");
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += action.cost || 0;
      return acc;
    }, {});
    return Object.entries(grouped)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) =>
        groupedDataSortOrder === "descending"
          ? b.value - a.value
          : a.value - b.value
      );
  }, [filteredActions, groupBy, groupedDataSortOrder]);

  const optimizedChartData = useMemo(() => {
    const topItems = groupedData.slice(0, 5);
    const otherValue = groupedData
      .slice(5)
      .reduce((sum, item) => sum + item.value, 0);
    if (otherValue > 0) {
      topItems.push({ name: "其他", value: otherValue });
    }
    return topItems;
  }, [groupedData]);

  const toggleGroupedDataSortOrder = useCallback(() => {
    setGroupedDataSortOrder((prev) =>
      prev === "ascending" ? "descending" : "ascending"
    );
  }, []);

  const toggleGroupBy = useCallback((option) => {
    setGroupBy((prevGroupBy) => {
      if (prevGroupBy.includes(option)) {
        return prevGroupBy.filter((item) => item !== option);
      } else {
        return [...prevGroupBy, option];
      }
    });
  }, []);

  const groupByOptions = useMemo(() => {
    const options = [
      { value: "projectName", label: "專案" },
      { value: "milestoneName", label: "里程碑" },
      { value: "missionName", label: "任務" }, // 更新為 missionName
      { value: "typeName", label: "類型" }, // 更新為 type
      { value: "assigneeName", label: "負責人" }, // 更新為 assigneeName
    ];
    if (access) {
      options.unshift({ value: "companyName", label: "公司" });
    }
    return options;
  }, [access]);

  const exportToCSV = useCallback((data, filename) => {
    const headers = [
      "行動名稱",
      "公司",
      "專案",
      "里程碑",
      "任務",
      "類型",
      "負責人",
      "開始日期",
      "結束日期",
      "花費（小時）",
    ];
    const csvContent = [
      headers.join(","),
      ...data.map((action) =>
        [
          `"${action.name}"`,
          `"${action.companyName || ""}"`,
          `"${action.projectName || ""}"`,
          `"${action.milestoneName || ""}"`,
          `"${action.missionName || ""}"`,
          `"${action.typeName || ""}"`,
          `"${action.assigneeName || ""}"`,
          `"${formatDateTime(action.startDate)}"`,
          `"${formatDateTime(action.endDate)}"`,
          action.cost.toFixed(1),
        ].join(",")
      ),
    ].join("\n");

    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, []);

  const copyToClipboard = useCallback(
    (data, isGroupData = false) => {
      let content;
      if (isGroupData) {
        const headers = groupBy.map(
          (g) => groupByOptions.find((o) => o.value === g)?.label || g
        );
        headers.push("時間 (小時)");
        content = [
          headers.join("\t"),
          ...data.map((item) => {
            const nameParts = item.name.split(" - ");
            return [...nameParts, item.value.toFixed(1)].join("\t");
          }),
        ].join("\n");
      } else {
        const headers = [
          "開始日期",
          "結束日期",
          "工時",
          "專案/里程碑",
          "類別",
          "動作",    
        ];
        content = [
          headers.join("\t"),
          ...data.map((action) =>
            [
              formatDateTime(action.startDate),
              formatDateTime(action.endDate),
              action.cost.toFixed(1),
              `${action.projectName}/${action.milestoneName}`,
              action.typeName,
              `[${action.missionName} ${action.version}]${action.name}`  
            ].join("\t")
          ),
        ].join("\n");
      }

      navigator.clipboard.writeText(content).then(
        () => {
          alert("已複製到剪貼簿");
        },
        (err) => {
          console.error("無法複製到剪貼簿: ", err);
        }
      );
    },
    [groupBy, groupByOptions]
  );

  const columns = useMemo(
    () => [
      ...(access
        ? [
            {
              Header: "公司",
              accessor: "companyName",
            },
          ]
        : []),
      {
        Header: "日期",
        accessor: "startDate",
        Cell: ({ row }) => (
          <span>
            {formatDateTime(row.original.startDate)} ~{" "}
            {row.original.endDate ? formatTime(row.original.endDate) : "進行中"}
          </span>
        ),
      },
      {
        Header: "花費（小時）",
        accessor: "cost",
        Cell: ({ value }) => value.toFixed(1),
      },
      {
        Header: "專案",
        accessor: "projectName",
      },
      {
        Header: "里程碑",
        accessor: "milestoneName",
      },
      {
        Header: "任務",
        accessor: "missionName", // 修改為 missionName
      },
      {
        Header: "類型",
        accessor: "typeName", // 修改為 type
      },
      {
        Header: "版本",
        accessor: "version", 
      },
      {
        Header: "行動名稱",
        accessor: "name",
      },
      {
        Header: "負責人",
        accessor: "assigneeName", // 修改為 assigneeName
      },
    ],
    [access]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: filteredActions,
        initialState: { sortBy: [] }, // 初始化時不進行排序
      },
      useBlockLayout,
      useResizeColumns,
      useSortBy
    );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        任務行動時間列表
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDateTimePicker
                label="開始日期"
                value={selectedStartDate}
                readOnly={false}
                height="40px"
                includeTime={false}
                onChange={setSelectedStartDate}
                inputProps={{ autoComplete: "off" }}
              />
              <CustomDateTimePicker
                label="結束日期"
                value={selectedEndDate}
                readOnly={false}
                height="40px"
                includeTime={false}
                onChange={setSelectedEndDate}
                inputProps={{ autoComplete: "off" }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRefresh}
            >
              刷新
            </Button>
          </Box>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {groupByOptions.map((option) => (
              <Button
                key={option.value}
                variant={
                  groupBy.includes(option.value) ? "contained" : "outlined"
                }
                onClick={() => toggleGroupBy(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3, mb: 3 }}>
        <Box sx={{ flex: "1 1 300px", minWidth: 0 }}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              總時間: {formatNumber(totalTime.toFixed(1))} 小時
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={optimizedChartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) =>
                      `${name} ${(percent * 100).toFixed(0)}%`
                    }
                  >
                    {optimizedChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <RechartsTooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Box>
        <Box sx={{ flex: "1 1 300px", minWidth: 0 }}>
          <Paper
            sx={{ p: 2, height: "100%", maxHeight: 400, overflow: "auto" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">
                按{" "}
                {groupBy
                  .map((g) => groupByOptions.find((o) => o.value === g)?.label)
                  .join(" - ") || "無"}{" "}
                分組的時間統計
              </Typography>
              <Box>
                <Tooltip title="複製分組數據">
                  <IconButton
                    onClick={() => copyToClipboard(groupedData, true)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <Button onClick={toggleGroupedDataSortOrder} size="small">
                  {groupedDataSortOrder === "descending"
                    ? "升序排列"
                    : "降序排列"}
                </Button>
              </Box>
            </Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {groupBy.map((g, index) => (
                    <TableCell key={index}>
                      {groupByOptions.find((o) => o.value === g)?.label || g}
                    </TableCell>
                  ))}
                  <TableCell align="right">時間 (小時)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedData.map((item, index) => {
                  const nameParts = item.name.split(" - ");
                  return (
                    <TableRow key={index}>
                      {nameParts.map((part, i) => (
                        <TableCell key={i}>{part}</TableCell>
                      ))}
                      <TableCell align="right">
                        {formatNumber(item.value.toFixed(1))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Box>

      <Paper sx={{ mb: 3, p: 2 }}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Tooltip title="複製表格數據">
            <IconButton
              onClick={() => copyToClipboard(filteredActions)}
              sx={{ mr: 1 }}
            >
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="匯出 CSV">
            <IconButton
              onClick={() => exportToCSV(filteredActions, "task_actions.csv")}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <StyledTableCell {...column.getHeaderProps()}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {column.render("Header")}
                        <IconButton
                          {...column.getSortByToggleProps()}
                          size="small"
                          style={{ marginLeft: "8px" }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownwardIcon fontSize="small" />
                            ) : (
                              <ArrowUpwardIcon fontSize="small" />
                            )
                          ) : (
                            <SortIcon fontSize="small" />
                          )}
                        </IconButton>
                      </div>
                      {column.id === "name" ? (
                        <TextField
                          value={filters.name || ""}
                          onChange={(e) =>
                            handleFilterChange("name", e.target.value)
                          }
                          placeholder={`篩選 ${column.render("Header")}`}
                          size="small"
                          fullWidth
                        />
                      ) : [
                          "companyName",
                          "projectName",
                          "milestoneName",
                          "missionName",
                          "typeName",
                          "assigneeName",
                        ].includes(column.id) ? (
                        <Autocomplete
                          multiple
                          options={dropdownOptions[`${column.id}s`]}
                          value={filters[column.id] || []}
                          onChange={(_, newValue) =>
                            handleFilterChange(column.id, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`篩選 ${column.render("Header")}`}
                              size="small"
                            />
                          )}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                                size="small"
                              />
                            ))
                          }
                          size="small"
                          fullWidth
                        />
                      ) : null}
                      {column.canResize && (
                        <Resizer
                          {...column.getResizerProps()}
                          className="resizer"
                        />
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    沒有資料
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      sx={{
                        backgroundColor:
                          row.original.endDate == null
                            ? "rgba(255, 0, 0, 0.1)"
                            : "inherit",
                      }}
                    >
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default TaskActionTimeList;
