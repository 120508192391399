import React, { useState, useEffect } from "react";
import QuillEditor from "../common/QuillEditor";
import gcsService from "../../services/db/GcsService";
import itemService from "../../services/db/ItemService";
import { convertToDTO } from "../../types/projectsTypes";
const MessageEdit = ({ item, onSave, onCancel, onDelete }) => {
  const [message, setMessage] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (item && item.commentId) {
      setIsNew(false);
      setMessage(item.message || "");
    } else {
      setIsNew(true);
      setMessage("");
    }
  }, [item]);

  const handleSave = async () => {
    try {
      setError("");
      const updatedItem = { ...item };

      if (message != null) {
        let url = await gcsService.uploadContent(message, updatedItem.content);
        updatedItem.content = url.objectName;
      }
      const response = updatedItem.commentId
        ? await itemService.updateItem(updatedItem.tableType, updatedItem, "")
        : await itemService.createItem(item.tableType, updatedItem);

      if (!response.success) {
        throw new Error(`上傳失敗: ${response.error}`);
      }
      const dto = convertToDTO({
        tableType: "Comment",
        data: response.data,
      });
      onSave({ ...dto, message });
    } catch (error) {
      console.error("Error saving message:", error);
      setError("儲存評論時發生錯誤。請稍後再試。");
    }
  };

  const handleDelete = async () => {
    if (window.confirm("確定要刪除此評論嗎？")) {
      try {
        await itemService.deleteItem("Comment", item.commentId);
        onDelete(item.commentId);
      } catch (error) {
        console.error("Error deleting message:", error);
        setError("刪除評論時發生錯誤。請稍後再試。");
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "24px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <QuillEditor
        url={item?.content}
        onChange={setMessage}
        minHeight={150}
        readOnly={false}
      />
      {error && (
        <div
          style={{
            backgroundColor: "#FEE2E2",
            color: "#DC2626",
            padding: "12px",
            borderRadius: "4px",
            marginTop: "16px",
          }}
        >
          {error}
        </div>
      )}
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "16px",
        }}
      >
        <button
          onClick={onCancel}
          style={{
            padding: "8px 16px",
            backgroundColor: "#E5E7EB",
            color: "#374151",
            borderRadius: "4px",
            border: "none",
            cursor: "pointer",
          }}
        >
          取消
        </button>
        <button
          onClick={handleSave}
          style={{
            padding: "8px 16px",
            backgroundColor: "#3B82F6",
            color: "white",
            borderRadius: "4px",
            border: "none",
            cursor: "pointer",
          }}
        >
          儲存
        </button>
        {!isNew && (
          <button
            onClick={handleDelete}
            style={{
              padding: "8px 16px",
              backgroundColor: "#EF4444",
              color: "white",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
            }}
          >
            刪除
          </button>
        )}
      </div>
    </div>
  );
};

export default MessageEdit;
