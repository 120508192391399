/**
 * @typedef {Object} AuthUserDTO
 * @property {number} userId - 用戶 ID
 * @property {string|null} username - 用戶名
 * @property {string|null} email - 電子郵件
 * @property {number} membershipLevelId - 會員等級 ID
 * @property {string|null} pictureUrl - 頭像 URL
 * @property {string|null} advancedSettings - 進階設定（JSON 字串）
 */

/**
 * 創建一個 AuthUserDTO 物件
 * @param {Object} data - 原始數據
 * @returns {AuthUserDTO}
 */
export function createAuthUserDTO(data) {
    return {
      userId: data.userID,
      username: data.username || null,
      email: data.email || null,
      membershipLevelId: data.membershipLevelId,
      pictureUrl: data.pictureUrl || null,
      advancedSettings: data.advancedSettings || null
    };
  }
  export function getAuthUserDTO(userData) {
    return {
      userIs: userData.id || userData.userID,
      username: userData.username || '',
      email: userData.email || '',
      membershipLevelId: userData.membershipLevelId || 0,
      pictureUrl: userData.pictureUrl || null,
      advancedSettings: userData.advancedSettings || null
    };
  }