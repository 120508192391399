import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { convertToDTO } from "../../types/relationsTypes";
import itemService from "../../services/db/ItemService";
import {
  transactionTypeTranslations,
  relatedEntityTypeTranslations,
  transactionTypeMapping,
} from "../../utils/transaction";
import { getUser } from "../../services/authService";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            padding: "0 4px",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "40px",
        },
        input: {
          padding: "10px 14px",
        },
      },
    },
  },
});

const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FormField = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: "200px",
}));

const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const TransactionEdit = ({ item, onSave, onCancel }) => {
  const [editedItem, setEditedItem] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState("demand");
  const [transactionTypes, setTransactionTypes] = useState({
    type1: "",
    type2: "",
    needTax: false,
    needFee: false,
  });
  const [taxRate, setTaxRate] = useState(0);
  const [feeRate, setFeeRate] = useState(0);
  const [outgoingAmount, setOutgoingAmount] = useState("");
  const [incomingAmount, setIncomingAmount] = useState("");
  const [amountText, setAmountText] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      if (item) {
        const data = await itemService.getWalletString("id", item.parentId);
        const user = getUser();
        setEditedItem({
          ...item,
          walletId: item.parentId,
          createdBy: user.userId,
          createdByName: user.username,
          pointBalance: data.pointBalance,
          credit : data.credit,
        });

        if (item.relatedEntityType) {
          setTransactionTypes(
            transactionTypeMapping[item.relatedEntityType] || {
              type1: "",
              type2: "",
              needTax: false,
              needFee: false,
            }
          );
        }

        if (item.amount) {
          if (item.amount < 0) {
            setOutgoingAmount(Math.abs(item.amount).toString());
          } else {
            setIncomingAmount(item.amount.toString());
          }
        }
      }
    };

    fetchData();
  }, [item]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const amount = outgoingAmount
          ? -parseFloat(outgoingAmount)
          : parseFloat(incomingAmount);
        const dto = await convertToDTO({
          tableType: "Transaction",
          data: {
            ...editedItem,
            amount,
            taxRate: transactionTypes.needTax ? taxRate : 0,
            feeRate: transactionTypes.needFee ? feeRate : 0,
            transactionType: outgoingAmount
              ? transactionTypes.type1
              : transactionTypes.type2,
          },
        });
        const response = await itemService.createItem("Transaction", dto);
        if (transactionTypes.needFee) {
        }

        if (!response.success) {
          throw new Error(`上傳失敗: ${response.error}`);
        }

        const result = convertToDTO({
          tableType: "Transaction",
          data: response.data,
        });
        setEditedItem({ ...result });
        setIsChanged(false);
        onSave(result);
      } catch (error) {
        console.error("儲存交易資料時發生錯誤:", error);
        setError("儲存交易資料時發生錯誤");
      } finally {
        setLoading(false);
      }
    },
    [
      editedItem,
      onSave,
      transactionTypes,
      taxRate,
      feeRate,
      outgoingAmount,
      incomingAmount,
    ]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsChanged(true);

    if (name === "relatedEntityType") {
      const newTransactionTypes = transactionTypeMapping[value] || {
        type1: "",
        type2: "",
        needTax: false,
        needFee: false,
      };
      setTransactionTypes(newTransactionTypes);
      if (!newTransactionTypes.needTax) setTaxRate(0);
      if (!newTransactionTypes.needFee) setFeeRate(0);
      setOutgoingAmount("");
      setIncomingAmount("");
    }
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    if (name === "outgoingAmount") {
      setOutgoingAmount(value);
      setIncomingAmount("");
    } else if (name === "incomingAmount") {
      setIncomingAmount(value);
      setOutgoingAmount("");
    }
    setIsChanged(true);
  };

  
  useEffect(() => {
    const amount = outgoingAmount ? `-${outgoingAmount}` : incomingAmount;
    setAmountText(new Intl.NumberFormat("en-US").format(amount));
  }, [outgoingAmount, incomingAmount]);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    setEditedItem((prev) => ({
      ...prev,
      relatedEntityType: "",
    }));
    setTransactionTypes({
      type1: "",
      type2: "",
      needTax: false,
      needFee: false,
    });
    setTaxRate(0);
    setFeeRate(0);
    setOutgoingAmount("");
    setIncomingAmount("");
    setIsChanged(true);
  };

  const handleRateChange = (e) => {
    const { name, value } = e.target;
    if (name === "taxRate") {
      setTaxRate(parseFloat(value));
    } else if (name === "feeRate") {
      setFeeRate(parseFloat(value));
    }
    setIsChanged(true);
  };

  const filteredRelatedEntityTypes = Object.entries(
    relatedEntityTypeTranslations
  ).filter(([key, value]) => {
    if (userType === "demand") {
      return [
        "ProjectExpense",
        "Wallet",
        "PlatformFee",
        "Bonus",
        "Adjustment",
        "Tax",
      ].includes(key);
    } else if (userType === "supply") {
      return [
        "ProjectIncome",
        "Wallet",
        "PlatformFee",
        "Bonus",
        "Adjustment",
        "Tax",
      ].includes(key);
    }
    return true; // 为通用类型
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ display: "flex", margin: "auto", boxShadow: 3 }}>
        <CardContent sx={{ width: "100%" }}>
          <HeaderRow>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              編輯交易
            </Typography>
            <Box>
              <Button
                onClick={onCancel}
                variant="outlined"
                color="secondary"
                sx={{ mr: 2, minWidth: 100 }}
              >
                取消
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ minWidth: 100 }}
                onClick={handleSubmit}
                disabled={!isChanged}
              >
                儲存
              </Button>
            </Box>
          </HeaderRow>
          <form onSubmit={handleSubmit}>
            <FormRow>
              <FormField>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="user-type"
                    name="user-type"
                    value={userType}
                    onChange={handleUserTypeChange}
                  >
                    <FormControlLabel
                      value="demand"
                      control={<Radio />}
                      label="需求端"
                    />
                    <FormControlLabel
                      value="supply"
                      control={<Radio />}
                      label="供應端"
                    />
                  </RadioGroup>
                </FormControl>
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <FormControl fullWidth>
                  <InputLabel id="related-entity-type-label">類別</InputLabel>
                  <Select
                    labelId="related-entity-type-label"
                    id="relatedEntityType"
                    name="relatedEntityType"
                    value={editedItem.relatedEntityType || ""}
                    onChange={handleChange}
                    label="類別"
                  >
                    {filteredRelatedEntityTypes.map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormField>
            </FormRow>
            {editedItem.relatedEntityType && (
              <>
                <FormRow>
                  <FormField>
                    <TextField
                      name="outgoingAmount"
                      label={`出金(${
                        transactionTypeTranslations[transactionTypes.type1] ||
                        ""
                      })`}
                      type="number"
                      value={outgoingAmount}
                      onChange={handleAmountChange}
                      fullWidth
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      name="incomingAmount"
                      label={`入金(${
                        transactionTypeTranslations[transactionTypes.type2] ||
                        ""
                      })`}
                      type="number"
                      value={incomingAmount}
                      onChange={handleAmountChange}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <TextField
                      name="amount"
                      label="本次交易金額"
                      value={amountText}
                      fullWidth
                      slotProps={{
                        input: {
                          readOnly: true,
                        },
                      }}
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      name="description"
                      label="備註"
                      value={editedItem.description || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
              </>
            )}
            <FormRow>
              <FormField>
                <TextField
                  name="balance"
                  label="餘額"
                  value={new Intl.NumberFormat("en-US").format(editedItem.pointBalance|| 0) }
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  fullWidth
                />
              </FormField>
              <FormField>
                <TextField
                  name="createdBy"
                  label="人員"
                  value={editedItem.createdByName || ""}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  fullWidth
                />
              </FormField>
            </FormRow>
            {editedItem.relatedEntityType && (
              <FormRow>
                <FormField>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={transactionTypes.needTax}
                            disabled
                          />
                        }
                        label="需要計算稅率"
                      />
                      {transactionTypes.needTax && (
                        <TextField
                          name="taxRate"
                          label="稅率 (%)"
                          type="number"
                          value={taxRate}
                          onChange={handleRateChange}
                          fullWidth
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </FormField>
                <FormField>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={transactionTypes.needFee}
                            disabled
                          />
                        }
                        label="需要計算手續費"
                      />
                      {transactionTypes.needFee && (
                        <TextField
                          name="feeRate"
                          label="手續費率 (%)"
                          type="number"
                          value={feeRate}
                          onChange={handleRateChange}
                          fullWidth
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </FormField>
              </FormRow>
            )}
          </form>
        </CardContent>
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}
      </Card>
    </ThemeProvider>
  );
};

export default TransactionEdit;
