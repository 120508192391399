import React from "react";
import TypeSelector from "../Selector/TypeSelector";

const TeamMember = ({ member, onUpdate, onRemove, readOnly }) => {
  const handleRoleChange = (selectedItem) => {
    onUpdate({ ...member, roleType: selectedItem.id, Type: selectedItem.Type });
  };

  const handleHourlyRateChange = (e) => {
    onUpdate({ ...member, hourlyRate: parseFloat(e.target.value) });
  };

  return (
    <div
      className={`bg-white p-3 rounded-lg shadow-sm border border-gray-200 ${
        member.leaveDate ? "opacity-60" : ""
      }`}
    >
      <div className="flex items-center mb-2">
        {member.pictureUrl && (
          <img
            src={member.pictureUrl}
            alt={member.username}
            className="w-10 h-10 rounded-full mr-3 border border-gray-200"
          />
        )}
        <div className="flex-grow">
          <div className="font-medium text-sm">{member.username}</div>
          <div className="text-xs text-gray-500">{member.email}</div>
        </div>
        {!readOnly &&
          (member.leaveDate ? (
            <button
              onClick={() => onUpdate({ ...member, leaveDate: null })}
              className="text-xs bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 transition-colors duration-200"
            >
              重新加入
            </button>
          ) : (
            <button
              onClick={() =>
                member.isTemporary
                  ? onRemove(member.id)
                  : onUpdate({
                      ...member,
                      leaveDate: new Date().toISOString().split("T")[0],
                    })
              }
              className="text-xs bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition-colors duration-200"
            >
              {member.isTemporary ? "移除" : "標記退出"}
            </button>
          ))}
      </div>
      <div className="flex items-center space-x-2">
        <div className="flex-grow">
          <TypeSelector
            showDescription={true}
            currentTypeId={member.roleType}
            onTypeChange={handleRoleChange}
            readOnly={!!member.leaveDate || readOnly}
            itemType="TeamMember"
            className="w-full text-sm"
          />
        </div>
        {!readOnly && (
          <div className="flex flex-col w-24">
            <label className="text-xs text-gray-600 mb-1">時薪</label>
            <input
              type="number"
              value={member.hourlyRate}
              onChange={handleHourlyRateChange}
              placeholder="時薪"
              className="border rounded px-2 py-1 w-full text-sm focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              disabled={!!member.leaveDate || readOnly}
            />
          </div>
        )}
      </div>
      <div className="mt-2 text-xs text-gray-500">
        加入日期: {member.joinDate}
        {member.leaveDate && (
          <span className="ml-2 text-red-500">
            退出日期: {member.leaveDate}
          </span>
        )}
      </div>
    </div>
  );
};

export default TeamMember;
