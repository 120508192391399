import React, { useState, useRef, useEffect } from "react";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { login, logout } from "../../services/authService";
import { useNavigate } from "react-router-dom";

const GoogleAuthButton = ({
  isAuthenticated,
  onAuthChange,
  user,
  setStatus,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    try {
      setStatus("使用者驗證中......");
      const user = await login(response.credential,setStatus);
      await onAuthChange(true, user);
      setStatus(null);   
    } catch (error) {
      console.error("後端驗證失敗:", error);
      onAuthChange(false, null);
      setStatus(null);   
    }
  };

  const handleEditProfile = () => {
    setMenuVisible(false);
    /** @type {import('../../types/authTypes').AuthUserDTO} */
    const userDTO = JSON.parse(localStorage.getItem("user"));
    if (userDTO && userDTO.userId) {
      navigate("/profile", { state: { userId: userDTO.userId } });
    } else {
      console.error("用戶 ID 不存在");
    }
  };

  const handleLogout = async () => {
    try {
      setStatus("登出中");
      await logout();
      googleLogout();
      onAuthChange(false, null);
      setStatus(null);
    } catch (error) {
      console.error("登出失敗:", error);
    }
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <div className="flex items-center space-x-4 relative" ref={buttonRef}>
      {isAuthenticated && user ? (
        <div>
          <button
            onClick={toggleMenu}
            className="w-10 h-10 rounded-full overflow-hidden focus:outline-none focus:ring"
          >
            <img
              src={user.picture}
              alt="User"
              className="w-full h-full object-cover"
            />
          </button>
          {menuVisible && (
            <div className="absolute right-0 mt-2 bg-white rounded-md shadow-lg py-2 z-50 auto-size">
              <button
                onClick={handleEditProfile}
                className="block w-full px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200 text-right"
              >
                編輯個人檔案
              </button>
              <button
                onClick={handleLogout}
                className="block w-full px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 transition-colors duration-200 text-right"
              >
                登出
              </button>
            </div>
          )}
        </div>
      ) : (
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={() => {
            console.log("登入失敗");
            onAuthChange(false, null);
          }}
          useOneTap
          type="icon"
          shape="circle"
        />
      )}
    </div>
  );
};

export default GoogleAuthButton;
