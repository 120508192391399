import React, { useState, useEffect } from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { accessCheck } from "./Access";

// 自定義圖標組件
const CustomIcon = ({ icon }) => (
  <span style={{ fontSize: "1.2rem", marginRight: "8px" }}>{icon}</span>
);

const DropdownMenu = ({
  isOpen,
  onClose,
  anchorEl,
  currentItem,
  onEdit,
  onDelete,
  onNew,
  onEditBudget,
}) => {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    const fetchActions = async () => {
      if (currentItem) {
        const result = await (currentItem.tableType === "TaskAction"
          ? accessCheck("Mission", currentItem.parentId)
          : accessCheck(currentItem.tableType, currentItem.id));
        const newActions = [];
        if (result) {
          if (result.readOnly) {
            newActions.push({
              label: "檢視",
              action: () => onEdit(currentItem),
              icon: "👁️",
            });
          }

          if (result.write) {
            newActions.push({
              label: "編輯",
              action: () => onEdit(currentItem),
              icon: "✏️",
            });
          }

          if (result.delete) {
            newActions.push({
              label: "刪除",
              action: () => onDelete(currentItem),
              icon: "🗑️",
            });
          }
          if (result.budget) {
            newActions.push({
              label: "編輯預算",
              action: () => onEditBudget(currentItem),
              icon: "💰",
            });
          }
          if (result.write && currentItem.tableType === "Mission") {
            newActions.push({
              label: "新增行動",
              action: () => onNew(currentItem),
              icon: "➕",
            });
          } else if (
            result.createChild &&
            currentItem.tableType !== "TaskAction"
          ) {
            let newItemLabel;
            switch (currentItem.tableType) {
              case "Project":
                newItemLabel = "新增里程碑";
                break;
              case "Milestone":
                newItemLabel = "新增任務";
                break;
              case "Mission":
                newItemLabel = "新增行動";
                break;
              default:
                newItemLabel = "新增子項目";
            }
            newActions.push({
              label: newItemLabel,
              action: () => onNew(currentItem),
              icon: "➕",
            });
          }
        }
        setActions(newActions);
      }
    };

    fetchActions();
  }, [currentItem, onEdit, onDelete, onNew,onEditBudget]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {actions.map(({ label, action, icon }) => (
        <MenuItem
          key={label}
          onClick={() => {
            onClose();
            action();
          }}
        >
          <ListItemIcon>
            <CustomIcon icon={icon} />
          </ListItemIcon>
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default DropdownMenu;
