import { formatDateTimeForDB } from '../utils/dateUtils';

/**
 * @typedef {Object} UserCompanyRelationDTO
 * @property {number|null} relationID - Relation ID
 * @property {number|null} accountID - User ID
 * @property {number|null} companyID - Company ID
 * @property {string|null} roleType - Role type
 * @property {string|null} companyEmail - Company email
 * @property {string|null} contactNumber - Contact number
 * @property {string|null} department - Department
 * @property {string|null} address - Address
 * @property {Date|null} createdAt - Creation date
 * @property {Date|null} updatedAt - Update date
 * @property {Date|null} quitDate - Quit date
 */

/**
 * @typedef {Object} CompanyDTO
 * @property {number} companyID - Company ID
 * @property {string} companyName - Company name
 * @property {string} address - Company address
 * @property {string} phoneNumber - Company phone number
 * @property {string} website - Company website
 * @property {string} industry - Company industry
 * @property {number|null} membershipLevelID - Membership level ID
 * @property {number|null} walletID - Wallet ID
 * @property {Date} createdAt - Creation date
 * @property {Date} updatedAt - Update date
 * @property {boolean} isDeleted - Deletion status
 * @property {string} registerId - Register ID
 * @property {string} shortName - Company short name
 */


/**
 * @typedef {Object} SupplierDTO
 * @property {number|null} id - Supplier ID
 * @property {number} accountId - Account ID
 * @property {string|null} name - Supplier name
 * @property {string|null} email - Supplier email
 * @property {string|null} contactNumber - Contact number
 * @property {Date|null} joinDate - Join date
 * @property {Date|null} quitDate - Quit date
 * @property {string|null} address - Address
 * @property {Date|null} createdAt - Creation date
 * @property {Date|null} updatedAt - Update date
 * @property {boolean|null} isDeleted - Deletion status
 * @property {number|null} companyId - Company ID
 * @property {string|null} role - Role
 */

/**
 * Creates a UserCompanyRelationDTO object
 * @param {Object} data - Raw data
 * @returns {UserCompanyRelationDTO}
 */
export function createUserCompanyRelationDto(data) {
  return {
    relationId: data.relationId || null,
    accountId: data.accountId || null,
    companyId: data.companyId || null,
    roleType: data.roleType || null,
    name: data.name || null,
    address: data.address || null,
    companyEmail: data.companyEmail || null,
    contactNumber: data.contactNumber || null,
    department: data.department || null,
    createdAt: data.createdAt ? formatDateTimeForDB(data.createdAt) : null,
    updatedAt: data.updatedAt ? formatDateTimeForDB(data.updatedAt) : null,
    quitDate: data.quitDate ? formatDateTimeForDB(data.quitDate) : null
  };
}

/**
 * Creates a CompanyDTO object
 * @param {Object} data - Raw data
 * @returns {CompanyDTO}
 */
export function createCompanyDto(data) {
  return {
    companyID: data.companyID || null,
    companyName: data.companyName || '',
    address: data.address || '',
    phoneNumber: data.phoneNumber || '',
    website: data.website || '',
    industry: data.industry || '',
    membershipLevelID: data.membershipLevelID || null,
    walletID: data.walletID || null,
    createdAt: data.createdAt ? formatDateTimeForDB(data.createdAt) : null,
    updatedAt: data.updatedAt ? formatDateTimeForDB(data.updatedAt) : null,
    isDeleted: data.isDeleted || false,
    registerId: data.registerId || '',
    shortName: data.shortName || ''
  };
}


/**
 * Creates a SupplierDTO object
 * @param {Object} data - Raw data
 * @returns {SupplierDTO}
 */
export function createSupplierDto(data) {
  return {
    id: data.id || null,
    accountId: data.accountId,
    name: data.name || null,
    email: data.email || null,
    contactNumber: data.contactNumber || null,
    joinDate: data.joinDate ? formatDateTimeForDB(data.joinDate) : null,
    quitDate: data.quitDate ? formatDateTimeForDB(data.quitDate) : null,
    address: data.address || null,
    createdAt: data.createdAt ? formatDateTimeForDB(data.createdAt) : null,
    updatedAt: data.updatedAt ? formatDateTimeForDB(data.updatedAt) : null,
    isDeleted: data.isDeleted || null,
    companyId: data.companyId || null,
    role: data.role || null
  };
}

// Add this to the dtoCreators object
export const dtoCreators = {
  UserCompanyRelation: createUserCompanyRelationDto,
  Company: createCompanyDto,
  Supplier: createSupplierDto,
};

/**
 * Converts a sync item to its corresponding DTO
 * @param {Object} syncItem - The sync item to convert
 * @param {string} syncItem.tableType - The table type of the sync item
 * @param {Object} syncItem.data - The data of the sync item
 * @returns {Object} The converted DTO or the original data if no corresponding DTO creator is found
 */
export function convertToDTO(syncItem) {
  const { tableType, data } = syncItem;
  const createDTO = dtoCreators[tableType];

  if (createDTO) {
    return createDTO(data);
  } else {
    console.warn(`未知的表類型: ${tableType}`);
    return data; // 如果沒有對應的 DTO，直接返回原始數據
  }
}