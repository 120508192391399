import axios from 'axios';
import { getUserDTO } from '../../types/userTypes';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
class UserService {
  async fetchUserData(userId, token, currentUser) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/Users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'user': currentUser?.userId,
        }
      });
      const userData = getUserDTO(response.data);
      
      // 確保 userData 包含正確的 userId
      if (!userData.userId) {
        console.warn('API 返回的用戶數據中沒有 userId，使用傳入的 userId');
        userData.userId = userId;
      }

      return userData;
    } catch (error) {
      console.error('從 API 獲取用戶數據失敗:', error);
      // 如果 API 請求失敗，嘗試從本地數據庫獲取
      return null;
    }
  }

  async updateUserData(userId, editedUser, token, currentUser) {
    if (userId === currentUser?.userId) {
      try {
        const response = await axios.put(`${API_BASE_URL}/api/Users/${userId}`, editedUser, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'user': currentUser?.userId
          }
        });
        
        const updatedUserData = getUserDTO(response.data);
        
        // 確保 updatedUserData 包含正確的 userId
        if (!updatedUserData.userId) {
          console.warn('API 返回的更新用戶數據中沒有 userId，使用傳入的 userId');
          updatedUserData.userId = userId;
        }
        return updatedUserData;
      } catch (error) {
        console.error('更新用戶數據到 API 失敗:', error);
        throw error;
      }
    } else {
      throw new Error('無權更新其他用戶的數據');
    }
  }
}

const userService = new UserService();

export const fetchUserData = userService.fetchUserData.bind(userService);
export const updateUserData = userService.updateUserData.bind(userService);


export default userService;