import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { convertToDTO } from "../../types/relationsTypes";
import itemService from "../../services/db/ItemService";
import { supplierRoleTypes } from "../../utils/utils";
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            background: "#fff",
            padding: "0 4px",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "40px",
        },
        input: {
          padding: "10px 14px",
        },
      },
    },
  },
});

const FormRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FormField = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: "200px",
}));

const SupplierEdit = ({ item, onSave, onCancel }) => {
  const [editedItem, setEditedItem] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [emailSearchStatus, setEmailSearchStatus] = useState(null);

  useEffect(() => {
    if (item) {
      if (item.companyID) setEditedItem({ ...item, companyId: item.companyID });
      else setEditedItem({ ...item });

      setIsNewItem(!item.relationId);
    }
  }, [item]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        const dto = await convertToDTO({
          tableType: "Supplier",
          data: editedItem,
        });
        const response = dto.relationId
          ? await itemService.updateItem(item.tableType, dto, "")
          : await itemService.createItem(item.tableType, dto);

        if (!response.success) {
          throw new Error(`上傳失敗: ${response.error}`);
        }

        const result = convertToDTO({
          tableType: item.tableType,
          data: response.data,
        });
        setEditedItem({ ...result });
        setIsChanged(false);
        onSave();
      } catch (error) {
        console.error("儲存資料時發生錯誤:", error);
        setError("儲存資料時發生錯誤");
      } finally {
        setLoading(false);
      }
    },
    [editedItem, item, onSave]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsChanged(true);
  };

  const handleClearQuitDate = () => {
    setEditedItem((prev) => ({
      ...prev,
      quitDate: null,
    }));
    setIsChanged(true);
  };

  const handleEmailSearch = async () => {
    setLoading(true);
    try {
      const result = await itemService.getUserByEmail(editedItem.email);
      // 確保 result 是一個陣列，並取得第一個元素
      const data = Array.isArray(result) ? result[0] : result;
      if (data && data.accountId) {
        setEditedItem((prev) => ({
          ...prev,
          accountId: data.accountId,
          companyEmail: data.email,
          name: data.username,
        }));
        setEmailSearchStatus(true);
      } else {
        setEmailSearchStatus(false);
      }
    } catch (error) {
      console.error("搜尋帳號時發生錯誤:", error);
      setEmailSearchStatus(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ maxWidth: 800, margin: "auto", mt: 5, boxShadow: 3 }}>
        <CardContent>
          <Typography
            variant="h5"
            component="div"
            sx={{ mb: 3, textAlign: "center", fontWeight: "bold" }}
          >
            編輯員工資料
          </Typography>
          <form onSubmit={handleSubmit}>
            {isNewItem && (
              <FormRow>
                <FormField>
                  <Box display="flex" alignItems="center">
                    <TextField
                      name="email"
                      label="使用者電子郵件"
                      value={editedItem.email || ""}
                      onChange={handleChange}
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor:
                              emailSearchStatus === true
                                ? "green"
                                : emailSearchStatus === false
                                ? "red"
                                : "rgba(0, 0, 0, 0.23)",
                          },
                          "&:hover fieldset": {
                            borderColor:
                              emailSearchStatus === true
                                ? "green"
                                : emailSearchStatus === false
                                ? "red"
                                : "rgba(0, 0, 0, 0.23)",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor:
                              emailSearchStatus === true
                                ? "green"
                                : emailSearchStatus === false
                                ? "red"
                                : "#1976d2",
                          },
                        },
                      }}
                    />
                    <IconButton onClick={handleEmailSearch} sx={{ ml: 1 }}>
                      <SearchIcon />
                    </IconButton>
                  </Box>
                </FormField>
              </FormRow>
            )}
            {(!isNewItem || (isNewItem && emailSearchStatus)) && (
              <>
                <FormRow>
                  <FormField>
                    <TextField
                      name="name"
                      label="姓名"
                      value={editedItem.name || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                  <FormField>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">角色</InputLabel>
                      <Select
                        labelId="role-label"
                        id="role"
                        name="role"
                        value={editedItem.role || ""}
                        onChange={handleChange}
                        label="角色"
                      >
                        {supplierRoleTypes.map((role) => (
                          <MenuItem key={role.value} value={role.value}>
                            {role.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <TextField
                      name="email"
                      label="電子郵件"
                      value={editedItem.email || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      name="contactNumber"
                      label="聯絡電話"
                      value={editedItem.contactNumber || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <TextField
                      name="address"
                      label="地址"
                      value={editedItem.address || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                  <FormField>
                    <TextField
                      name="companyId"
                      label="公司ID"
                      value={editedItem.companyId || ""}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField>
                    <TextField
                      name="joinDate"
                      label="加入日期"
                      type="date"
                      value={editedItem.joinDate || ""}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormField>
                  <FormField>
                    <Box display="flex" alignItems="center">
                      <TextField
                        name="quitDate"
                        label="離職日期"
                        type="date"
                        value={editedItem.quitDate || ""}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <IconButton onClick={handleClearQuitDate} sx={{ ml: 1 }}>
                        <ClearIcon />
                      </IconButton>
                    </Box>
                  </FormField>
                </FormRow>
              </>
            )}
          </form>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", pb: 3 }}>
          <Button
            onClick={onCancel}
            variant="outlined"
            color="secondary"
            sx={{ mr: 2, minWidth: 100 }}
          >
            取消
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ minWidth: 100 }}
            onClick={handleSubmit}
            disabled={!isChanged}
          >
            儲存
          </Button>
        </CardActions>
        {error && (
          <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
            {error}
          </Typography>
        )}
      </Card>
    </ThemeProvider>
  );
};

export default SupplierEdit;
