import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createAuthUserDTO } from "../types/authTypes";
import { clearIndexedDB, deleteIndexedDB } from "./db/clearIndexedDB";
import { setLastUpdateTime } from "./db/syncService";

const AUTH_TOKEN_KEY = "token";
const AUTH_USER_KEY = "user";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const handleError = (error, message) => {
  console.error(message, error);
  throw error;
};

const clearAllData = async () => {
  localStorage.clear();
  try {
    clearIndexedDB()
      .then(() => {})
      .catch((error) => {
        console.error("清理失敗:", error);
      });
  } catch (error) {
    console.error("清除 IndexedDB 表格失敗:", error);
  }

  try {
    await deleteIndexedDB();
  } catch (error) {
    console.error("刪除 IndexedDB 數據庫失敗:", error);
  }
};

export const login = async (data, setStatus) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/Auth/authenticate`, {
      Token: data,
    });

    const token = response.data.token;
    localStorage.setItem(AUTH_TOKEN_KEY, token);

    const userDTO = createAuthUserDTO(response.data.user);
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(userDTO));
    setStatus("資料下載中...");
    try {
      setLastUpdateTime();
      setStatus("資料更新完畢");
    } catch (error) {
      console.error("資料庫更新失敗:", error);
    }

    return userDTO;
  } catch (error) {
    handleError(error, "登入失敗:");
  }
};

export const logout = async () => {
  const token = getToken();
  try {
    if (token) {
      await axios.post(
        `${API_BASE_URL}/api/Auth/logout`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    }
  } catch (error) {
    console.error("登出 API 調用失敗:", error);
  } finally {
    await clearAllData();
    window.location.reload();
  }
};

export const getCurrentUser = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) return null;

  try {
    const user = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (user.exp > currentTime) {
      return user;
    } else {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      return null;
    }
  } catch (error) {
    console.error("無效的 token:", error);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    return null;
  }
};

export const getToken = () => localStorage.getItem(AUTH_TOKEN_KEY);
export const getUser = () => {
  const userString = localStorage.getItem(AUTH_USER_KEY);
  if (!userString) return null;

  try {
    return JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user data from localStorage:", error);
    return null;
  }
};
export const verifyToken = async () => {
  const token = getToken();
  if (!token) return { isValid: false };

  try {
    var result = await axios.get(`${API_BASE_URL}/api/Secure/secure-endpoint`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { isValid: result.status === 200 };
  } catch (error) {
    console.error("Token 驗證失敗:", error);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(AUTH_USER_KEY);
    return { isValid: false };
  }
};

export const isAuthenticated = () => !!getCurrentUser();

export const refreshToken = async () => {
  const token = getToken();
  if (!token) return null;

  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/Auth/refresh-token`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    localStorage.setItem(AUTH_TOKEN_KEY, response.data.token);
    return response.data.token;
  } catch (error) {
    handleError(error, "Token刷新失敗:");
  }
};
export const getCurrentUserLevel = () => {
  const user = getUser();
  if (user) return user.membershipLevelId;
  return 0;
};
