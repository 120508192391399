import React from "react";
import TeamMember from "./TeamMember";

const TeamMemberList = ({
  team,
  onUpdateMember,
  onRemoveMember,
  isDragging,
  setIsDragging,
  readOnly,
  onComplete,
  milestoneId,
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    try {
      const data = e.dataTransfer.getData("application/json");

      const userData = JSON.parse(data);

      const newMember = {
        id: `temp_${Date.now()}`,
        accountId: userData.accountId,
        username: userData.username,
        email: userData.email,
        pictureUrl: userData.pictureUrl,
        roleType: "",
        hourlyRate: 1,
        joinDate: new Date().toISOString().split("T")[0],
        leaveDate: null,
        isTemporary: true,
        milestoneId: milestoneId,
        tableType: "TeamMember",
      };
      onUpdateMember(newMember);
    } catch (error) {
      console.error("Error handling dropped data:", error);
    }
    setIsDragging(false);
  };

  return (
    <div
      className={`${readOnly ? "w-full" : "w-1/2"} p-4 flex flex-col`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="flex justify-between items-center mb-2">
        <h4 className="text-lg font-semibold text-gray-700">團隊成員</h4>
        {readOnly === false && (
          <button
            onClick={() => {
              onComplete();
            }}
            className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 transition-colors duration-200 text-sm"
          >
            完成
          </button>
        )}
      </div>
      <div
        className={`flex-grow overflow-y-auto space-y-2 ${
          isDragging
            ? "bg-blue-50 border-2 border-blue-300 border-dashed rounded-md p-2"
            : ""
        }`}
      >
        {team.map((member) => (
          <TeamMember
            key={member.id}
            member={member}
            onUpdate={onUpdateMember}
            onRemove={onRemoveMember}
            readOnly={readOnly}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamMemberList;
