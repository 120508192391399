import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import itemService from "../../services/db/ItemService";
import { getUser } from "../../services/authService";
import ItemEditModal from "../common/ItemEditModal";
import { formatDate } from "../../utils/dateUtils";
import { supplierRoleTypes } from "../../utils/utils";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  position: 'relative',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CUSTOM_EVENT_NAME = "dataUpdated";

const SupplyChainManagement = () => {
  const [supplyChainItems, setSupplyChainItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [access, setAccess] = useState(false);
  const [parentContainerWidth, setParentContainerWidth] = useState(
    window.innerWidth
  );
  const [columnWidths, setColumnWidths] = useState({});

  const loadSupplyChainItems = useCallback(async (isInitialLoad = false) => {
    if (isInitialLoad) {
      setLoading(true);
    }
    setError(null);
    try {
      const user = getUser();
      let fetchedItems = [];

      if (user.membershipLevelId > 10) {
        setAccess(true);
        fetchedItems = await itemService.getSupplier();
      }
      
      if (isInitialLoad) {
        setSupplyChainItems(fetchedItems);
      } else {
        setSupplyChainItems(prevItems => {
          const updatedItems = [...prevItems];
          fetchedItems.forEach(newItem => {
            const index = updatedItems.findIndex(item => item.itemId === newItem.itemId);
            if (index !== -1) {
              // 更新現有項目
              updatedItems[index] = { ...updatedItems[index], ...newItem };
            } else {
              // 添加新項目
              updatedItems.push(newItem);
            }
          });
          // 移除不再存在的項目
          return updatedItems.filter(item => 
            fetchedItems.some(newItem => newItem.itemId === item.itemId)
          );
        });
      }
    } catch (error) {
      console.error("Failed to load supply chain items:", error);
      setError("Failed to load supply chain items. Please try again.");
    } finally {
      if (isInitialLoad) {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    loadSupplyChainItems(true);  // 初始加載
    const handleDataUpdated = () => {
      loadSupplyChainItems(false);  // 後續更新
    };
    const handleResize = () => setParentContainerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
    };
  }, [loadSupplyChainItems]);

  const handleOpenEditModal = (item = null) => {
    setSelectedItem(item || { tableType: "Supplier" });
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const handleSave = async () => {
    handleCloseEditModal();
  };

  const handleColumnResize = (columnId, newWidth) => {
    setColumnWidths(prevWidths => ({
      ...prevWidths,
      [columnId]: newWidth,
    }));
  };

  const columns = useMemo(
    () => [
      { id: "account", label: "", minWidth: 100 },
      { id: "name", label: "名稱", minWidth: 170 },
      { id: "email", label: "Email", minWidth: 150 },
      { id: "contactNumber", label: "連絡電話", minWidth: 100 },
      { id: "dateRange", label: "加入/離開日期", minWidth: 150 },
      { id: "address", label: "地址", minWidth: 130 },
      { id: "company", label: "公司", minWidth: 100 },
      { id: "role", label: "類型", minWidth: 100, align: "center" },
      { id: "actions", label: "操作", minWidth: 100, align: "center" },
    ],
    []
  );

  const renderRoleType = (role) => {
    const roleType = supplierRoleTypes.find(type => type.value === role);
    return roleType ? roleType.label : role;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ width: "100%", mb: 2 }}>
        <Typography color="error">{error}</Typography>
        <Button onClick={loadSupplyChainItems}>重試</Button>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      {access && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenEditModal()}
          sx={{ mb: 2 }}
        >
          新增供應鏈
        </Button>
      )}
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ 
                    minWidth: column.minWidth,
                    width: columnWidths[column.id] || 'auto',
                  }}
                >
                  {column.label}
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                      width: '5px',
                      cursor: 'col-resize',
                    }}
                    onMouseDown={(e) => {
                      const startX = e.pageX;
                      const startWidth = e.target.parentElement.offsetWidth;
                      
                      const handleMouseMove = (e) => {
                        const newWidth = startWidth + e.pageX - startX;
                        handleColumnResize(column.id, newWidth);
                      };
                      
                      const handleMouseUp = () => {
                        document.removeEventListener('mousemove', handleMouseMove);
                        document.removeEventListener('mouseup', handleMouseUp);
                      };
                      
                      document.addEventListener('mousemove', handleMouseMove);
                      document.addEventListener('mouseup', handleMouseUp);
                    }}
                  />
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {supplyChainItems.map((item) => (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={item.itemId}
              >
                {columns.map((column) => {
                  const value = item[column.id];
                  if (column.id === "actions") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {access && (
                          <IconButton
                            onClick={() => handleOpenEditModal(item)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </StyledTableCell>
                    );
                  }
                 
                  if (column.id === "account") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {item.account && (
                          <Box
                            component="img"
                            src={item.account.pictureUrl}
                            alt={item.account.username}
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              border: '1px solid #e0e0e0'
                            }}
                          />
                        )}
                      </StyledTableCell>
                    );
                  }  
                  if (column.id === "company") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {value && value.companyName}
                      </StyledTableCell>
                    );
                  }
                  if (column.id === "dateRange") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {formatDate(item.joinDate)} - {item.quitDate ? formatDate(item.quitDate) : '至今'}
                      </StyledTableCell>
                    );
                  }
                  if (column.id === "role") {
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {renderRoleType(value)}
                      </StyledTableCell>
                    );
                  }
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {value}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent={"Supplier"}
          readOnly={false}
          tableType={"Supplier"}
          setItemList={loadSupplyChainItems}
          setErrorMessage={setError}
          parentContainerWidth={parentContainerWidth}
          onSave={handleSave}
          onClose={handleCloseEditModal}
        />
      )}
    </Box>
  );
};

export default SupplyChainManagement;