// AssigneeService.js
import itemService from "../../../services/db/ItemService";
import { getUser } from "../../../services/authService";
import { companyRoleTypes } from "../../../utils/utils";
export class AssigneeService {
  static async getAssigneeData(itemType, item, currentAssigneeId) {
    let options = [];
    let currentAssignee = null;

    switch (itemType) {
      case "Project":
        ({ options, currentAssignee } = await this.getProjectAssigneeData(
          item
        ));
        break;
      case "Milestone":
        ({ options, currentAssignee } = await this.getMilestoneAssigneeData(
          item,
          currentAssigneeId
        ));
        break;
      case "Mission":
        ({ options, currentAssignee } = await this.getMissionAssigneeData(
          item,
          currentAssigneeId
        ));
        break;
      case "TaskAction":
        ({ options, currentAssignee } = await this.getTaskActionAssigneeData(
          item,
          currentAssigneeId
        ));
        break;
      default:
        throw new Error(`Unsupported item type: ${itemType}`);
    }

    return { options, currentAssignee };
  }

  static async getProjectAssigneeData(item) {
    const localUser = getUser();
    let allMembers = [];
    let options = [];
    let currentAssignee =
      item.accountId !== undefined
        ? {
            id: `${item.accountId}`,
            label: item.assigneeItem?.username,
            color: "bg-gray-200 text-gray-800",
            accountId: item.accountId,
            assigneeId: item.assigneeId,
            data: item.assigneeItem,
          }
        : null;
    const isPersonalProject = !item.parentId || item.parentId <= 0;

    if (!isPersonalProject) {
      const relations = await itemService.getMemberByCompany(item.parentId);
      // 檢查 localUser 是否在 relations 中
      const localUserInRelations = relations.some(
        (r) => r.accountId === localUser.userId
      );
      allMembers = [
        ...(!localUserInRelations
          ? [
              {
                id: `${localUser.userId}-${localUser.userId}`,
                label: localUser.username,
                color: "bg-yellow-200 text-yellow-800",
                description: "系統代理",
                accountId: localUser.userId,
                assigneeId: localUser.userId,
                data: localUser,
              },
            ]
          : []),
        ...relations.map((r) => {
          const roleLabel = companyRoleTypes.find(
            (role) => role.value === r.roleType
          )?.label;
          return {
            id: `${r.accountId}`,
            label: `${r.name}${r.quitDate ? " (已離職)" : ""}`,
            color: r.quitDate ? "bg-gray-200 text-gray-800" : null,
            description: roleLabel
              ? `客戶:${r.department}(${roleLabel})`
              : `客戶:${r.department}`,
            accountId: r.accountId,
            assigneeId: r.accountId,
            leaveDate: r.quitDate,
            data: r,
          };
        }),
      ];
      const findAssignee = allMembers.find(
        (member) => member.accountId === item.accountId
      );
      if (findAssignee) currentAssignee = findAssignee;
      else {
        if (currentAssignee) {
          allMembers = [
            ...allMembers,
            { ...currentAssignee, description: "前負責人" },
          ];
        }
      }
    } else {
      if (localUser.userId === item.accountId) {
        currentAssignee = {
          ...currentAssignee,
          description: "個人專案",
        };
        allMembers = [currentAssignee];
      } else if (item.accountId === undefined) {
        allMembers = [
          {
            id: `${localUser.userId}`,
            label: localUser.username,
            description: "個人專案",
            color: "bg-gray-200 text-gray-800",
            accountId: localUser.userId,
            assigneeId: localUser.userId,
            data: localUser,
          },
        ];
      } else if (currentAssignee) {
        allMembers = [
          ...allMembers,
          { ...currentAssignee, description: "前負責人" },
        ];
      }
    }
    options = allMembers.filter((member) => !member.leaveDate);

    return { options, currentAssignee };
  }

  static async getMilestoneAssigneeData(item) {
    const milestoneRelations = item.id
      ? await itemService.getMilestoneString("assignee", String(item.id))
      : await itemService.getMilestoneString(
          "assigneeParent",
          String(item.parentId)
        );
    const localUser = getUser();
    let allMembers = [];
    let options = [];
    let currentAssignee =
      item.accountId !== undefined
        ? {
            id: `${item.accountId}`,
            label: item.assigneeItem?.username,
            color: "bg-gray-200 text-gray-800",
            accountId: item.accountId,
            assigneeId: item.accountId,
            data: item.assigneeItem,
          }
        : null;

    if (Array.isArray(milestoneRelations) && milestoneRelations.length > 0) {
      const data = milestoneRelations[0];
      const relations = data.companyRelations;
      const teamMembers = data.teamMembers;
      const isPersonalProject = !relations;
      let findAssignee;
      if (teamMembers) {
        allMembers.push(
          ...teamMembers
            .filter(
              (t) =>
                t.roleTypeNavigation &&
                (t.roleTypeNavigation.typeName === "CSM" ||
                  t.roleTypeNavigation.typeName === "PM")
            )
            .map((t) => ({
              id: `${t.accountId}-${t.id}`,
              label: `${t.user ? t.user.username : ""}(${
                t.roleTypeNavigation ? t.roleTypeNavigation.typeName : ""
              })${t.leaveDate ? " (已退出)" : ""}`,
              color: t.leaveDate ? "bg-gray-200 text-gray-800" : null,
              description: t.roleTypeNavigation
                ? `團隊:${t.roleTypeNavigation.description}`
                : "",
              accountId: t.accountId,
              assigneeId: t.accountId,
              leaveDate: t.leaveDate,
              data: t,
            }))
        );
      }
      if (!isPersonalProject) {
        allMembers.push(
          ...relations.map((r) => {
            const roleLabel = companyRoleTypes.find(
              (role) => role.value === r.roleType
            )?.label;
            return {
              id: `${r.accountId}-${r.accountId}`,
              label: `${r.name}${r.quitDate ? " (已離職)" : ""}`,
              color: r.quitDate ? "bg-gray-200 text-gray-800" : null,
              description: roleLabel
                ? `客戶:${r.department}(${roleLabel})`
                : r.department,
              accountId: r.accountId,
              assigneeId: r.accountId,
              leaveDate: r.quitDate,
              data: r,
            };
          })
        );

        const localUserInRelations = allMembers.some(
          (r) => r.accountId === localUser.userId
        );
        if (!localUserInRelations) {
          allMembers = [
            {
              id: `${localUser.userId}-${localUser.userId}`,
              label: localUser.username,
              color: "bg-yellow-200 text-yellow-800",
              description: "系統代理",
              accountId: localUser.userId,
              assigneeId: localUser.userId,
              data: localUser,
            },
            ...allMembers,
          ];
        }
        findAssignee = allMembers.find(
          (member) => member.accountId === item.accountId
        );
        if (findAssignee) currentAssignee = findAssignee;
        else {
          if (currentAssignee) {
            allMembers = [
              ...allMembers,
              { ...currentAssignee, description: "前負責人" },
            ];
          }
        }
      } else {
        if (currentAssignee) {
          allMembers = [
            {
              id: `${localUser.userId}`,
              label: localUser.username,
              color: "bg-yellow-200 text-yellow-800",
              description: "代理",
              accountId: localUser.userId,
              assigneeId: localUser.userId,
              data: localUser,
            },
          ];
          // 尋找團隊內有沒有現在的指定人
          findAssignee = allMembers.find(
            (member) => member.accountId === item.accountId
          );
          if (findAssignee) {
            currentAssignee = findAssignee;
            allMembers = [currentAssignee, ...allMembers];
          } else {
            if (currentAssignee) {
              allMembers = [
                { ...currentAssignee, description: "前負責人" },
                ...allMembers,
              ];
            }
          }
        } else {
          allMembers = [
            {
              id: `${localUser.userId}`,
              label: localUser.username,
              color: "bg-yellow-200 text-yellow-800",
              description: "個人專案",
              accountId: localUser.userId,
              assigneeId: localUser.userId,
              data: localUser,
            },
          ];
        }
      }
    }
    options = allMembers.filter((member) => !member.leaveDate);

    return { options, currentAssignee };
  }

  static async getMissionAssigneeData(item) {
    const teamMembers = await itemService.getTeamMemberString(
      "parentId",
      String(item.parentId)
    );
    let allMembers = [];
    let options = [];
    let currentAssignee =
      item.accountId !== undefined ? item.assigneeItem : null;
    if (teamMembers) {
      allMembers.push(
        ...teamMembers.map((t) => ({
          id: `${t.accountId}-${t.id}`,
          label: `${t.user ? t.user.username : ""}(${
            t.roleTypeNavigation ? t.roleTypeNavigation.typeName : ""
          })${t.leaveDate ? " (已退出)" : ""}`,
          color: t.leaveDate ? "bg-gray-200 text-gray-800" : null,
          description: t.roleTypeNavigation
            ? `${t.roleTypeNavigation.description}`
            : "",
          accountId: t.accountId,
          assigneeId: t.id,
          leaveDate: t.leaveDate,
          data: t,
        }))
      );
    }
    options = allMembers.filter((member) => !member.leaveDate);
    const findAssignee = allMembers.find(
      (member) =>
        member.assigneeId === item.assigneeId &&
        member.accountId === item.accountId
    );
    if (findAssignee) currentAssignee = findAssignee;
    return { options, currentAssignee };
  }

  static async getTaskActionAssigneeData(item) {
    const relations = await itemService.getTaskActionString(
      "teammember",
      String(item.parentId)
    );
    let allMembers = [];
    let options = [];
    let currentAssignee =
      item.accountId !== undefined ? item.assigneeItem : null;
    const localUser = getUser();
    if (Array.isArray(relations) && relations.length > 0) {
      const data = relations[0];
      const teamMembers = data.teamMembers;

      if (teamMembers) {
        allMembers = teamMembers.map((t) => ({
          id: `${t.accountId}-${t.id}`,
          label: `${t.user ? t.user.username : ""}(${
            t.roleTypeNavigation ? t.roleTypeNavigation.typeName : ""
          })${t.leaveDate ? " (已退出)" : ""}`,
          color: t.leaveDate ? "bg-gray-200 text-gray-800" : null,
          description: t.roleTypeNavigation
            ? `${t.roleTypeNavigation.description}`
            : "",
          accountId: t.accountId,
          assigneeId: t.id,
          leaveDate: t.leaveDate,
          data: t,
        }));

        // 篩選 options
        options = allMembers.filter(
          (member) =>
            member.assigneeId === data.assigneeId ||
            (member.data.roleTypeNavigation &&
              ["PM", "CSM"].includes(member.data.roleTypeNavigation.typeName) &&
              localUser.userId === member.accountId)
        );
      }

      const findAssignee = allMembers.find(
        (member) =>
          member.assigneeId === item.assigneeId &&
          member.accountId === item.accountId
      );
      if (findAssignee) currentAssignee = findAssignee;
    }
    return { options, currentAssignee };
  }
}

export default AssigneeService;
