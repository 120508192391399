import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import { differenceInDays, isBefore } from "date-fns";
import {
  Card,
  CardContent,
  Button,
  Select,
  MenuItem,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ItemEditModal from "../common/ItemEditModal";
import { formatDate } from "../../utils/dateUtils";
import { getCostColor, priorityOrder, getColorStyles,formatNumber } from "../../utils/utils";
import { getUser } from "../../services/authService";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import itemService from "../../services/db/ItemService";
const StyledChip = styled(Chip)(({ colorstyle }) => ({
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));

const FilterContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "nowrap",
  gap: "10px",
  marginBottom: "20px",
});
const shallowEqualMissions = (arr1, arr2) => {
  if (arr1 === arr2) return true;
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id !== arr2[i].id || arr1[i].updatedAt !== arr2[i].updatedAt|| arr1[i].actionCount !== arr2[i].actionCount) {
      return false;
    }
  }
  return true;
};
const MissionsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  padding: "1rem",
  overflow: "auto",
});

const GroupWrapper = styled("div")({
  marginBottom: "2rem",
});

const GroupContainer = styled("div")(({ maxHeight }) => ({
  width: "100%",
  maxHeight: maxHeight,
  overflow: "auto",
  marginTop: "0.5rem",
}));

const GroupHeader = styled(Typography)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "0.5rem",
});

const CardsContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  width: "100%",
});

const MissionCard = styled(Card)(({ theme }) => ({
  width: "300px",
  minWidth: "300px",
  height: "auto",
  borderLeft: "8px solid #3f51b5",
  position: "relative",
  overflow: "hidden",
  marginBottom: "1rem",
}));

const TruncatedTypography = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))({
  "& .MuiTooltip-tooltip": {
    fontSize: "1rem",
  },
});

const incrementalUpdate = (currentMissions, newMissions) => {
  const updateMission = (currentMission, newMission) => {
    if ((new Date(newMission.updatedAt) > new Date(currentMission.updatedAt)) || (newMission.actionCount!==currentMission.actionCount) ) {
      return {
        ...currentMission,
        ...newMission,
        // Preserve any fields that might be present in currentMission but not in newMission
        milestoneName: newMission.milestoneName || currentMission.milestoneName,
        projectName: newMission.projectName || currentMission.projectName,
        actionCount: newMission.actionCount || currentMission.actionCount,
        userName: newMission.userName || currentMission.userName,
        preVersion: newMission.preVersion || currentMission.preVersion,
        typeItem: newMission.typeItem || currentMission.typeItem,
        statusItem: newMission.statusItem || currentMission.statusItem,
        priorityItem: newMission.priorityItem || currentMission.priorityItem,
        assigneeItem: newMission.assigneeItem || currentMission.assigneeItem,
      };
    }
    return currentMission;
  };

  const newMissionMap = new Map(
    newMissions.map((mission) => [mission.id, mission])
  );

  const updatedMissions = currentMissions.map((currentMission) => {
    const newMission = newMissionMap.get(currentMission.id);
    if (newMission) {
      return updateMission(currentMission, newMission);
    }
    return currentMission;
  });

  // Add any new missions that weren't in the current set
  newMissions.forEach((newMission) => {
    if (!currentMissions.some((mission) => mission.id === newMission.id)) {
      updatedMissions.push(newMission);
    }
  });

  return updatedMissions;
};
const CUSTOM_EVENT_NAME = "dataUpdated";
const Missions = () => {
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cardRefs = useRef({});
  const groupRefs = useRef({});
  const currentUser = useMemo(() => getUser(), []);

  const [selectedUsers, setSelectedUsers] = useState([currentUser.username]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [statusOption, setStatusOption] = useState([]);
  const [showGroups, setShowGroups] = useState({
    已逾期: true,
    即將到期: true,
    進行中: true,
    即將開始: true,
    尚未開始: true,
  });

  const [upcomingDays, setUpcomingDays] = useState({
    即將到期: 7,
    即將開始: 7,
  });

  const [sortDirections, setSortDirections] = useState({
    已逾期: "asc",
    即將到期: "asc",
    進行中: "asc",
    即將開始: "asc",
    尚未開始: "asc",
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedMission, setSelectedMission] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    types: [],
    statuses: [],
  });

  const fetchNewData = useCallback(async () => {
    try {
      const [
        missions,
        milestones,
        projects,
        taskActions,
        users,
        types,
        statusOptions,
      ] = await Promise.all([
        itemService.fetch("Mission"),
        itemService.fetch("Milestone"),
        itemService.fetch("Project"),
        itemService.fetch("TaskAction"),
        itemService.fetch("User"),
        itemService.getTypes("typeCategory", "Mission"),
        itemService.getStatus("appliesTo", "Mission"),
      ]);
      setStatusOption(statusOptions);
      setSelectedStatuses(
        statusOptions
          .filter(
            (option) => !["籌備", "取消", "結案"].includes(option.statusName)
          )
          .map((option) => option.statusName)
      );

      const processedMissions = missions.map((mission) => {
        const milestone = milestones.find((m) => m.id === mission.parentId);
        const project = projects.find((p) => p.id === milestone?.parentId);
        const actions = taskActions.filter((ta) => ta.parentId === mission.id);
        const user = users.find((u) => u.accountId === mission.accountId);
        const typeItem = types.find((t) => t.typeId === mission.typeId);
        const statusItem = statusOptions.find(
          (s) => s.statusId === mission.statusId
        );
        const priorityItem = priorityOrder.find(
          (p) => p.id === mission.priority
        );

        return {
          ...mission,
          milestoneName: milestone?.name,
          projectName: project?.name,
          actionCount: actions.length,
          userName: user?.username,
          preVersion: `v${milestone?.version}.`,
          typeItem,
          statusItem,
          priorityItem,
          assigneeItem: user,
        };
      });
      return processedMissions;
    } catch (err) {
      console.error("加載數據時出錯:", err);
      setError("加載數據失敗。請嘗試刷新頁面。");
      return null;
    }
  }, [setError, setStatusOption]); // 添加所有使用到的函數和 state 設置器

  const loadMissions = useCallback(async () => {
    setLoading(true);
    try {
      const processedMissions = await fetchNewData();
      setMissions((prevMissions) => {
        const updatedMissions = incrementalUpdate(
          prevMissions,
          processedMissions
        );
        return shallowEqualMissions(prevMissions, updatedMissions)
          ? prevMissions
          : updatedMissions;
      });
      setError(null);
    } catch (err) {
      console.error("加載數據時出錯:", err);
      setError("加載數據失敗。請嘗試刷新頁面。");
    } finally {
      setLoading(false);
    }
  }, [fetchNewData, setError]);


  const updateDataSmooth = useCallback(async () => {
    const newData = await fetchNewData();
    if (newData) {
      setMissions((prevMissions) => {
        const updatedMissions = incrementalUpdate(prevMissions, newData);
        return shallowEqualMissions(prevMissions, updatedMissions)
          ? prevMissions
          : updatedMissions;
      });
      // 確保所有狀態更新後重新篩選
      await Promise.all([
        setFilters((prevFilters) => ({ ...prevFilters })),
        // 其他需要更新的狀態
      ]);
    }
  },[fetchNewData, setMissions]);

  useEffect(() => {
    loadMissions();
    const handleDataUpdated = () => {
      updateDataSmooth();
    };
    window.addEventListener(CUSTOM_EVENT_NAME, handleDataUpdated);
  }, [loadMissions,updateDataSmooth]);

  const groupMissions = useMemo(() => {
    const filteredMissions = missions.filter(
      (mission) =>
        selectedStatuses.includes(mission.statusItem?.statusName) &&
        selectedUsers.includes(mission.userName) &&
        mission.name.toLowerCase().includes(filters.name.toLowerCase()) &&
        (filters.types.length === 0 ||
          filters.types.some((type) => type.typeId === mission.typeId)) &&
        (filters.statuses.length === 0 ||
          filters.statuses.some(
            (status) => status.statusId === mission.statusId
          ))
    );

    const groups = {
      已逾期: [],
      即將到期: [],
      進行中: [],
      即將開始: [],
      尚未開始: [],
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    filteredMissions.forEach((mission) => {
      const startDate = new Date(mission.startDate);
      const endDate = mission.endDate ? new Date(mission.endDate) : null;
      const daysToStart = differenceInDays(startDate, today);
      const daysToEnd = endDate ? differenceInDays(endDate, today) : null;

      if (endDate && isBefore(endDate, today)) {
        groups["已逾期"].push(mission);
      } else if (!endDate && isBefore(startDate, today)) {
        groups["進行中"].push(mission);
      } else if (
        isBefore(startDate, today) &&
        daysToEnd <= upcomingDays["即將到期"]
      ) {
        groups["即將到期"].push(mission);
      } else if (isBefore(startDate, today)) {
        groups["進行中"].push(mission);
      } else if (daysToStart <= upcomingDays["即將開始"]) {
        groups["即將開始"].push(mission);
      } else {
        groups["尚未開始"].push(mission);
      }
    });

    Object.keys(groups).forEach((key) => {
      groups[key].sort((a, b) => {
        const dateA = new Date(
          key === "即將開始" || key === "尚未開始" ? a.startDate : a.endDate
        );
        const dateB = new Date(
          key === "即將開始" || key === "尚未開始" ? b.startDate : b.endDate
        );
        return sortDirections[key] === "asc" ? dateA - dateB : dateB - dateA;
      });
    });

    return groups;
  }, [
    missions,
    selectedStatuses,
    selectedUsers,
    upcomingDays,
    sortDirections,
    filters,
  ]);

  const updateGroupHeights = useCallback(() => {
    Object.entries(groupRefs.current).forEach(([key, groupElement]) => {
      if (groupElement) {
        const missions = groupMissions[key] || [];
        const cardHeights = missions.map(
          (mission) => cardRefs.current[mission.id]?.offsetHeight || 0
        );
        const maxCardHeight = Math.max(...cardHeights, 0);
        const contentHeight = groupElement.scrollHeight;
        const newHeight = Math.min(
          maxCardHeight * 2,
          Math.max(contentHeight, maxCardHeight)
        );
        groupElement.style.maxHeight = `${newHeight}px`;
      }
    });
  }, [groupMissions]);

  useEffect(() => {
    // 使用 setTimeout 來確保在下一個事件循環中執行，給予 DOM 足夠的時間進行更新
    const timer = setTimeout(() => {
      updateGroupHeights();
    }, 0);

    return () => clearTimeout(timer);
  }, [groupMissions, updateGroupHeights]);

  // 初始加載和窗口大小變化時更新高度
  useLayoutEffect(() => {
    updateGroupHeights();
    window.addEventListener("resize", updateGroupHeights);
    return () => window.removeEventListener("resize", updateGroupHeights);
  }, [updateGroupHeights]);

  const handleUpcomingDaysChange = useCallback((groupKey, value) => {
    const days = parseInt(value, 10);
    if (!isNaN(days) && days > 0) {
      setUpcomingDays((prev) => ({ ...prev, [groupKey]: days }));
    }
  }, []);

  const toggleSortDirection = useCallback((key) => {
    setSortDirections((prev) => ({
      ...prev,
      [key]: prev[key] === "asc" ? "desc" : "asc",
    }));
  }, []);

  const handleOpenMenu = useCallback((event, mission) => {
    setSelectedMission(mission);
    setIsEditModalOpen(true);
  }, []);


  const handleSave = useCallback(async () => {
    try {
      setIsEditModalOpen(false);
      setSelectedMission(null);
      await updateDataSmooth();
    } catch (error) {
      console.error("更新失敗:", error);
      setError("數據更新錯誤。請稍後再試。");
    }
  }, [updateDataSmooth, setError]);

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
    setSelectedMission(null);
  }, []);

  const renderMissionCard = useCallback(
    (mission) => {
      const daysToStart = differenceInDays(
        new Date(mission.startDate),
        new Date()
      );
      const daysToEnd = mission.endDate
        ? differenceInDays(new Date(mission.endDate), new Date())
        : null;
      const isStarted = daysToStart <= 0;
      const urgencyColor = (() => {
        const days = isStarted ? daysToEnd : daysToStart;
        if (days < 0) return "error";
        if (days <= 3) return "warning";
        if (days <= 7) return "info";
        return "success";
      })();

      return (
        <MissionCard
          key={mission.id}
          variant="outlined"
          ref={(el) => (cardRefs.current[mission.id] = el)}
        >
          <CardContent>
            <StyledTooltip
              title={`${mission.projectName} - ${mission.milestoneName}`}
              placement="bottom-start"
            >
              <TruncatedTypography variant="body2" color="text.secondary">
                {mission.projectName} - {mission.milestoneName}
              </TruncatedTypography>
            </StyledTooltip>
            <StyledTooltip title={mission.name} placement="bottom-start">
              <TruncatedTypography variant="h6" component="div">
                {mission.name}
              </TruncatedTypography>
            </StyledTooltip>
            <Typography variant="body2">
              {formatDate(mission.startDate)} ~{" "}
              {mission.endDate ? formatDate(mission.endDate) : ""}
            </Typography>
            <Typography
              variant="body2"
              color={getCostColor(mission.cost, mission.budget)}
            >
              動作數: {mission.actionCount} | 花費/預算: {formatNumber(mission.cost)}/
              {formatNumber(mission.budget)}
            </Typography>
            <Typography color={urgencyColor}>
              {isStarted
                ? daysToEnd === null
                  ? ""
                  : daysToEnd < 0
                  ? `逾期 ${Math.abs(daysToEnd)} 天`
                  : `剩餘 ${daysToEnd} 天`
                : `${daysToStart} 天後開始`}
            </Typography>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
                marginTop: "0.5rem",
              }}
            >
              <StyledChip
                label={`v${mission.version}`}
                size="small"
                colorstyle={getColorStyles("bg-blue-200 text-blue-800")}
              />
              <StyledChip
                label={mission.priorityItem?.label || "無"}
                size="small"
                colorstyle={getColorStyles(
                  mission.priorityItem?.color || "bg-green-200 text-green-800"
                )}
              />
              <StyledChip
                label={mission.statusItem?.statusName}
                size="small"
                colorstyle={getColorStyles(mission.statusItem?.color)}
              />
            </div>
            <Typography variant="body2" sx={{ marginTop: "0.5rem" }}>
              負責人: {mission.userName || "N/A"}
            </Typography>
            <IconButton
              onClick={(event) => handleOpenMenu(event, mission)}
              size="small"
              sx={{ position: "absolute", top: 5, right: 5 }}
            >
              <MoreVertIcon />
            </IconButton>
          </CardContent>
        </MissionCard>
      );
    },
    [handleOpenMenu]
  );

  const sortMissions = useCallback(
    (missions, key) => {
      return [...missions].sort((a, b) => {
        const dateA = new Date(
          key === "即將開始" || key === "尚未開始"
            ? a.startDate
            : a.endDate || a.startDate
        );
        const dateB = new Date(
          key === "即將開始" || key === "尚未開始"
            ? b.startDate
            : b.endDate || b.startDate
        );
        return sortDirections[key] === "asc" ? dateA - dateB : dateB - dateA;
      });
    },
    [sortDirections]
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  useEffect(() => {
    setFilters((prevFilters) => ({ ...prevFilters }));
  }, []);
  if (loading)
    return (
      <Typography variant="h6" align="center">
        載入中...
      </Typography>
    );
  if (error)
    return (
      <Typography variant="h6" align="center" color="error">
        錯誤: {error}
      </Typography>
    );

  return (
    <MissionsContainer>
      <Typography variant="h4" gutterBottom>
        任務管理
      </Typography>
      <FilterContainer>
        <TextField
          label="名稱篩選"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
          variant="outlined"
          size="small"
          style={{ flexGrow: 1 }}
        />
        <Autocomplete
          multiple
          options={statusOption}
          getOptionLabel={(option) => option?.statusName}
          value={selectedStatuses.map((status) =>
            statusOption.find((option) => option?.statusName === status)
          )}
          onChange={(_, newValue) =>
            setSelectedStatuses(newValue.map((option) => option?.statusName))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="狀態"
              variant="outlined"
              size="small"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledChip
                label={option.statusName}
                size="small"
                colorstyle={getColorStyles(option.color)}
                style={{ margin: "2px" }}
              />
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <StyledChip
                variant="outlined"
                label={option?.statusName}
                size="small"
                colorstyle={getColorStyles(option.color)}
                {...getTagProps({ index })}
              />
            ))
          }
          style={{ minWidth: 200 }}
        />
        <Autocomplete
          multiple
          options={[
            ...new Set(missions.map((m) => m?.userName).filter(Boolean)),
          ]}
          value={selectedUsers}
          onChange={(_, newValue) => setSelectedUsers(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="負責人"
              variant="outlined"
              size="small"
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          style={{ minWidth: 200 }}
        />
      </FilterContainer>

      <div style={{ marginBottom: "1rem" }}>
        <Typography variant="h6" gutterBottom>
          顯示分組
        </Typography>
        {Object.entries(showGroups).map(([key, value]) => (
          <Button
            key={key}
            variant={value ? "contained" : "outlined"}
            color="primary"
            onClick={() =>
              setShowGroups((prev) => ({ ...prev, [key]: !prev[key] }))
            }
            sx={{ marginRight: 0.5, marginBottom: 0.5 }}
          >
            {key}
          </Button>
        ))}
      </div>

      {Object.entries(groupMissions).map(
        ([key, missions]) =>
          showGroups[key] && (
            <GroupWrapper key={key}>
              <GroupHeader variant="h6">
                <span
                  onClick={() => toggleSortDirection(key)}
                  style={{ cursor: "pointer" }}
                >
                  {key} {sortDirections[key] === "asc" ? "🔼" : "🔽"}
                </span>
                {(key === "即將到期" || key === "即將開始") && (
                  <Select
                    value={upcomingDays[key]}
                    onChange={(e) =>
                      handleUpcomingDaysChange(key, e.target.value)
                    }
                    sx={{ width: 80 }}
                  >
                    {[1, 3, 7, 14, 30].map((days) => (
                      <MenuItem key={days} value={days}>
                        {days} 天
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </GroupHeader>
              <GroupContainer ref={(el) => (groupRefs.current[key] = el)}>
                <CardsContainer>
                  {sortMissions(missions, key).map((mission) =>
                    renderMissionCard(mission)
                  )}
                </CardsContainer>
              </GroupContainer>
            </GroupWrapper>
          )
      )}

      {isEditModalOpen && selectedMission && (
        <ItemEditModal
          currentItem={selectedMission}
          ItemEditComponent="Mission"
          readOnly={false}
          tableType="Mission"
          parentId={selectedMission.parentId}
          setItemList={loadMissions}
          setErrorMessage={setError}
          parentContainerWidth={window.innerWidth}
          onSave={handleSave}
          onClose={handleCloseModal}
        />
      )}
    </MissionsContainer>
  );
};

export default Missions;
