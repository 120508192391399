import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { DateField, DateTimeField } from '@mui/x-date-pickers';
import { StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO, setSeconds, startOfDay } from 'date-fns';
import zhTW from 'date-fns/locale/zh-TW';
import { Popover, Box, IconButton, InputAdornment, Button, Typography, FormControl } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';

const calculateFontSize = (fontSize, height) => {
  if (fontSize) return fontSize;
  if (height) return `${Math.floor(parseInt(height) * 0.5)}px`;
  return '16px';
};

const defaultLabels = {
  now: '現在',
  today: '今天',
  ok: '確定',
  cancel: '取消',
  clear: '清除'
};

const CustomDateTimePicker = ({
  value,
  onChange,
  width,
  height,
  fontSize,
  readOnly,
  includeTime = false,
  label,
  errorMessage,
  labels = {}
}) => {
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [tempDateTime, setTempDateTime] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  const mergedLabels = useMemo(() => ({ ...defaultLabels, ...labels }), [labels]);

  const autoFontSize = useMemo(() => calculateFontSize(fontSize, height), [fontSize, height]);

  const containerStyle = useMemo(() => ({
    width: width || 'auto',
    height: height || 'auto',
  }), [width, height]);

  const inputStyle = useMemo(() => ({
    width: '100%',
    height: '100%',
    '& .MuiInputBase-root': {
      height: '100%',
      fontSize: autoFontSize,
    },
    '& .MuiInputBase-input': {
      height: '100%',
      padding: '0 14px',
      fontSize: 'inherit',
    },
    '& .MuiInputAdornment-root': {
      height: '100%',
    },
    '& .MuiInputAdornment-root .MuiButtonBase-root': {
      fontSize: 'inherit',
      padding: '4px',
      minWidth: 'unset',
    },
    '& .MuiInputAdornment-root .MuiButton-root': {
      fontSize: `calc(${autoFontSize} * 0.8)`,
      padding: '2px 4px',
      minWidth: 'unset',
    },
    '& .MuiInputAdornment-root .MuiIconButton-root': {
      padding: '4px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: `calc(${autoFontSize} * 1.2)`,
    },
  }), [autoFontSize]);

  useEffect(() => {
    if (value) {
      try {
        const parsedDate = parseISO(value);
        if (isNaN(parsedDate.getTime())) throw new Error('Invalid date');
        setSelectedDateTime(parsedDate);
        setTempDateTime(parsedDate);
      } catch (error) {
        console.error('Error parsing initial value:', error);
      }
    } else {
      setSelectedDateTime(null);
      setTempDateTime(null);
    }
  }, [value]);

  const handleDateTimeChange = useCallback((newDateTime, resetSeconds = true) => {
    if (readOnly) return;
    try {
      let adjustedDateTime = newDateTime;
      if (newDateTime && resetSeconds) {
        adjustedDateTime = includeTime ? setSeconds(newDateTime, 0) : startOfDay(newDateTime);
      }
      setTempDateTime(adjustedDateTime);
      setSelectedDateTime(adjustedDateTime);
      onChange?.(adjustedDateTime ? adjustedDateTime.toISOString() : null);
    } catch (error) {
      console.error('Error handling date time change:', error);
    }
  }, [readOnly, includeTime, onChange]);

  const handleStaticPickerChange = useCallback((newDateTime) => {
    if (tempDateTime && newDateTime) {
      if (includeTime) {
        const sameDate = tempDateTime.getDate() === newDateTime.getDate() &&
                         tempDateTime.getMonth() === newDateTime.getMonth() &&
                         tempDateTime.getFullYear() === newDateTime.getFullYear();
        setTempDateTime(sameDate ? setSeconds(newDateTime, 0) : newDateTime);
      } else {
        setTempDateTime(startOfDay(newDateTime));
      }
    } else {
      setTempDateTime(newDateTime);
    }
  }, [tempDateTime, includeTime]);

  const handleAccept = useCallback(() => {
    handleDateTimeChange(tempDateTime, false);
    setAnchorEl(null);
  }, [handleDateTimeChange, tempDateTime]);

  const handleClear = useCallback(() => {
    handleDateTimeChange(null, false);
    setAnchorEl(null);
  }, [handleDateTimeChange]);

  const handleCancel = useCallback(() => {
    setTempDateTime(selectedDateTime);
    setAnchorEl(null);
  }, [selectedDateTime]);

  const handleToday = useCallback(() => {
    const now = includeTime ? new Date() : startOfDay(new Date());
    handleDateTimeChange(now, false);
    setAnchorEl(null);
  }, [includeTime, handleDateTimeChange]);

  const handleIconClick = useCallback((event) => {
    if (!readOnly) {
      setAnchorEl(event.currentTarget);
      setTempDateTime(selectedDateTime);
    }
  }, [readOnly, selectedDateTime]);

  const handleNowClick = useCallback(() => {
    if (!readOnly) {
      const now = includeTime ? new Date() : startOfDay(new Date());
      handleDateTimeChange(now, false);
    }
  }, [readOnly, includeTime, handleDateTimeChange]);

  const CustomActionBar = useCallback(({ onAccept, onClear, onCancel, onSetToday }) => (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      padding: '12px',
      borderLeft: '1px solid #e0e0e0',
      height: '100%',
      justifyContent: 'space-between',
    }}>
      <Button 
        onClick={onAccept} 
        sx={{ mb: 2, fontSize: `calc(${autoFontSize})` }}
        variant="contained"
      >
        {mergedLabels.ok}
      </Button>
      <Button 
        onClick={onCancel} 
        sx={{ mb: 10, fontSize: `calc(${autoFontSize})` }}
        variant="outlined"
      >
        {mergedLabels.cancel}
      </Button>
      <Button 
        onClick={onSetToday} 
        sx={{ 
          mb: 2,
          fontSize: `calc(${autoFontSize})`,
          color: 'warning.main',
          borderColor: 'warning.main',
          '&:hover': {
            backgroundColor: 'warning.light',
            color: 'warning.contrastText',
          },
        }}
      >
        {includeTime ? mergedLabels.now : mergedLabels.today}
      </Button>
      <Button 
        onClick={onClear} 
        sx={{ 
          mb: 2,
          fontSize: `calc(${autoFontSize})`,
          color: 'error.main',
          borderColor: 'error.main',
          '&:hover': {
            backgroundColor: 'error.light',
            color: 'error.contrastText',
          },
        }}
        variant="outlined"
      >
        {mergedLabels.clear}
      </Button>
    </Box>
  ), [autoFontSize, includeTime, mergedLabels]);

  const FieldComponent = includeTime ? DateTimeField : DateField;
  const StaticPickerComponent = includeTime ? StaticDateTimePicker : StaticDatePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhTW}>
      <FormControl fullWidth variant="outlined" style={containerStyle}>
        <FieldComponent
          inputRef={inputRef}
          value={selectedDateTime}
          onChange={(newDateTime) => handleDateTimeChange(newDateTime, true)}
          format={includeTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
          ampm={false}
          readOnly={readOnly}
          InputProps={{
            ...(readOnly ? {} : {
              startAdornment: (
                <InputAdornment position="start">
                  <Button onClick={handleNowClick} size="small" style={{ fontSize: `calc(${autoFontSize} * 0.8)` }}>
                    {includeTime ? mergedLabels.now : mergedLabels.today}
                  </Button>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleIconClick} size="small">
                    <CalendarToday style={{ fontSize: `calc(${autoFontSize} * 1.2)` }} />
                  </IconButton>
                </InputAdornment>
              ),
            }),
            notched: Boolean(label),
          }}
          sx={{
            ...inputStyle,
           
          }}
          label={label}
        />
      </FormControl>
      {!readOnly && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCancel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <StaticPickerComponent
              autoFocus={true}
              orientation="portrait"
              value={tempDateTime}
              onChange={handleStaticPickerChange}
              minutesStep={1}
              ampm={false}
              fixedWeekNumber={6}
              showDaysOutsideCurrentMonth={true}
              slots={{
                actionBar: () => null,
              }}
              views={includeTime ? ['year', 'month', 'day', 'hours', 'minutes'] : ['year', 'month', 'day']}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: autoFontSize,
                },
                '& .MuiPickersDay-root': {
                  fontSize: `calc(${autoFontSize} * 0.9)`,
                },
                '& .MuiClock-pin, & .MuiClockPointer-root': {
                  backgroundColor: 'primary.main',
                },
                '& .MuiClockPointer-thumb': {
                  border: '16px solid',
                  borderColor: 'primary.main',
                },
              }}
              localeText={{
                toolbarTitle: '',
              }}
            />
            <CustomActionBar
              onAccept={handleAccept}
              onClear={handleClear}
              onCancel={handleCancel}
              onSetToday={handleToday}
            />
          </Box>
        </Popover>
      )}
      {errorMessage && <Typography color="error" fontSize={`calc(${autoFontSize} * 0.8)`}>{errorMessage}</Typography>}
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;