import React, { useState, useMemo, useEffect, useCallback } from 'react';
import itemService from "../../../services/db/ItemService";

const UserList = ({ users, companyRelations, suppliers, friends, onAddMember, setIsDragging, milestoneId }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [collapsedGroups, setCollapsedGroups] = useState({});
    const [loading, setLoading] = useState(false);
    const [emailSearchResult, setEmailSearchResult] = useState(null);

    const groupedUsers = useMemo(() => {
        const filterUsers = (userList) => 
            (userList || []).filter(user => 
                user && (
                    (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
                    (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
                )
            );

        return [
            { title: "快組隊認證", users: filterUsers(suppliers) },
            { title: "公司同事", users: filterUsers(companyRelations) },        
            { title: "朋友", users: filterUsers(friends) },
            { title: "合作過的戰友", users: filterUsers(users) }
        ];
    }, [users, companyRelations, suppliers, friends, searchTerm]);

    useEffect(() => {
        const handleEmailSearch = async () => {
            setLoading(true);
            try {
                const result = await itemService.getUserByEmail(searchTerm);
                const data = Array.isArray(result) ? result[0] : result;
                if (data && data.accountId) {
                    setEmailSearchResult({
                        accountId: data.accountId,
                        username: data.username || 'No Name',
                        email: data.email || 'No Email',
                        pictureUrl: data.pictureUrl,
                    });
                } else {
                    setEmailSearchResult(null);
                }
            } catch (error) {
                console.error("搜尋帳號時發生錯誤:", error);
                setEmailSearchResult(null);
            } finally {
                setLoading(false);
            }
        };

        const allUsers = [...suppliers, ...companyRelations, ...friends, ...users];
        const hasMatchingUsers = allUsers.some(user => 
            (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        );

        if (!hasMatchingUsers && searchTerm) {
            handleEmailSearch();
        } else {
            setEmailSearchResult(null);
        }
    }, [searchTerm, suppliers, companyRelations, friends, users]);

    const toggleGroup = useCallback((title) => {
        setCollapsedGroups(prev => ({ ...prev, [title]: !prev[title] }));
    }, []);

    const renderUserGroup = useCallback(({ title, users }) => {
        if (users.length === 0) return null;

        return (
            <div key={title} className="mb-4">
                <div 
                    className="flex justify-between items-center cursor-pointer bg-gray-100 p-2 rounded-md"
                    onClick={() => toggleGroup(title)}
                >
                    <h3 className="text-lg font-semibold">{title}</h3>
                    <div className="flex items-center">
                        <span className="mr-2 text-sm text-gray-600">{users.length}</span>
                        <svg 
                            className={`w-4 h-4 transition-transform ${collapsedGroups[title] ? 'transform rotate-180' : ''}`}
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24" 
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </div>
                </div>
                {!collapsedGroups[title] && (
                    <div className="mt-2 space-y-2">
                        {users.map((user) => (
                            <div
                                key={user.accountId || `user-${Math.random()}`}
                                draggable
                                onDragStart={(e) => {
                                    const userData = JSON.stringify(user, milestoneId);         
                                    e.dataTransfer.setData('application/json', userData);
                                    setIsDragging(true);
                                }}
                                onDragEnd={() => setIsDragging(false)}
                                className="bg-gray-50 p-2 rounded-md flex items-center cursor-move shadow-sm hover:shadow-md transition-shadow duration-200"
                            >
                                {user.pictureUrl && (
                                    <img src={user.pictureUrl} alt={user.username} className="w-10 h-10 rounded-full mr-2 border border-gray-200" />
                                )}
                                <div className="flex-grow">
                                    <div className="font-medium text-sm">{user.username || 'No Name'}</div>
                                    <div className="text-xs text-gray-500">{user.email || 'No Email'}</div>
                                </div>
                                <button
                                    onClick={() => {     
                                        onAddMember({
                                            id: `temp_${Date.now()}`,
                                            accountId: user.accountId,
                                            username: user.username || 'No Name',
                                            email: user.email || 'No Email',
                                            pictureUrl: user.pictureUrl,
                                            roleType: null,
                                            hourlyRate: 1,
                                            joinDate: new Date().toISOString().split('T')[0],
                                            leaveDate: null,
                                            isTemporary: true,
                                            milestoneId: milestoneId,
                                            tableType: "TeamMember" 
                                        });
                                    }}
                                    className="bg-indigo-500 text-white px-2 py-1 rounded-md hover:bg-indigo-600 transition-colors duration-200 text-xs"
                                >
                                    加入
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }, [collapsedGroups, toggleGroup, onAddMember, milestoneId, setIsDragging]);

    const renderEmailSearchResult = useCallback(() => {
        if (!emailSearchResult) return null;

        return (
            <div className="mt-2 bg-gray-50 p-2 rounded-md flex items-center shadow-sm">
                {emailSearchResult.pictureUrl && (
                    <img src={emailSearchResult.pictureUrl} alt={emailSearchResult.username} className="w-10 h-10 rounded-full mr-2 border border-gray-200" />
                )}
                <div className="flex-grow">
                    <div className="font-medium text-sm">{emailSearchResult.username}</div>
                    <div className="text-xs text-gray-500">{emailSearchResult.email}</div>
                </div>
                <button
                    onClick={() => {     
                        onAddMember({
                            id: `temp_${Date.now()}`,
                            ...emailSearchResult,
                            roleType: null,
                            hourlyRate: 1,
                            joinDate: new Date().toISOString().split('T')[0],
                            leaveDate: null,
                            isTemporary: true,
                            milestoneId: milestoneId,
                            tableType: "TeamMember" 
                        });
                    }}
                    className="bg-indigo-500 text-white px-2 py-1 rounded-md hover:bg-indigo-600 transition-colors duration-200 text-xs"
                >
                    加入
                </button>
            </div>
        );
    }, [emailSearchResult, onAddMember, milestoneId]);

    return (
        <div className="w-1/2 p-4 flex flex-col border-r">
            <input
                type="text"
                placeholder="搜尋用戶..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 mb-3 border rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
            {loading && <div className="text-center">正在搜尋...</div>}
            {!loading && emailSearchResult === null && groupedUsers.every(group => group.users.length === 0) && searchTerm && (
                <div className="text-center text-red-500">沒有找到符合的用戶</div>
            )}
            {renderEmailSearchResult()}
            <div className="flex-grow overflow-y-auto space-y-2">
                {groupedUsers.map(renderUserGroup)}
            </div>
        </div>
    );
};

export default UserList;