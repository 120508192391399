import { useState, useEffect } from 'react';
import { AssigneeService } from './AssigneeService';

export const useAssigneeData = (itemType, item, currentAssigneeId) => {
    const [state, setState] = useState({
      assigneeOptions: [],
      currentAssignee: null,
      loading: true,
      error: null
    });
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { options, currentAssignee } = await AssigneeService.getAssigneeData(itemType, item, currentAssigneeId);
          
          setState({
            assigneeOptions: options,
            currentAssignee,
            loading: false,
            error: null
          });
        } catch (error) {
          setState(prev => ({ ...prev, loading: false, error: error.message }));
        }
      };
  
      fetchData();
    }, [itemType, item, currentAssigneeId]);
  
    return state;
  };