import React, { useMemo } from 'react';
import DropdownSelector from './DropdownSelector';

const priorityOptions = [
  { id: 1, label: "無", color: "bg-green-200 text-green-800" },
  { id: 2, label: "低", color: "bg-blue-200 text-blue-800" },
  { id: 3, label: "一般", color: "bg-yellow-200 text-yellow-800" },
  { id: 4, label: "高", color: "bg-orange-200 text-orange-800" },
  { id: 5, label: "緊急", color: "bg-red-200 text-red-800" },
];

const PrioritySelector = ({ currentPriorityId, onPriorityChange, readOnly ,height,label}) => {
  const mappedPriorityOptions = useMemo(() => 
    priorityOptions.map(priority => ({
      id: priority.id,
      label: priority.label,
      description: '',
      color: priority.color,
      abc : `541651465${label}`,
      cdf : {priority}
    })),
    []
  );

  const currentPriority = useMemo(() => 
    mappedPriorityOptions.find(priority => priority.id === currentPriorityId) || null,
    [currentPriorityId, mappedPriorityOptions]
  );

  const renderReadOnlyPriority = () => {
    if (!currentPriority) {
      return <span className="text-gray-500">未設置優先順序</span>;
    }
    return (
      <span className={`px-2 py-1 rounded-full text-xs ${currentPriority.color}`}>
        {currentPriority.label}
      </span>
    );
  };

  if (readOnly) {
    return renderReadOnlyPriority();
  }

  return (
    <DropdownSelector
      currentValue={currentPriority}
      onValueChange={onPriorityChange}
      readOnly={readOnly}
      allowedOptions={mappedPriorityOptions}
      allOptions={mappedPriorityOptions}
      placeholder="選擇優先順序"
      showDescription={false}
      inputHeight = {height}
      label = {label}
    />
  );
};

export default PrioritySelector;