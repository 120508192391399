import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Paper,
  Chip,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import itemService from "../../services/db/ItemService";
import { formatDate } from "../../utils/dateUtils";
import {
  getColorStyles,
  getCostColor,
  priorityOrder,
  formatNumber,
} from "../../utils/utils";
import DropdownMenu from "../common/DropdownMenu_mui";
import ItemEditModal from "../common/ItemEditModal";
import { getUser} from "../../services/authService";
import { accessCheck } from "../common/Access";
const statusOrder = [
  "籌備",
  "待執行",
  "進行中",
  "完成",
  "待結案",
  "結案",
  "取消",
];

const sortByStatusOrder = (a, b) => {
  return statusOrder.indexOf(a.statusName) - statusOrder.indexOf(b.statusName);
};
const TruncatedTypography = ({ children, ...props }) => (
  <Typography
    {...props}
    sx={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ...props.sx,
    }}
  >
    {children}
  </Typography>
);

const StyledChip = ({ label, colorstyle, ...props }) => (
  <Chip
    label={label}
    {...props}
    sx={{
      backgroundColor: colorstyle.backgroundColor,
      color: colorstyle.color,
      ...props.sx,
    }}
  />
);

const JiraStyleKanban = ({ projectId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newItemTitle, setNewItemTitle] = useState("");
  const [addingToColumn, setAddingToColumn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [selectedMilestones, setSelectedMilestones] = useState([]);
  const [milestoneMap, setMilestoneMap] = useState({});
  const [milestoneStatuses, setMilestoneStatuses] = useState([]);
  const [selectedMilestoneStatusIds, setSelectedMilestoneStatusIds] = useState(
    []
  );
  const [allMilestones, setAllMilestones] = useState([]);
  const [columns, setColumns] = useState({});
  const [missionStatuses, setMissionStatuses] = useState([]);
  const [selectedMissionStatuses, setSelectedMissionStatuses] = useState([]);
  const [milestoneItems, setMilestoneItems] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isMilestoneSelectionOpen, setIsMilestoneSelectionOpen] =
    useState(false);
  const parentContainerWidth = window.innerWidth;
  const loadInitialData = useCallback(async () => {
    setLoading(true);
    try {
      const milestoneStatusesData = await itemService.getStatus(
        "appliesTo",
        "Milestone"
      );
      const sortedMilestoneStatuses =
        milestoneStatusesData.sort(sortByStatusOrder);
      setMilestoneStatuses(sortedMilestoneStatuses);

      const initialSelectedStatusIds = milestoneStatusesData
        .filter(
          (status) =>
            status.statusName === "進行中" || status.statusName === "待執行"
        )
        .map((status) => status.statusId);
      setSelectedMilestoneStatusIds(initialSelectedStatusIds);

      const milestonesData = await itemService.getMilestoneString(
        "kanbanOptions",
        ""
      );
      const sortedMilestones = milestonesData.sort((a, b) => {
        // 首先按 parent.parentId 排序
        if (a.parent.parentId !== b.parent.parentId) {
          // 使用通用的比較邏輯，適用於字符串、數字等類型
          return a.parent.parentId < b.parent.parentId ? -1 : 1;
        }
        // 如果 parent.parentId 相同，則按 parent.name 排序
        return (a.parent.name || "").localeCompare(b.parent.name || "");
      });
      setAllMilestones(sortedMilestones);

      const map = milestonesData.reduce((acc, milestone) => {
        if (milestone && milestone.id) {
          acc[milestone.id] = milestone;
        }
        return acc;
      }, {});
      setMilestoneMap(map);

      const filteredMilestones = milestonesData.filter((milestone) =>
        initialSelectedStatusIds.includes(milestone.statusId)
      );
      setMilestones(filteredMilestones);

      const statuses = await itemService.getStatus("appliesTo", "Mission");
      const sortedMissionStatuses = statuses.sort(sortByStatusOrder);
      setMissionStatuses(sortedMissionStatuses);
      setSelectedMissionStatuses(
        sortedMissionStatuses.map((status) => status.statusId)
      );
      const initialColumns = statuses.reduce((acc, status) => {
        acc[status.statusName] = [];
        return acc;
      }, {});
      setColumns(initialColumns);
    } catch (err) {
      console.error("載入初始數據時出錯:", err);
      setError("載入初始數據失敗。請重試。");
    } finally {
      setLoading(false);
    }
  }, []);

  const loadItemsForMilestone = useCallback(async (milestone) => {
    try {
      const items = await itemService.getMissionString(
        "parentIdDetail",
        String(milestone.id)
      );
      if (!Array.isArray(items)) {
        console.error("Expected array of items, but received:", items);
        return [];
      }
      return items.map((mission) => ({
        ...mission,
        priorityItem: priorityOrder.find((p) => p.id === mission.priority),
        versionString: `v${milestone.version || "0"}.${mission.version || "0"}`,
        parentName:
          milestone.parent && milestone.parent.name
            ? `${milestone.parent.name} > ${milestone.name || ""}`
            : milestone.name || "未知里程碑",
      }));
    } catch (err) {
      console.error(`載入里程碑 ${milestone.id} 的任務數據時出錯:`, err);
      return [];
    }
  }, []);

  const handleMilestoneToggle = useCallback(
    async (milestoneId) => {
      setSelectedMilestones((prev) => {
        if (prev.includes(milestoneId)) {
          setMilestoneItems((prevItems) => {
            const newItems = { ...prevItems };
            delete newItems[milestoneId];
            return newItems;
          });
          return prev.filter((id) => id !== milestoneId);
        } else {
          const milestone = milestoneMap[milestoneId];
          if (!milestone) {
            console.error("Milestone not found in milestoneMap:", milestoneId);
            return prev;
          }
          loadItemsForMilestone(milestone).then((items) => {
            setMilestoneItems((prevItems) => ({
              ...prevItems,
              [milestoneId]: items,
            }));
          });
          return [...prev, milestoneId];
        }
      });
    },
    [loadItemsForMilestone, milestoneMap]
  );

  useEffect(() => {
    const newColumns = {};
    Object.entries(milestoneItems).forEach(([milestoneId, items]) => {
      items.forEach((item) => {
        if (selectedMissionStatuses.includes(item.statusId)) {
          if (!newColumns[item.statusId]) {
            newColumns[item.statusId] = [];
          }
          newColumns[item.statusId].push(item);
        }
      });
    });
    setColumns(newColumns);
  }, [milestoneItems, selectedMissionStatuses]);

  const handleStatusToggle = useCallback((statusId) => {
    setSelectedMissionStatuses((prev) => {
      const newSelectedStatuses = prev.includes(statusId)
        ? prev.filter((id) => id !== statusId)
        : [...prev, statusId];
      return newSelectedStatuses;
    });
  }, []);

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, targetStatusId) => {
    e.preventDefault();
    const itemData = e.dataTransfer.getData("text/plain");
    const item = JSON.parse(itemData);

    if (item.statusId === targetStatusId) return;
    const currentUser = getUser();
    if (item.assignee.accountId !== currentUser.userId) {
      const accessQ = await accessCheck("Milestone", item.parentId);
      if(!accessQ.write) return;
    }
    const updatedItem = {
      ...item,
      statusId: targetStatusId,
      type: null,
      assignee: null,
      priorityItem: null,
      status: null,
    };
    try {
      await itemService.updateItem("Mission", updatedItem);
      const milestone = milestoneMap[updatedItem.parentId];
      loadItemsForMilestone(milestone).then((items) => {
        setMilestoneItems((prevItems) => ({
          ...prevItems,
          [updatedItem.parentId]: items,
        }));
      });
    } catch (error) {
      console.error("Error updating item:", error);
      setError("Failed to update item. Please try again.");
    }
  };

  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setIsEditModalOpen(false);
    setSelectedItem({ ...item, tableType: "Mission" });
  };

  const handleNewAction = (item) => {
    console.log(item);
    const newAction = {
      // ... 新項目的屬性
      parentId: item.id,
      tableType: "TaskAction",
      // 其他必要的屬性
    };
    setSelectedItem(newAction);
    setIsEditModalOpen(true);
    setAnchorEl(null);
  };

  const handleOpenMilestoneSelection = () => {
    if (selectedMilestones.length > 1) {
      setIsMilestoneSelectionOpen(true);
    } else if (selectedMilestones.length === 1) {
      const selectedMilestone = milestoneMap[selectedMilestones[0]];

      handleEditNewItem(selectedMilestone);
    }
  };

  const handleCloseMilestoneSelection = () => {
    setIsMilestoneSelectionOpen(false);
  };

  const handleSelectMilestoneForNewItem = (milestone) => {
    handleCloseMilestoneSelection();
    handleEditNewItem(milestone);
  };

  const handleEditNewItem = (parentItem) => {
    const newMission = {
      // ... 新項目的屬性
      parentId: parentItem.id,
      tableType: "Mission",
      // 其他必要的屬性
    };
    setSelectedItem(newMission);
    setIsEditModalOpen(true);
    setAnchorEl(null);
  };

  const handleSave = useCallback(() => {
    try {
      setIsEditModalOpen(false);
      console.log(selectedItem);
      const milestone = milestoneMap[selectedItem.parentId];
      loadItemsForMilestone(milestone).then((items) => {
        setMilestoneItems((prevItems) => ({
          ...prevItems,
          [selectedItem.parentId]: items,
        }));
      });
      setSelectedItem(null);
    } catch (error) {
      console.error("保存失敗:", error);
      setError("資料錯誤");
    }
  }, [setError, selectedItem, loadItemsForMilestone, milestoneMap]);

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const handleAddItem = async (statusId) => {
    if (!newItemTitle.trim()) return;

    const newItem = {
      name: newItemTitle,
      statusId: statusId,
      itemType: "Mission",
    };

    try {
      const addedItem = await itemService.add("Mission", newItem);
      setColumns((prev) => ({
        ...prev,
        [statusId]: [...prev[statusId], addedItem],
      }));
      setNewItemTitle("");
      setAddingToColumn(null);
    } catch (error) {
      console.error("Error adding new item:", error);
      setError("Failed to add new item. Please try again.");
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditItem = () => {
    setIsEditModalOpen(true);
    setSelectedItem({ ...selectedItem, tableType: "Mission" });
    handleMenuClose();
  };

  const handleDeleteItem = async () => {
    if (!selectedItem) return;

    try {
      await itemService.delete(selectedItem.itemType, selectedItem);
      setColumns((prev) => ({
        ...prev,
        [selectedItem.statusId]: prev[selectedItem.statusId].filter(
          (i) => i.id !== selectedItem.id
        ),
      }));
    } catch (error) {
      console.error("Error deleting item:", error);
      setError("Failed to delete item. Please try again.");
    }
    handleMenuClose();
  };

  const handleMilestoneStatusToggle = (statusId) => {
    setSelectedMilestoneStatusIds((prev) =>
      prev.includes(statusId)
        ? prev.filter((id) => id !== statusId)
        : [...prev, statusId]
    );
  };

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  useEffect(() => {
    const filteredMilestones = allMilestones.filter((milestone) =>
      selectedMilestoneStatusIds.includes(milestone.statusId)
    );
    setMilestones(filteredMilestones);
  }, [selectedMilestoneStatusIds, allMilestones]);

  useEffect(() => {
    const filteredItems = Object.values(milestoneItems)
      .flat()
      .filter((item) => selectedMissionStatuses.includes(item.statusId));

    const newColumns = missionStatuses.reduce((acc, status) => {
      acc[status.statusId] = filteredItems.filter(
        (item) => item.statusId === status.statusId
      );
      return acc;
    }, {});

    setColumns(newColumns);
  }, [milestoneItems, selectedMissionStatuses, missionStatuses]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ backgroundColor: "#f4f5f7", p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "#172b4d" }}>
          選擇里程碑
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {milestoneStatuses.map((status) => {
            const colorStyles = getColorStyles(status?.color);
            const isSelected = selectedMilestoneStatusIds.includes(
              status.statusId
            );
            return (
              <Chip
                key={status.statusId}
                label={status.statusName}
                onClick={() => handleMilestoneStatusToggle(status.statusId)}
                sx={{
                  backgroundColor: isSelected
                    ? colorStyles.backgroundColor
                    : "transparent",
                  color: isSelected ? colorStyles.color : "black",
                  borderColor: colorStyles.color,
                  "&:hover": {
                    backgroundColor: isSelected
                      ? colorStyles.backgroundColor
                      : `${colorStyles.backgroundColor}22`,
                  },
                  transition: "all 0.3s",
                }}
                variant={isSelected ? "filled" : "outlined"}
              />
            );
          })}
        </Box>
      </Box>

      <Box sx={{ mb: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {milestones.map((milestone) => {
          const colorStyles = getColorStyles(milestone.status?.color);
          const isSelected = selectedMilestones.includes(milestone.id);
          return (
            <Tooltip
              title={`${formatDate(milestone.startDate)}~${formatDate(
                milestone.endDate
              )}`}
              key={milestone.id}
            >
              <Chip
                label={`${milestone.parent.name} > ${milestone.name} v${milestone.version}(${milestone.type?.typeName})`}
                onClick={() => handleMilestoneToggle(milestone.id)}
                sx={{
                  backgroundColor: isSelected
                    ? colorStyles.backgroundColor
                    : "transparent",
                  color: "black",
                  borderColor: colorStyles.color,
                  "&:hover": {
                    backgroundColor: isSelected
                      ? colorStyles.backgroundColor
                      : `${colorStyles.backgroundColor}22`,
                  },
                  transition: "all 0.3s",
                }}
                variant={isSelected ? "filled" : "outlined"}
              />
            </Tooltip>
          );
        })}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#172b4d" }}>
          篩選狀態
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {missionStatuses.map((status) => {
            const colorStyles = getColorStyles(status?.color);
            const isSelected = selectedMissionStatuses.includes(
              status.statusId
            );
            return (
              <Chip
                key={status.statusId}
                label={status.statusName}
                onClick={() => handleStatusToggle(status.statusId)}
                sx={{
                  backgroundColor: isSelected
                    ? colorStyles.backgroundColor
                    : "transparent",
                  color: isSelected ? colorStyles.color : "black",
                  borderColor: colorStyles.color,
                  "&:hover": {
                    backgroundColor: isSelected
                      ? colorStyles.backgroundColor
                      : `${colorStyles.backgroundColor}22`,
                  },
                  transition: "all 0.3s",
                }}
                variant={isSelected ? "filled" : "outlined"}
              />
            );
          })}
        </Box>
      </Box>

      {selectedMilestones.length > 0 && (
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
          {missionStatuses.map((status) => {
            if (!selectedMissionStatuses.includes(status.statusId)) return null;
            const items = columns[status.statusId] || [];
            const colorStyles = getColorStyles(status.color);
            return (
              <Paper
                key={status.statusId}
                elevation={3}
                sx={{
                  width: 300,
                  minHeight: 500,
                  p: 2,
                  mr: 2,
                  mb: 2,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, status.statusId)}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: colorStyles.color,
                    backgroundColor: colorStyles.backgroundColor,
                    padding: "5px 10px",
                    borderRadius: "4px",
                  }}
                >
                  {status.statusName} ({items.length})
                </Typography>
                {items.length === 0 ? (
                  <Typography color="textSecondary">暫無任務</Typography>
                ) : (
                  items.map((item) => (
                    <Paper
                      key={item.id}
                      elevation={1}
                      sx={{
                        p: 1,
                        mb: 1,
                        bgcolor: "background.default",
                        borderRadius: 1,
                        transition: "all 0.3s",
                        "&:hover": {
                          bgcolor: "action.hover",
                          transform: "translateY(-2px)",
                          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                        },
                        position: "relative",
                        minWidth: 250,
                        maxWidth: 300,
                      }}
                      draggable
                      onDragStart={(e) => handleDragStart(e, item)}
                    >
                      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                        <DragIndicatorIcon
                          sx={{
                            mr: 0.5,
                            color: "text.secondary",
                            fontSize: 16,
                            cursor: "move",
                          }}
                        />
                        <Box sx={{ flex: 1, mr: 1, overflow: "hidden" }}>
                          <Tooltip
                            title={item.parentName}
                            placement="bottom-start"
                            enterDelay={500}
                          >
                            <div>
                              {" "}
                              {/* 使用 div 作為可聚焦元素 */}
                              <TruncatedTypography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  fontSize: "0.75rem",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.parentName}
                              </TruncatedTypography>
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={item.name}
                            placement="bottom-start"
                            enterDelay={500}
                          >
                            <div>
                              {" "}
                              {/* 使用 div 作為可聚焦元素 */}
                              <TruncatedTypography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                  fontWeight: "bold",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.name}
                              </TruncatedTypography>
                            </div>
                          </Tooltip>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                              mt: 0.5,
                            }}
                          >
                            <StyledChip
                              label={item.versionString}
                              size="small"
                              colorstyle={getColorStyles(
                                "bg-blue-200 text-blue-800"
                              )}
                              sx={{
                                height: 20,
                                "& .MuiChip-label": {
                                  px: 0.5,
                                  fontSize: "0.625rem",
                                },
                              }}
                            />
                            <Tooltip
                              title={
                                <React.Fragment>
                                  開始日期: {formatDate(item.startDate)}
                                  <br />
                                  結束日期: {formatDate(item.endDate)}
                                </React.Fragment>
                              }
                              placement="bottom-start"
                              enterDelay={500}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  flex: 1,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {formatDate(item.startDate)} ~{" "}
                                {item.endDate ? formatDate(item.endDate) : ""}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Tooltip
                            title={
                              <React.Fragment>
                                動作數: {item.actionCount}
                                <br />
                                成本: {formatNumber(item.cost)}
                                <br />
                                預算: {formatNumber(item.budget)}
                              </React.Fragment>
                            }
                            placement="bottom-start"
                            enterDelay={500}
                          >
                            <Typography
                              variant="caption"
                              color={getCostColor(item.cost, item.budget)}
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "block",
                              }}
                            >
                              動作: {item.actionCount} | 花費/預算:{" "}
                              {formatNumber(item.cost)}/
                              {formatNumber(item.budget)}
                            </Typography>
                          </Tooltip>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.25,
                              mt: 0.5,
                            }}
                          >
                            {[
                              {
                                label: item.priorityItem?.label || "無",
                                color:
                                  item.priorityItem?.color ||
                                  "bg-green-200 text-green-800",
                                tooltip: "優先順序",
                              },
                              {
                                label: item.type?.typeName,
                                color: "bg-green-200 text-green-800",
                                tooltip: "類別",
                              },
                              {
                                label: item.assignee?.username,
                                color: "bg-gray-200 text-gray-800",
                                tooltip: "負責人",
                              },
                            ].map((chip, index) => (
                              <Tooltip
                                title={
                                  <React.Fragment>
                                    {chip.tooltip}:{chip.label}
                                  </React.Fragment>
                                }
                                placement="bottom-start"
                                enterDelay={500}
                              >
                                <StyledChip
                                  key={index}
                                  label={chip.label}
                                  size="small"
                                  colorstyle={getColorStyles(chip.color)}
                                  sx={{
                                    height: 20,
                                    "& .MuiChip-label": {
                                      px: 0.5,
                                      fontSize: "0.625rem",
                                    },
                                  }}
                                />{" "}
                              </Tooltip>
                            ))}
                          </Box>
                        </Box>
                        <IconButton
                          size="small"
                          onClick={(e) => handleMenuOpen(e, item)}
                          sx={{
                            position: "absolute",
                            top: 2,
                            right: 2,
                            padding: 0.5,
                          }}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Paper>
                  ))
                )}
                {addingToColumn === status.statusId ? (
                  <Box mt={1}>
                    <TextField
                      fullWidth
                      size="small"
                      value={newItemTitle}
                      onChange={(e) => setNewItemTitle(e.target.value)}
                      onKeyDown={(e) =>
                        e.key === "Enter" && handleAddItem(status.statusId)
                      }
                    />
                    <Box mt={1}>
                      <Button
                        size="small"
                        onClick={() => handleAddItem(status.statusId)}
                      >
                        Add
                      </Button>
                      <Button
                        size="small"
                        onClick={() => setAddingToColumn(null)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenMilestoneSelection}
                    fullWidth
                    sx={{
                      justifyContent: "flex-start",
                      mt: 1,
                      color: "text.secondary",
                      "&:hover": { backgroundColor: "rgba(9, 30, 66, 0.08)" },
                    }}
                  >
                    新增任務
                  </Button>
                )}
              </Paper>
            );
          })}
        </Box>
      )}

      <DropdownMenu
        isOpen={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        currentItem={selectedItem}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        onNew={handleNewAction}
      />

      {isEditModalOpen && selectedItem && (
        <ItemEditModal
          currentItem={selectedItem}
          ItemEditComponent={selectedItem.tableType}
          editMode="popup"
          readOnly={false}
          tableType={selectedItem.tableType}
          parentId={selectedItem.parentId}
          setItemList={setSelectedItem}
          setErrorMessage={setError}
          parentContainerWidth={parentContainerWidth}
          onSave={handleSave}
          onClose={handleCloseModal}
        />
      )}

      <Dialog
        open={isMilestoneSelectionOpen}
        onClose={handleCloseMilestoneSelection}
        className="rounded-lg shadow-lg overflow-hidden"
        PaperProps={{
          style: {
            maxWidth: "500px",
            width: "100%",
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle className="bg-gray-100 text-lg font-semibold p-4 border-b border-gray-200">
          選擇里程碑
        </DialogTitle>
        <DialogContent className="p-0">
          <List
            className="divide-y divide-gray-200 overflow-y-auto"
            style={{ maxHeight: "calc(80vh - 160px)" }}
          >
            {selectedMilestones.map((milestoneId) => {
              const milestone = milestoneMap[milestoneId];
              return (
                <ListItem
                  button
                  key={milestone.id}
                  onClick={() => handleSelectMilestoneForNewItem(milestone)}
                  className="hover:bg-blue-100 transition-colors duration-150 ease-in-out"
                >
                  <ListItemText
                    primary={
                      <span className="text-sm font-medium text-gray-900">
                        {`${milestone.parent.name} > ${milestone.name}`}
                      </span>
                    }
                    secondary={
                      <span className="text-xs text-gray-500">
                        {`v${milestone.version} (${milestone.type?.typeName})`}
                      </span>
                    }
                    className="py-3"
                  />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions className="border-t border-gray-200 bg-gray-50 p-4">
          <Button
            onClick={handleCloseMilestoneSelection}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-150 ease-in-out"
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JiraStyleKanban;
