import itemService from "../../services/db/ItemService";
import { getUser } from "../../services/authService"; // Assuming this is where getUser comes from

// 定義權限常量
const READ_CHILDREN = 1; // 10000
const WRITE = 2; // 0010
const CREATE_CHILDREN = 3; // 100000
const DELETE_CHILDREN = 4; // 0100
const DELETE = 5; // 0100
const BUDGET = 32; // 1000000

export const accessCheck = async (type, id) => {
  try {
    const accessQ = await itemService.getAccessBool(type, id);
    if (Array.isArray(accessQ) && accessQ.length > 0) {
      // 只返回第一筆數據
      const firstAccess = accessQ[0];
      const currentUser = getUser();
      const permission = 0xf & firstAccess.permission;

      if (currentUser.userId === firstAccess.userId) {
        return {
          access: firstAccess.permission,
          read: true,
          readChild: permission >= READ_CHILDREN,
          write: permission >= WRITE,
          createChild: permission >= CREATE_CHILDREN,
          deleteChild: permission >= DELETE_CHILDREN,
          delete: permission >= DELETE,
          budget: firstAccess.permission >= BUDGET,
          readOnly: permission === 0,
        };
      }
    }
    return null; // 如果 accessQ 是空數組或不是數組，返回 null
  } catch (error) {
    console.error("Error in accessCheck:", error);
    return null; // 發生錯誤時返回 null
  }
};

export const getMaxAccess = () => {
  return {
    access: 5,
    read: true,
    readChild: true,
    write: true,
    createChild: true,
    deleteChild: true,
    delete: true,
    budget: false,
    readOnly: false,
  };
};

/*
    public enum PermissionType
    {
        DeleteItem = 5,
        DeleteSubItems = 4,
        AddSubItems = 3,
        WriteItem = 2,
        ReadSubItems = 1,
        ReadItem = 0
    }
 */
