import React, { useCallback } from 'react';
import DropdownSelector from './DropdownSelector';
import { useAssigneeData } from './useAssigneeData';

const AssigneeSelector = React.memo(({ 
  currentAssigneeId, 
  onAssigneeChange, 
  readOnly, 
  itemType, 
  item,
  height, 
  label,
  allowManualInput = false
}) => {
  const { 
    assigneeOptions, 
    currentAssignee, 
    loading, 
    error 
  } = useAssigneeData(itemType, item, currentAssigneeId);

  const handleAssigneeChange = useCallback((selectedItem) => {
    if (!selectedItem) return;    
    onAssigneeChange(selectedItem);
  }, [onAssigneeChange]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (readOnly) {
    return (
      <span className="px-2 py-1 rounded-full text-xs bg-gray-100 text-gray-800">
        {currentAssignee?.label || 'N/A'}
      </span>
    );
  }

  return (
    <DropdownSelector
      currentValue={currentAssignee}
      onValueChange={handleAssigneeChange}
      readOnly={readOnly || (itemType === "TaskAction" && assigneeOptions.length === 0)}
      allowedOptions={assigneeOptions}
      allOptions={assigneeOptions}
      placeholder="輸入email或選擇負責人"
      showDescription={true}
      allowManualInput={allowManualInput}
      inputHeight={height}
      label={label}
    />
  );
});

export default AssigneeSelector;