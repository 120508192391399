import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  useTable,
  useExpanded,
  useSortBy,
  useResizeColumns,
  useBlockLayout,
} from "react-table";
import { VariableSizeList as List } from "react-window";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Tooltip,
  IconButton,
  TextField,
  Stack,
  Paper,
  TableContainer,
  Button,
  Chip,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import itemService from "../../services/db/ItemService";
import ItemEditModal from "../common/ItemEditModal";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  formatDateTime,
  formatDate,
  formatTime,
  getCurrentLocalDateTime,
  getRelativeTime,
} from "../../utils/dateUtils";
import { convertToDTO as convertProjectsToDTO } from "../../types/projectsTypes";
import {
  getChildTableType,
  getTableTypeName,
  getCostColor,
  getColorStyles,
  priorityOrder,
} from "../../utils/utils";
import DropdownMenu from "../common/DropdownMenu_mui";
import { getUser } from "../../services/authService";
import UpdateIcon from "@mui/icons-material/Update";
import { withStyles } from "@mui/styles";
import BudgetEdit from "../Edit/BudgetEdit";
const LargeTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1rem", // 這裡設置字體大小，可以根據需要調整
    maxWidth: "none", // 允許 Tooltip 根據內容自動調整寬度
    whiteSpace: "pre-line", // 保留換行
    fontWeight: "bold", // 可選：使文字加粗
  },
}))(Tooltip);

const StyledTableRow = styled("div")(({ theme }) => ({
  display: "flex",
  "&:hover": {
    backgroundColor: "#e3f2fd",
    "& > div": {
      backgroundColor: "#e3f2fd",
    },
  },
}));
const StyledCard = styled(Card)(({ theme, selected }) => ({
  cursor: "pointer",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  transition: "all 0.3s",
  border: selected ? `2px solid ${theme.palette.primary.main}` : "none",
  boxShadow: selected
    ? `0 0 10px ${theme.palette.primary.main}`
    : theme.shadows[1],
}));
const ReadOnlyTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "rgba(0, 0, 0, 0.87)",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(0, 0, 0, 0.42)",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "rgba(0, 0, 0, 0.87)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgba(0, 0, 0, 0.87)",
  },
});
const StyledTableCell = styled("div")(({ theme, isHeader, align }) => ({
  padding: theme.spacing(1),
  backgroundColor: isHeader ? "#F9FAFB" : "#FFFFFF",
  color: "#333333",
  position: "relative",
  borderRight: "1px solid #e0e0e0",
  "&:last-child": {
    borderRight: "none",
  },
  display: "flex",
  alignItems: "center",
  justifyContent:
    align === "right"
      ? "flex-end"
      : align === "center"
      ? "center"
      : "flex-start",
  textAlign: align,
  overflow: "hidden",
  transition: "background-color 0.2s",
}));

const CellContent = styled("div")({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
});

const NameCell = styled("div")(({ theme, depth }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2 + depth * 2),
  width: "100%",
  overflow: "hidden",
}));

const NameText = styled("span")({
  flex: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const ActionButton = styled(IconButton)({
  padding: 4,
  marginLeft: "auto",
});

const TableWrapper = styled("div")({
  position: "relative",
  height: "calc(100vh - 200px)",
  overflow: "hidden",
});

const TableHeader = styled("div")({
  paddingRight: "17px", // 假設滾動條寬度為17px，根據實際情況調整
  overflow: "hidden",
});

const TableBody = styled("div")({
  height: "calc(100% - 40px)", // 假設表頭高度為40px，根據實際情況調整
  overflow: "auto",

  // 自定義滾動條樣式
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },

  // 針對 Firefox
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
});
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  backgroundColor: "transparent",
  color: "#666666",
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
}));

const StyledChip = styled(Chip)(({ colorstyle }) => ({
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));

const FilterContainer = styled("div")({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
  marginBottom: "20px",
});

const BudgetManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    name: "",
    status: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [parentContainerWidth, setParentContainerWidth] = useState(
    window.innerWidth
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const currentUser = useMemo(() => getUser(), []);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isBudgetOpen, setIsBudgetOpen] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [budgetBalance, setBudgetBalance] = useState(0);
  const [maxBudget, setMaxBudget] = useState(0);
  const [minBudget, setMinBudget] = useState(0);
  const loadCompanies = async () => {
    try {
      setLoading(true);
      const companiesData = await itemService.getCompanyByString("budget", "");
      setCompanies(companiesData);
    } catch (error) {
      console.error("加載專案失敗:", error);
      setError("加載專案失敗");
    } finally {
      setLoading(false);
    }
  };
  const handleCompanySelect = async (company) => {
    try {
      if (company.uId === selectedCompany?.uId) {
        setSelectedCompany(null);
        setData([]);
      } else {
        setSelectedCompany(company);
        setLoading(true);
        if (company.companyID > 0) {
          const projects = await itemService.getProjectString(
            "budget",
            String(company.companyID)
          );
          setData(projects || []);
        } else {
          const personalProjectsData = await itemService.getProjectString(
            "budget-personal",
            String(company.accountId)
          );
          setData(personalProjectsData || []);
        }
      }
    } catch (error) {
      console.error("加載公司詳細信息失敗:", error);
      setError("加載公司詳細信息失敗");
    } finally {
      setLoading(false);
    }
  };

  const handleNewData = useCallback(async () => {
    try {
      if (selectedCompany.companyID > 0) {
        const projects = await itemService.getProjectString(
          "budget",
          String(selectedCompany.companyID)
        );
        setData(projects || []);
      } else {
        const personalProjectsData = await itemService.getProjectString(
          "budget",
          "0"
        );
        setSelectedCompany({
          ...selectedCompany,
          project: personalProjectsData,
        });
        setData(personalProjectsData || []);
      }
    } catch (error) {
      console.error("更新失敗:", error);
      setError("數據更新錯誤");
    }
  }, [selectedCompany, setSelectedCompany, setData]);

  useEffect(() => {
    loadCompanies();
    const handleResize = () => setParentContainerWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const handleDataUpdated = () => {
      handleNewData();
    };
    window.addEventListener("dataUpdated", handleDataUpdated);

    return () => {
      window.removeEventListener("resize", handleResize);
      // 移除自定義事件監聽器
      window.removeEventListener("dataUpdated", handleDataUpdated);
    };
  }, [handleNewData]);

  const handleCancelBudget = () => {
    setAnchorEl(null);
    setIsBudgetOpen(false);
    setBudgetBalance(0);
  };

  const handleSaveBudget = async () => {
    const numericValue = calculateValue(editValue);
    if (isNaN(numericValue)) {
      setError("請輸入有效的數字");
      return;
    }
    if (numericValue < selectedItem.cost) {
      setError("不得小於花費");
      return;
    }
    if (numericValue < 1) {
      setError("預算至少要為1");
      return;
    }
    if (numericValue !== selectedItem.budget) {
      if (
        numericValue - selectedItem.budget > budgetBalance ||
        budgetBalance === undefined
      ) {
        setError("預算不足");
        return;
      }
      const dto = await convertProjectsToDTO({
        tableType: selectedItem.tableType,
        data: {
          ...selectedItem,
          type: null,
          status: null,
          assignee: null,
          subRows: null,
          budget: numericValue,
        },
      });
      await itemService.updateItem("Budget", dto, selectedItem.tableType);
      setBudgetBalance(0);

      if (selectedItem.tableType === "Project") {
        const result = await itemService.getProjectString(
          "wallet",
          String(dto.id)
        );
        if (result.length > 0) {
          setSelectedCompany({
            ...selectedCompany,
            wallet: result[0],
          });
          setCompanies({
            ...companies,
            selectedCompany,
          });
        }
      }
      handleNewData();
    }
    handleCancelBudget();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCancelBudget();
    } else if (event.key === "Enter") {
      const result = calculateValue(editValue);
      if (result)
        setEditValue(`${new Intl.NumberFormat("en-US").format(result)}`);
    }
  };

  const handleOpenMenu = useCallback((event, item) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
    setSelectedItem(item);
  }, []);
  const handleCellClick = useCallback(
    (event, row, columnId, item) => {
      if (columnId === "budget") {
        let access = currentUser.membershipLevelId === 999;
        const projectid =
          item.tableType === "Mission"
            ? item.projectId
            : item.tableType === "Milestone"
            ? item.parentId
            : item.id;
        const project = data.find((p) => p.id === projectid);
      
        if (selectedCompany.companyID > 0) {
          access = access || selectedCompany?.userRoleType === "Administrator";
        } 
        else {
          if (project) {
            access = currentUser.userId === project.accountId;
          }
        }
        if(item.tableType === "Milestone"){
          if (project) {
            access = currentUser.userId === project.accountId;
          }
        }
        else if (item.tableType === "Mission") {
          access = false;
          if (project) {
            const milestone = project.subRows.find(
              (m) => m.id === item.parentId
            );
            if (
              milestone &&
              milestone.teamMember &&
              milestone.teamMember.length > 0
            ) {
              const me = milestone.teamMember.find(
                (t) =>
                  t.accountId === currentUser.userId &&
                  (t.roleName === "CSM" || t.roleName === "PM")
              );
              access = access || me !== undefined;
            }
          }
        }
        if(currentUser.membershipLevelId===999)  access = true;
        if (access) {
          const project = data.find(
            (p) =>
              p.id ===
              (item.tableType === "Mission"
                ? item.projectId
                : item.tableType === "Milestone"
                ? item.parentId
                : item.id)
          );
          if (project) {
            if (item.tableType === "Project") {
              const totalMilestoneBudget = project.subRows.reduce(
                (sum, milestone) => sum + (milestone.budget || 0),
                0
              );
              setBudgetBalance(
                selectedCompany?.wallet?.pointBalance +
                  selectedCompany?.wallet?.credit
              );
              setMaxBudget(
                item.budget +
                  selectedCompany?.wallet?.pointBalance +
                  selectedCompany?.wallet?.credit
              );
              setMinBudget(totalMilestoneBudget < 1 ? 1 : totalMilestoneBudget);
            } else if (
              item.tableType === "Milestone" ||
              item.tableType === "Mission"
            ) {
              const milestone = project.subRows.find(
                (m) =>
                  m.id ===
                  (item.tableType === "Mission" ? item.parentId : item.id)
              );
              if (milestone) {
                // For missions, also calculate remaining budget within the milestone
                const totalMissionBudget = milestone.subRows.reduce(
                  (sum, mission) => sum + (mission.budget || 0),
                  0
                );
                if (item.tableType === "Mission") {
                  const remainingMilestoneBudget =
                    (milestone.budget || 0) - totalMissionBudget;
                  setBudgetBalance(remainingMilestoneBudget);
                  setMaxBudget(item.budget + remainingMilestoneBudget);
                  setMinBudget(item.cost < 1 ? 1 : item.cost);
                } else {
                  // Calculate the sum of all milestone budgets
                  const totalMilestoneBudget = project.subRows.reduce(
                    (sum, milestone) => sum + (milestone.budget || 0),
                    0
                  );
                  // Calculate the remaining budget
                  const remainingBudget =
                    (project.budget || 0) - totalMilestoneBudget;
                  setBudgetBalance(remainingBudget); // Ensure it doesn't go negative
                  setMaxBudget(item.budget + remainingBudget);
                  setMinBudget(totalMissionBudget < 1 ? 1 : totalMissionBudget);
                }
              }
            }
          } else {
            setBudgetBalance(0); // If project not found, set balance to 0
          }
          setEditValue(
            `${new Intl.NumberFormat("en-US").format(row.original.budget)}`
          );
          setAnchorEl(event.currentTarget);
          setSelectedItem(item);
          setIsBudgetOpen(true);
        }
      }
    },
    [
      currentUser,
      selectedCompany,
      data,
      setEditValue,
      setAnchorEl,
      setSelectedItem,
      setIsBudgetOpen,
      setMaxBudget,
      setBudgetBalance,
      setMinBudget,
    ]
  );

  const columns = useMemo(
    () => [
      {
        Header: "名稱",
        accessor: "name",
        width: 300, // Increased width to accommodate the action button
        align: "left",
        tips: ({ value }) => value,
        Cell: ({ value, row }) => (
          <LargeTooltip title={value} placement="bottom">
            <NameCell depth={row.depth}>
              {row.canExpand ? (
                <StyledIconButton {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <ExpandMoreIcon fontSize="small" />
                  ) : (
                    <ChevronRightIcon fontSize="small" />
                  )}
                </StyledIconButton>
              ) : (
                <span style={{ width: 24 }} />
              )}
              <NameText>{value}</NameText>
              <ActionButton
                onClick={(event) => handleOpenMenu(event, row.original)}
                size="small"
              >
                <MoreVertIcon />
              </ActionButton>
            </NameCell>
          </LargeTooltip>
        ),
      },
      {
        Header: "版本",
        accessor: "versionString",
        width: 80,
        align: "left",
        tips: ({ value }) => value,
      },
      {
        Header: "類型",
        accessor: "type",
        width: 120,
        align: "center",
        Cell: ({ value, row }) => (
          <StyledChip
            label={value?.typeName}
            size="small"
            colorstyle={
              row.original.tableType === "Project"
                ? getColorStyles("bg-blue-200 text-gray-800")
                : row.original.tableType === "Milestone"
                ? getColorStyles("bg-green-200 text-gray-800")
                : row.original.tableType === "Mission"
                ? getColorStyles("bg-orange-200 text-gray-800")
                : getColorStyles("bg-yellow-200 text-gray-800")
            }
          />
        ),
        tips: ({ value }) => value?.typeName,
      },
      {
        Header: "負責人",
        accessor: "assignee",
        width: 120,
        align: "center",
        Cell: ({ value }) => (
          <StyledChip
            label={value?.username}
            size="small"
            colorstyle={
              currentUser.userId === value?.accountId
                ? getColorStyles("bg-yellow-200 text-gray-800")
                : getColorStyles("bg-gray-200 text-gray-800")
            }
          />
        ),
        tips: ({ value }) => value?.username,
      },
      {
        Header: "狀態",
        accessor: "status",
        width: 120,
        align: "center",
        Cell: ({ value, row }) => {
          if (row.original.tableType === "TaskAction") return null;
          return (
            <StyledChip
              label={value?.statusName}
              size="small"
              colorstyle={getColorStyles(value?.color)}
            />
          );
        },
        tips: ({ value }) => value?.statusName,
      },
      {
        Header: "優先",
        accessor: "priority",
        width: 100,
        align: "center",
        sortType: (rowA, rowB) => {
          const priorityA = rowA.original.priority || 0;
          const priorityB = rowB.original.priority || 0;
          return priorityA - priorityB;
        },
        Cell: ({ value, row }) => {
          const p = priorityOrder.find((p) => p.id === value);
          return (
            <StyledChip
              label={p?.label}
              size="small"
              colorstyle={getColorStyles(p?.color)}
            />
          );
        },
        tips: ({ value }) => value?.label,
      },
      {
        Header: "期間",
        accessor: "startDate",
        width: 200,
        minWidth: 120,
        align: "left",
        Cell: ({ value, row }) => {
          let formattedValue;
          if (row.original.tableType === "TaskAction") {
            formattedValue = `${formatDateTime(value)}~${formatTime(
              row.original.endDate
            )}`;
          } else {
            formattedValue = `${formatDate(value)}~${formatDate(
              row.original.endDate
            )}`;
          }

          return <CellContent>{formattedValue}</CellContent>;
        },
        tips: ({ value, row }) => {
          if (!row) {
            return "";
          }
          let tooltipValue;
          tooltipValue = `${formatDate(value)}~${formatDate(row.endDate)}`;
          return tooltipValue;
        },
      },
      {
        Header: "預算",
        accessor: "budget",
        width: 200,
        minWidth: 150,
        align: "right",
        Cell: ({ value, row }) => {
          const cost = row.original.cost;
          const budget = row.original.budget;
          const color = getCostColor(cost, budget);
          return (
            <LargeTooltip
              title={`花費: ${new Intl.NumberFormat("en-US").format(
                cost
              )}, 預算: ${new Intl.NumberFormat("en-US").format(budget)}`}
              placement="bottom"
            >
              <CellContent
                style={{ color, cursor: "pointer" }}
                onClick={(event) =>
                  handleCellClick(event, row, "budget", row.original)
                }
              >
                {`${new Intl.NumberFormat("en-US").format(
                  cost
                )}/${new Intl.NumberFormat("en-US").format(budget)}`}
              </CellContent>
            </LargeTooltip>
          );
        },
      },
      {
        Header: "最後更新",
        accessor: "updatedAt",
        width: 150,
        minWidth: 120,
        align: "right",
        Cell: ({ value }) => {
          const relativeTime = getRelativeTime(value);
          let formattedValue;
          formattedValue = `${formatDateTime(value)}`;
          return (
            <LargeTooltip title={formattedValue} placement="bottom">
              <CellContent
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <UpdateIcon style={{ marginRight: "5px", fontSize: "16px" }} />
                {relativeTime}
              </CellContent>
            </LargeTooltip>
          );
        },
      },
    ],
    [handleOpenMenu, currentUser, handleCellClick]
  );

  const filteredData = useMemo(() => {
    if (!Array.isArray(data)) {
      console.error("data is not an array:", data);
      return [];
    }
    return data.filter((item) => {
      const nameMatch = item.name
        .toLowerCase()
        .includes((filters.name || "").toLowerCase());
      const typeMatch =
        !filters.types ||
        filters.types.length === 0 ||
        filters.types.some((type) => type.typeId === item.typeId);
      const statusMatch =
        !filters.statuses ||
        filters.statuses.length === 0 ||
        filters.statuses.some(
          (status) =>
            status.statusId === item.statusId ||
            status.statusName === item.statusName
        );
      return nameMatch && typeMatch && statusMatch;
    });
  }, [data, filters]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data: filteredData,
      autoResetExpanded: false,
      autoResetPage: false,
      disableSortRemove: true,
      defaultCanSort: false,
    },
    useBlockLayout,
    useResizeColumns,
    useSortBy,
    useExpanded
  );

  const handleHeaderClick = useCallback(
    (column) => {
      if (column.canSort && !columnResizing.isResizingColumn) {
        column.toggleSortBy();
      }
    },
    [columnResizing.isResizingColumn]
  );

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  }, []);

  const handleEditClick = useCallback(() => {
    setIsEditModalOpen(true);
    handleCloseMenu();
  }, [handleCloseMenu]);

  const handleInputChange = (e) => {
    setEditValue(e.target.value);
  };
  const calculateValue = (input) => {
    try {
      const cleanInput = input.replace(/[\s,]+/g, "");
      const parts = cleanInput.split(/([+-])/); // 修改这里，去掉 - 的转义
      let result = parseFloat(parts[0]) || 0;

      for (let i = 1; i < parts.length; i += 2) {
        const operator = parts[i];
        const value = parseFloat(parts[i + 1]) || 0;
        if (operator === "+") {
          result += value;
        } else if (operator === "-") {
          result -= value;
        }
      }
      return result;
    } catch (error) {
      console.error("Invalid mathematical expression");
      return null;
    }
  };
  const handleDeleteClick = useCallback(
    async (item) => {
      if (window.confirm(`確定要刪除 ${item.name} 嗎？`)) {
        try {
          await itemService.deleteItem(item.tableType, item.id);
        } catch (error) {
          console.error("刪除失敗:", error);
          setError("刪除數據時發生錯誤");
        }
      }
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleNewClick = useCallback(
    (parentItem) => {
      if (parentItem && parentItem.cost >= parentItem.budget) {
        alert(
          `${getTableTypeName(parentItem.tableType)}預算不足：${
            parentItem.name
          }的成本已超出預算。`
        );
        return;
      }
      const tableType = parentItem
        ? parentItem.tableType !== "User"
          ? getChildTableType(parentItem.tableType)
          : "Project"
        : "Project";
      const newItem = {
        tableType: tableType,
        name: `新${getTableTypeName(tableType)}`,
        startDate: getCurrentLocalDateTime(),
        endDate: null,
        parentId: parentItem ? parentItem.id : null,
        parent: parentItem,
      };
      setSelectedItem(newItem);
      setIsEditModalOpen(true);
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleSave = useCallback(() => {
    try {
      // 保存邏輯...
      setIsEditModalOpen(false);
      //   handleNewData(); // 調用增量更新
    } catch (error) {
      console.error("保存失敗:", error);
      setError("數據保存錯誤");
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const renderCompanyCard = (company) => {
    return (
      <Box
        key={company.companyID}
        width={{
          xs: "100%",
          sm: "calc(33.33% - 8px)",
          md: "calc(20% - 8px)",
        }}
        mb={1}
      >
        <StyledCard
          selected={selectedCompany?.uId === company?.uId}
          onClick={async () => {
            await handleCompanySelect(company);
          }}
        >
          <CardContent>
            <LargeTooltip
              title={
                company.companyName +
                (selectedCompany?.userRoleType === "Administrator" ||
                currentUser?.membershipLevelId === 999 ||
                (company.companyID === 0 &&
                  company.accountId === currentUser.userId)
                  ? `
                    餘額: ${new Intl.NumberFormat("en-US").format(
                      company.wallet?.pointBalance || 0.0
                    )}
                    信用額度: ${new Intl.NumberFormat("en-US").format(
                      company.wallet?.credit || 0.0
                    )}`
                  : "")
              }
              arrow
              placement="top"
            >
              <Typography
                variant="body1"
                component="h3"
                style={{ width: "100%", whiteSpace: "pre-line" }}
              >
                {`${company.companyName}\n${
                  selectedCompany?.userRoleType === "Administrator" ||
                  currentUser?.membershipLevelId === 999 ||
                  (company.companyID === 0 &&
                    company.accountId === currentUser.userId)
                    ? `(${new Intl.NumberFormat("en-US").format(
                        company.wallet?.pointBalance || 0.0
                      )}/${new Intl.NumberFormat("en-US").format(
                        company.wallet?.credit || 0.0
                      )})`
                    : " "
                }`}
              </Typography>
            </LargeTooltip>
          </CardContent>
        </StyledCard>
      </Box>
    );
  };

  const renderCompanyDetails = () => {
    if (!selectedCompany) return null;
    return (
      <Card sx={{ p: 2 }}>
        {selectedCompany.companyID > 0 ? (
          <Stack spacing={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <ReadOnlyTextField
                fullWidth
                label={"公司名稱"}
                value={selectedCompany.companyName || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <ReadOnlyTextField
                fullWidth
                label="簡稱"
                value={selectedCompany.shortName || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="地址"
                value={selectedCompany.address || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="電話"
                value={selectedCompany.phoneNumber || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <ReadOnlyTextField
                fullWidth
                label="統一編號"
                value={selectedCompany.registerId || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="產業"
                value={selectedCompany.industry || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="網站"
                value={selectedCompany.website || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
            </Stack>
            {(selectedCompany.userRoleType === "Administrator" ||
              currentUser.membershipLevelId === 999) && (
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <ReadOnlyTextField
                  fullWidth
                  label="餘額"
                  value={`${new Intl.NumberFormat("en-US").format(
                    selectedCompany?.wallet?.pointBalance || 0.0
                  )}`}
                  slotProps={{
                    input: { readOnly: true },
                  }}
                  variant="standard"
                />
                <ReadOnlyTextField
                  fullWidth
                  label="信用額度"
                  value={`${new Intl.NumberFormat("en-US").format(
                    selectedCompany?.wallet?.credit || 0.0
                  )}`}
                  slotProps={{
                    input: { readOnly: true },
                  }}
                  variant="standard"
                />
              </Stack>
            )}
          </Stack>
        ) : (
          selectedCompany.accountId === currentUser.userId && (
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <ReadOnlyTextField
                fullWidth
                label={"名稱"}
                value={selectedCompany.companyName || ""}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="餘額"
                value={`${new Intl.NumberFormat("en-US").format(
                  selectedCompany?.wallet?.pointBalance || 0.0
                )}`}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
              <ReadOnlyTextField
                fullWidth
                label="信用額度"
                value={`${new Intl.NumberFormat("en-US").format(
                  selectedCompany?.wallet?.credit || 0.0
                )}`}
                slotProps={{
                  input: { readOnly: true },
                }}
                variant="standard"
              />
            </Stack>
          )
        )}
      </Card>
    );
  };

  const rowRenderer = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <StyledTableRow {...row.getRowProps({ style })} style={{ ...style }}>
          {row.cells.map((cell) => {
            const column = cell.column;
            const tooltipContent = column.tips
              ? column.tips({ value: cell.value, row: row.original })
              : "";

            return (
              <StyledTableCell
                key={cell.column.id}
                style={{ width: cell.column.width }}
                align={cell.column.align}
              >
                <LargeTooltip title={tooltipContent} placement="bottom">
                  <div style={{ width: "100%" }}>{cell.render("Cell")}</div>
                </LargeTooltip>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      );
    },
    [prepareRow, rows]
  );

  const getItemSize = (index) => 35; // 假設每行高度為 35px，您可以根據實際情況調整

  if (loading) {
    return (
      <Typography variant="h6" align="center">
        加載中...
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" align="center" color="error">
        錯誤: {error}
      </Typography>
    );
  }

  return (
    <Box mb={3}>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {companies.map(renderCompanyCard)}
      </Box>
      {renderCompanyDetails()}
      <Box mb={1}>
        <Card
          elevation={3}
          style={{
            margin: "20px auto",
            width: "100%",
            backgroundColor: "#FFFFFF",
            color: "#333333",
          }}
        >
          {selectedCompany && (
            <CardContent>
              <FilterContainer>
                <TextField
                  label="名稱篩選"
                  value={filters.name}
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{ flexGrow: 1 }}
                />
                <Button
                  variant="contained"
                  onClick={() =>
                    handleNewClick({
                      ...selectedCompany,
                      id: selectedCompany.companyID,
                    })
                  }
                  style={{ backgroundColor: "#007AFF", color: "#FFFFFF" }}
                >
                  新增專案
                </Button>
              </FilterContainer>
              <TableContainer
                component={Paper}
                style={{ width: "100%", backgroundColor: "#FFFFFF" }}
                display="flex"
              >
                <TableWrapper>
                  <TableHeader>
                    <div
                      {...getTableProps()}
                      style={{ display: "flex", minWidth: "100%" }}
                    >
                      {headerGroups.map((headerGroup) => (
                        <div
                          {...headerGroup.getHeaderGroupProps()}
                          style={{ display: "flex" }}
                        >
                          {headerGroup.headers.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              {...column.getHeaderProps()}
                              isHeader={true}
                              style={{
                                width: column.width,
                                position: "relative",
                              }}
                              onClick={() => handleHeaderClick(column)}
                            >
                              {column.render("Header")}
                              <span style={{ marginLeft: "4px" }}>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDownwardIcon fontSize="small" />
                                  ) : (
                                    <ArrowUpwardIcon fontSize="small" />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                              {column.canResize && (
                                <div
                                  {...column.getResizerProps()}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    height: "100%",
                                    width: "10px",
                                    cursor: "col-resize",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            </StyledTableCell>
                          ))}
                        </div>
                      ))}
                    </div>
                  </TableHeader>
                  <TableBody>
                    <div {...getTableBodyProps()}>
                      <List
                        height={window.innerHeight - 240} // 調整高度以適應新結構
                        itemCount={rows.length}
                        itemSize={getItemSize}
                        width={totalColumnsWidth}
                      >
                        {rowRenderer}
                      </List>
                    </div>
                  </TableBody>
                </TableWrapper>
              </TableContainer>
            </CardContent>
          )}
          {selectedItem && isMenuOpen && (
            <DropdownMenu
              isOpen={isMenuOpen}
              onClose={handleCloseMenu}
              anchorEl={anchorEl}
              currentItem={selectedItem}
              onEdit={handleEditClick}
              onDelete={handleDeleteClick}
              onNew={handleNewClick}
              onEditBudget = {handleEditClick}
            />
          )}
          {isEditModalOpen && selectedItem && (
            <ItemEditModal
              currentItem={selectedItem}
              ItemEditComponent={selectedItem.tableType}
              readOnly={false}
              tableType={selectedItem.tableType}
              parentId={selectedItem.parentId}
              setItemList={handleNewData}
              setErrorMessage={setError}
              parentContainerWidth={parentContainerWidth}
              onSave={handleSave}
              onClose={handleCloseModal}
            />
          )}
          {selectedItem && isBudgetOpen && (
            <Popover
              open={isBudgetOpen}
              anchorEl={anchorEl}
              onClose={handleCancelBudget}
              anchorReference="anchorEl"
            >
              <div className="p-2">
                <div className="flex items-center space-x-2">
                  <IconButton
                    size="small"
                    onClick={() =>
                      setEditValue(
                        new Intl.NumberFormat("en-US").format(minBudget)
                      )
                    }
                    title="最小預算"
                  >
                    <Typography variant="body2">
                      {new Intl.NumberFormat("en-US").format(minBudget)}
                    </Typography>
                  </IconButton>
                  <Typography variant="body2">{"<"}</Typography>
                  <TextField
                    value={editValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    size="small"
                    autoFocus
                    type="text"
                    className="w-24"
                    error={
                      calculateValue(editValue) < minBudget ||
                      calculateValue(editValue) > maxBudget
                    }
                    helperText={
                      calculateValue(editValue) < minBudget ||
                      calculateValue(editValue) > maxBudget
                        ? "預算超出範圍"
                        : ""
                    }
                    slotProps={{
                      input: {
                        style: {
                          borderColor:
                            calculateValue(editValue) < minBudget ||
                            calculateValue(editValue) > maxBudget
                              ? "red"
                              : "",
                        },
                      },
                    }}
                  />
                  <Typography variant="body2">{"<"}</Typography>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setEditValue(
                        new Intl.NumberFormat("en-US").format(maxBudget)
                      )
                    }
                    title="最大預算"
                  >
                    <Typography variant="body2">
                      {new Intl.NumberFormat("en-US").format(maxBudget)}
                    </Typography>
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleSaveBudget}
                    title="儲存"
                    disabled={
                      calculateValue(editValue) < minBudget ||
                      calculateValue(editValue) > maxBudget ||
                      calculateValue(editValue) === selectedItem.budget
                    }
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={handleCancelBudget}
                    title="取消"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </Popover>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default BudgetManagement;
