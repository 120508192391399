import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import * as XLSX from 'xlsx';

const parseExcelFile = (workbook) => {
  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  const headers = data[2].map(header => header ? header.trim() : '');
  const rows = data.slice(3).map(row => {
    const obj = {};
    headers.forEach((header, index) => {
      if (header) {
        obj[header] = row[index] !== undefined ? row[index] : '';
      }
    });
    return obj;
  });
  return { headers, rows };
};

const processOrders = (pcbData, smtData) => {
  const pcbOrders = {};
  const orders = [];

  // Process PCB data
  pcbData.rows.forEach(row => {
    const orderNumber = row['订单号'];
    if (orderNumber) {
      pcbOrders[orderNumber] = {
        orderNumber,
        orderDate: row['交货时间'] || '',
        pcbName: row['品名规格'] || '',
        pcbQuantity: parseInt(row['数量']) || 0,
        pcbAmount: parseFloat(row['实际支付金额']) || 0,
        logisticNumber:row['送货单号'] || '',
      };
    }
  });

  // Process SMT data and merge with PCB data
  smtData.rows.forEach(row => {
    const pcbOrderNumber = row['PCB订单号'];
    const pcbOrder = pcbOrders[pcbOrderNumber];
    if (pcbOrder) {
      const smtQuantity = parseInt(row['数量']) || 0;
      const smtAmount = parseFloat(row['实收金额']) || 0;
      const totalAmount = pcbOrder.pcbAmount + smtAmount;

      orders.push({
        source: "JLCPCB",
        orderNumber: pcbOrderNumber,
        orderDate: pcbOrder.orderDate,
        totalAmount: totalAmount.toFixed(2),
        logisticNumber: pcbOrder.logisticNumber,
        toWarehouse : true,
        orderItems: [
          {
            productName: `PCB-${pcbOrder.pcbName}`,
            specification: pcbOrder.pcbName,
            quantity: pcbOrder.pcbQuantity,
            price: (pcbOrder.pcbAmount).toFixed(2),
            amount: pcbOrder.pcbAmount.toFixed(2)
          },
          {
            productName: `SMT-${row['PCB文件名']}`,
            specification: row['PCB文件名'] || '',
            quantity: smtQuantity,
            price: (smtAmount).toFixed(2),

          }
        ]
      });
    }
  });
  return orders;
};

export const JLCOrderInputDialog = ({ open, onClose, onOrdersParsed }) => {
  const [pcbFile, setPcbFile] = useState(null);
  const [smtFile, setSmtFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileUpload = (event, setFile) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleParse = () => {
    if (!pcbFile || !smtFile) {
      setError('請上傳兩個文件。');
      return;
    }

    const pcbReader = new FileReader();
    const smtReader = new FileReader();

    pcbReader.onload = (e) => {
      try {
        const pcbData = new Uint8Array(e.target.result);
        const pcbWorkbook = XLSX.read(pcbData, { type: 'array' });
        const pcbParsedData = parseExcelFile(pcbWorkbook);

        smtReader.onload = (e) => {
          try {
            const smtData = new Uint8Array(e.target.result);
            const smtWorkbook = XLSX.read(smtData, { type: 'array' });
            const smtParsedData = parseExcelFile(smtWorkbook);

            const processedOrders = processOrders(pcbParsedData, smtParsedData);
            if (processedOrders.length === 0) {
              setError('沒有找到任何匹配的訂單，請確保上傳了正確的文件。');
            } else {
              onOrdersParsed(processedOrders);
              onClose();
            }
          } catch (err) {
            setError('解析SMT文件時出錯: ' + err.message);
            console.error('SMT文件解析錯誤:', err);
          }
        };

        smtReader.readAsArrayBuffer(smtFile);
      } catch (err) {
        setError('解析PCB文件時出錯: ' + err.message);
        console.error('PCB文件解析錯誤:', err);
      }
    };

    pcbReader.readAsArrayBuffer(pcbFile);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>上傳嘉立創PCB和SMT訂單</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          請上傳嘉立創PCB的對賬單Excel文件和SMT訂單賬單Excel文件。
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Button
              variant="contained"
              component="label"
            >
              選擇PCB文件
              <input
                type="file"
                hidden
                accept=".xls,.xlsx"
                onChange={(e) => handleFileUpload(e, setPcbFile)}
              />
            </Button>
            {pcbFile && <Typography variant="caption" sx={{ ml: 1 }}>{pcbFile.name}</Typography>}
          </Box>
          <Box>
            <Button
              variant="contained"
              component="label"
            >
              選擇SMT文件
              <input
                type="file"
                hidden
                accept=".xls,.xlsx"
                onChange={(e) => handleFileUpload(e, setSmtFile)}
              />
            </Button>
            {smtFile && <Typography variant="caption" sx={{ ml: 1 }}>{smtFile.name}</Typography>}
          </Box>
        </Box>
        {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleParse} variant="contained" disabled={!pcbFile || !smtFile}>
          解析訂單
        </Button>
      </DialogActions>
    </Dialog>
  );
};