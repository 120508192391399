// syncService.js
import itemService from './ItemService';
import { getUser, getToken } from '../authService'

class SyncService {
  constructor() {
    this.isSyncing = false;
    this.maxRetries = 1;
    this.retryDelay = 5000; // 5 seconds
    this.latestDataTime = null;
    this.initLastSyncTime();
  }

  initLastSyncTime() {
    const storedTime = localStorage.getItem('lastSyncTime');
    if (storedTime) {
      this.setLastSyncTime(storedTime);
    }
  }

  async syncWithBackend() {
    if (this.isSyncing) return;
    this.isSyncing = true;
    try {
      const hasNewData = await this.downloadFromBackend();
      this.setLastSyncTime(new Date().toISOString());
      if (hasNewData) {
        localStorage.setItem('needToRefresh', 'true');
      }
    } catch (error) {
      console.error('Sync failed:', error);
    } finally {
      this.isSyncing = false;
    }
  }

  async downloadFromBackend() {
    const lastSyncTime = this.getLastSyncTime();
    const stores = ['TeamMembers', 'Project', 'Missions', 'Milestones', 'TaskActions', 'ActionTypes', 'TypesCorrelations', 'TaskRatings', 'Tags', 'TagRelations', 'StatusOptions', 'Comments', 'Access', 'User', 'UserCompanyRelation'];
    let hasNewData = false;

    for (const store of stores) {
      try {
        const method = `get${store}`;
        if (typeof itemService[method] === 'function') {
          const newData = await itemService[method](getToken(), getUser(), lastSyncTime);
          if (newData && newData.length > 0) {
            const newestItemTime = this.getNewestItemTime(newData);
            this.setLatestDataTime(newestItemTime);
            hasNewData = true;
          }
        } 
      } catch (error) {
        console.error(`Error downloading ${store}:`, error);
      }
    }

    return hasNewData;
  }

  setLastSyncTime(time) {
    localStorage.setItem('lastSyncTime', time);
  }

  getLastSyncTime() {
    return localStorage.getItem('lastSyncTime') || null;
  }

  setLatestDataTime(time) {
    this.latestDataTime = this.getLatestTime(this.latestDataTime, time);
  }

  getLatestDataTime() {
    return this.latestDataTime;
  }

  getLatestTime(time1, time2) {
    if (!time1) return time2;
    if (!time2) return time1;
    return new Date(time1) > new Date(time2) ? time1 : time2;
  }

  getNewestItemTime(items) {
    return items.reduce((latest, item) => {
      const itemTime = item.updatedAt || item.createdAt;
      return this.getLatestTime(latest, itemTime);
    }, null);
  }
}

const syncService = new SyncService();

export default syncService;
export const setLastUpdateTime = syncService.setLastSyncTime.bind(syncService);
export const getLastUpdateTime = syncService.getLastSyncTime.bind(syncService);
export const getLatestDataTime = syncService.getLatestDataTime.bind(syncService);
export const getLatestTime = syncService.getLatestTime.bind(syncService);
export const setLatestDataTime = syncService.setLatestDataTime.bind(syncService);