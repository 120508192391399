import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Typography,
  Chip,
  Button,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import QuillEditor from "../common/QuillEditor";
import ItemEditModal from "../common/ItemEditModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DropdownMenu from "../common/DropdownMenu_mui";
import itemService from "../../services/db/ItemService";
import {
  formatDate,
  formatDateTime,
  getCurrentLocalDateTime,
  getRelativeTime,
} from "../../utils/dateUtils";
import {
  getTableTypeName,
  getChildTableTypeName,
  getChildTableType,
  getColorStyles,
} from "../../utils/utils";
import { getUser } from "../../services/authService";
const Icons = {
  Project: (
    <Box component="svg" viewBox="0 0 24 24" width={20} height={20}>
      <path d="M3 3v18h18V3H3zm16 16H5V5h14v14zM7 7h10v2H7V7zm0 4h10v2H7v-2zm0 4h7v2H7v-2z" />
    </Box>
  ),
  Milestone: (
    <Box component="svg" viewBox="0 0 24 24" width={20} height={20}>
      <path d="M4 24h-2v-24h2v24zm18-21.387s-1.621 1.43-3.754 1.43c-3.36 0-3.436-2.895-7.337-2.895-2.108 0-4.075.98-4.909 1.694v12.085c1.184-.819 2.979-1.681 4.923-1.681 3.684 0 4.201 2.754 7.484 2.754 2.122 0 3.593-1.359 3.593-1.359v-12.028z" />
    </Box>
  ),
  Mission: (
    <Box component="svg" viewBox="0 0 24 24" width={20} height={20}>
      <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-4 7h-8v1h8v-1zm0 5h-8v1h8v-1zm0 5h-8v1h8v-1zm-10-11h-6v6h6v-6zm-1 1v4h-4v-4h4zm1 9h-6v6h6v-6zm-1 1v4h-4v-4h4z" />
    </Box>
  ),
  TaskAction: (
    <Box component="svg" viewBox="0 0 24 24" width={20} height={20}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30">
        <circle
          cx="12"
          cy="12"
          r="10"
          fill="none"
          stroke="#4a4a4a"
          stroke-width="2"
        />
        <line
          x1="12"
          y1="12"
          x2="12"
          y2="6"
          stroke="#4a4a4a"
          stroke-width="2"
        />
        <line
          x1="12"
          y1="12"
          x2="16"
          y2="12"
          stroke="#4a4a4a"
          stroke-width="2"
        />
        <rect x="2" y="24" width="4" height="6" fill="#4a4a4a" />
        <rect x="8" y="20" width="4" height="10" fill="#4a4a4a" />
        <rect x="14" y="16" width="4" height="14" fill="#4a4a4a" />
        <rect x="20" y="12" width="4" height="18" fill="#4a4a4a" />
      </svg>{" "}
    </Box>
  ),
  Comment: (
    <Box component="svg" viewBox="0 0 24 24" width={20} height={20}>
      <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm-5 11.5c-.829 0-1.5-.671-1.5-1.5s.671-1.5 1.5-1.5 1.5.671 1.5 1.5-.671 1.5-1.5 1.5zm5 0c-.829 0-1.5-.671-1.5-1.5s.671-1.5 1.5-1.5 1.5.671 1.5 1.5-.671 1.5-1.5 1.5zm5 0c-.829 0-1.5-.671-1.5-1.5s.671-1.5 1.5-1.5 1.5.671 1.5 1.5-.671 1.5-1.5 1.5z" />
    </Box>
  ),
};

const StyledChip = styled(Chip)(({ theme, colorstyle }) => ({
  backgroundColor: colorstyle.backgroundColor,
  color: colorstyle.color,
  "& .MuiChip-label": {
    color: colorstyle.color,
  },
}));
const formatNumber = (num) => {
  return num != null ? num.toLocaleString() : "-";
};
const getItemTypeDetails = (tableType) => {
  switch (tableType) {
    case "Project":
      return { icon: Icons.Project, color: "primary" };
    case "Milestone":
      return { icon: Icons.Milestone, color: "success" };
    case "Mission":
      return { icon: Icons.Mission, color: "warning" };
    case "TaskAction":
      return { icon: Icons.TaskAction, color: "default" };
    default:
      return { icon: Icons.Comment, color: "default" };
  }
};

const HistoryItem = ({
  item,
  level = 0,
  onEdit,
  onAddReply,
  onAddMessage,
  onAddChildItem,
  readOnly = false,
  setError,
  containerRef,
  onUpdateChildItems,
  onSave,
  onRefresh,
  isExpanded: propIsExpanded,
  onToggleExpand,
}) => {
  const [isExpanded, setIsExpanded] = useState(propIsExpanded);
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const itemName = item.name || "Unnamed Item";
  const itemRef = useRef(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const currentUser = getUser();
  useEffect(() => {
    setIsExpanded(propIsExpanded);
  }, [propIsExpanded]);

  const toggleExpand = () => {
    try {
      const newExpandedState = !isExpanded;
      setIsExpanded(newExpandedState);
      onToggleExpand(item.id, newExpandedState);
    } catch (error) {
      console.error("Error in toggleExpand:", error);
      setError("Error expanding/collapsing item");
    }
  };

  const { icon: ItemTypeIcon, color: itemColor } = getItemTypeDetails(
    item.tableType
  );

  const displayVersion = useMemo(() => {
    const display = `${item.preVersion || ""}${
      item.version != null ? item.version : "x"
    }`;
    return item.tableType !== "Project" ? display : "V";
  }, [item]);

  const childPreVersion = useMemo(() => {
    return item.tableType !== "Project" ? `${displayVersion}.` : "V";
  }, [displayVersion, item]);

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
    setEditingItem(null);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  }, []);

  const handleEdit = useCallback(() => {
    setEditingItem(item);
    setIsEditModalOpen(true);
    handleCloseMenu();
  }, [item, handleCloseMenu]);

  const handleDelete = useCallback(
    async (item) => {
      if (window.confirm(`確定要刪除 ${item.name} 嗎？`)) {
        try {
          await itemService.deleteItem(item.tableType, item.id);
        } catch (error) {
          console.error("刪除失敗:", error);
          setError("刪除數據時發生錯誤");
        }
      }
      handleCloseMenu();
    },
    [handleCloseMenu, setError]
  );

  const handleNew = useCallback(
    (parentItem) => {
      if (parentItem && parentItem.cost >= parentItem.budget) {
        alert(
          `${getTableTypeName(parentItem.tableType)}預算不足：${
            parentItem.name
          }的成本已超出預算。`
        );
        return;
      }
      const newItem = {
        tableType: parentItem
          ? getChildTableType(parentItem.tableType)
          : "Project",
        name: `新${getTableTypeName(
          parentItem ? getChildTableType(parentItem.tableType) : "Project"
        )}`,
        startDate: getCurrentLocalDateTime(),
        endDate: null,
        parentId: parentItem ? parentItem.id : null,
        parent: parentItem,
      };
      setEditingItem(newItem);
      setIsEditModalOpen(true);
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleSave = useCallback(() => {
    try {
      handleCloseModal();
    } catch (error) {
      console.error("保存失敗:", error);
      setError("數據保存錯誤");
    }
  }, [handleCloseModal, setError]);

  const handleOpenMenu = useCallback(
    (event) => {
      setEditingItem(item);
      setAnchorEl(event.currentTarget);
      setIsEditModalOpen(false);
      setIsMenuOpen(true);
    },
    [item]
  );

  const handleAddMessage = (parentItem) => {
    try {
      setEditingItem({
        accountId: parentItem.accountId,
        relatedTo: parentItem.tableType,
        relatedId: parentItem.id,
        content: null,
        tableType: "Comment",
      });
      setIsEditModalOpen(true);
    } catch (error) {
      console.error("Error setting new message:", error);
      setError("Error creating new message");
    }
  };

  const renderSelectors = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
    >
      {item.tableType !== "Project" && (
        <Chip
          label={displayVersion}
          size="small"
          sx={{ mr: 1, backgroundColor: "grey.200", color: "grey.800" }}
        />
      )}
      {item.type != null && (
        <StyledChip
          label={item.type.typeName}
          size="small"
          colorstyle={{ backgroundColor: "blue.200", color: "grey.800" }}
          sx={{ mr: 1 }}
        />
      )}
      {item.status != null && (
        <StyledChip
          label={item.status?.statusName || "Unknown status"}
          size="small"
          colorstyle={getColorStyles(item.status?.color)}
          sx={{ mr: 1 }}
        />
      )}
      {item.assignee != null && (
        <Chip
          label={item.assignee?.username || "Unknown assignee"}
          size="small"
          sx={{ mr: 1 }}
        />
      )}
    </Box>
  );

  const renderDateTimeInfo = () => {
    if (item.tableType === "TaskAction") {
      return (
        <Typography variant="body2" color="text.secondary">
          開始時間: {formatDateTime(item.startDate)}
          {item?.endDate && ` ~ ${formatDateTime(item?.endDate)}`}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" color="text.secondary">
          期間: {formatDate(item?.startDate)} ~ {formatDate(item?.endDate)}
        </Typography>
      );
    }
  };

  const renderItemDetails = () => (
    <Box
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>{renderDateTimeInfo()}</Box>
      <Box>
        {item.tableType === "TaskAction" ? (
          <Typography variant="body2" color="text.secondary">
            花費: {formatNumber(item.cost)} 小時
          </Typography>
        ) : (
          (item.cost != null || item.budget != null) && (
            <Typography variant="body2" color="text.secondary">
              花費/預算: {formatNumber(item.cost)}/{formatNumber(item.budget)}
            </Typography>
          )
        )}
      </Box>
    </Box>
  );

  const renderComments = () => {
    if (!item.comments || item.comments.length === 0) return null;

    const sortedComments = [...item.comments].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    const latestComment = sortedComments[0];

    return (
      <Box mt={4} pt={2} borderTop={1} borderColor="divider">
        <Box display="flex" alignItems="center">
          <Button
            onClick={() => setIsCommentsExpanded(!isCommentsExpanded)}
            startIcon={Icons.Comment}
            endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            color={itemColor}
          >
            {`留言(${item.comments.length})`}
          </Button>

          {item.tableType !== "TaskAction" && (
            <>
              <Button
                onClick={() => handleAddMessage(item)}
                color={itemColor}
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
              >
                留言
              </Button>
            </>
          )}
        </Box>
        <Collapse in={!isCommentsExpanded}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Box display="flex" alignItems="center">
                {latestComment.user != null && (
                  <Chip
                    label={latestComment.user?.username || "Unknown assignee"}
                    size="small"
                    sx={{ mr: 1 }}
                  />
                )}
                <Typography variant="caption" color="text.secondary">
                  {`${formatDateTime(
                    latestComment.createdAt
                  )}(${getRelativeTime(latestComment.updatedAt)})`}
                </Typography>
              </Box>
            </Box>
            <QuillEditor
              url={latestComment.content}
              renderMode={true}
              value={null}
              minHeight={40}
              readOnly={true}
            />
          </Paper>
        </Collapse>
        <Collapse in={isCommentsExpanded}>
          {sortedComments.map((c, index) => (
            <Paper
              key={c.commentId || index}
              elevation={1}
              sx={{ p: 2, mb: 2 }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Box display="flex" alignItems="center">
                  {c.user != null && (
                    <Chip
                      label={c.user?.username || "Unknown assignee"}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  )}
                  <Typography variant="caption" color="text.secondary">
                    {`${formatDateTime(c.createdAt)}(${getRelativeTime(
                      c.updatedAt
                    )}更新)`}
                  </Typography>
                </Box>
                {currentUser.userId === c.accountId && (
                  <IconButton
                    onClick={() => handleEditComment(c)}
                    size="small"
                    aria-label="Edit comment"
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              <QuillEditor
                url={c.content}
                renderMode={true}
                value={null}
                minHeight={40}
                readOnly={true}
              />
            </Paper>
          ))}
        </Collapse>
      </Box>
    );
  };

  const renderExpandedChildItems = () => (
    <Box>
      {item.children.map((child) => (
        <HistoryItem
          key={child.id || Math.random()}
          item={{ ...child, preVersion: childPreVersion }}
          level={level + 1}
          onEdit={onEdit}
          onAddReply={onAddReply}
          onAddMessage={onAddMessage}
          onAddChildItem={onAddChildItem}
          childItemType={getChildTableType(item.tableType)}
          readOnly={readOnly}
          setError={setError}
          containerRef={containerRef}
          access={item.access}
          onUpdateChildItems={handleChildItemsUpdate}
          onSave={onSave}
          onRefresh={onRefresh}
          isExpanded={false}
          onToggleExpand={onToggleExpand}
        />
      ))}
    </Box>
  );

  const handleChildItemsUpdate = (updatedItems) => {
    try {
      if (onUpdateChildItems) {
        onUpdateChildItems(item.id, updatedItems);
      }
    } catch (error) {
      console.error("Error updating child items:", error);
      setError("Error updating child items");
    }
  };

  const handleEditComment = (comment) => {
    try {
      setEditingItem({
        ...comment,
        tableType: "Comment",
      });
      setIsEditModalOpen(true);
    } catch (error) {
      console.error("Error editing comment:", error);
      setError("Error editing comment");
    }
  };

  const getChildTableTypeIcon = (childTableType) => {
    switch (childTableType) {
      case "Project":
        return Icons.Project;
      case "Milestone":
        return Icons.Milestone;
      case "Mission":
      case "TaskAction":
        return Icons.Task;
      default:
        return Icons.Comment;
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        ml: level > 0 ? 4 : 0,
      }}
      ref={itemRef}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        pb={1}
        borderBottom={1}
        borderColor={`${itemColor}.200`}
      >
        <Box display="flex" alignItems="center">
          <Box color={`${itemColor}.main`} mr={1}>
            {ItemTypeIcon}
          </Box>
          <Typography variant="h6" color={`${itemColor}.main`}>
            {itemName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          {renderSelectors()}
          <IconButton
            onClick={(event) => handleOpenMenu(event)}
            size="small"
            color={itemColor}
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {renderItemDetails()}

      {item.gcsdescriptionUrl && (
        <Box>
          <QuillEditor
            url={item.gcsdescriptionUrl}
            renderMode={true}
            value={null}
            minHeight={50}
            readOnly={true}
          />
        </Box>
      )}

      {renderComments()}

      {item.children && item.children.length > 0 && (
        <Box mt={1}>
          <Button
            onClick={toggleExpand}
            startIcon={getChildTableTypeIcon(getChildTableType(item.tableType))}
            endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            color={itemColor}
          >
            {isExpanded
              ? `收起 ${getChildTableTypeName(item.tableType)}`
              : `${getChildTableTypeName(item.tableType)} (${
                  item.children.length
                })`}
          </Button>
          <Collapse in={isExpanded}>{renderExpandedChildItems()}</Collapse>
        </Box>
      )}
      {isMenuOpen && (
        <DropdownMenu
          isOpen={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          currentItem={editingItem}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onNew={handleNew}
        />
      )}
      {editingItem && isEditModalOpen && (
        <ItemEditModal
          currentItem={editingItem}
          setCurrentItem={setEditingItem}
          ItemEditComponent={editingItem.tableType}
          readOnly={readOnly}
          tableType={editingItem.tableType}
          parentId={item.parentId}
          setItemList={handleChildItemsUpdate}
          setErrorMessage={setError}
          parentContainerWidth={containerRef.current?.offsetWidth}
          onSave={handleSave}
        />
      )}
    </Paper>
  );
};

export default HistoryItem;
