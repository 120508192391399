import React, { useState, useCallback, useRef } from "react";
import {
  Box,
  Paper,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Typography,
  Container,
} from "@mui/material";
import History from "../History/HistoryView";
import FormFields from "./FormFields";
import ChildItemsList from "./ChildItemsList";
import gcsService from "../../services/db/GcsService";
import { convertToDTO as convertProjectsToDTO } from "../../types/projectsTypes";
import itemService from "../../services/db/ItemService";
import { getTableTypeName } from "../../utils/utils";

const ItemEdit = ({
  item,
  onSave,
  onCancel,
  isNew,
  editMode,
  readOnly,
  itemType,
  preVersion,
  lastVersion,
  childItemType,
  access,
}) => {
  const [editedItem, setEditedItem] = useState({
    ...item,
    tableType: itemType,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const contentRef = useRef(null);

  const handleKeyDown = useCallback((e) => {
    try {
      if (e.key === "Enter" && e.target.tagName !== "TEXTAREA") {
        e.preventDefault();
      }
    } catch (error) {
      console.error("處理按鍵事件時發生錯誤:", error);
      setError("處理按鍵事件時發生錯誤");
    }
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e?.preventDefault();
        if (!isNew && !isChanged) return null;

        setLoading(true);
        setError(null);

        let updatedItem = { ...editedItem };

        if (contentRef.current != null) {
          let url = await gcsService.uploadContent(
            contentRef.current,
            updatedItem.gcsdescriptionUrl
          );
          updatedItem.gcsdescriptionUrl = url.objectName;
        }
        let result = null;
        let dto;
        if (itemType === "Milestone" && Array.isArray(updatedItem.team)) {
          for (let member of updatedItem.team) {
            if (member.Type) {
              dto = await convertProjectsToDTO({
                tableType: "Type",
                data: member.Type,
              });
              dto.typeId = null;
              result = await itemService.createItem("Type", dto);
              member.roleType = result.data.typeId;
            }
            dto = await convertProjectsToDTO({
              tableType: "TeamMember",
              data: member,
            });
            result =
              typeof dto.id === "number" && !isNaN(dto.id)
                ? await itemService.updateItem("TeamMember", dto, "")
                : await itemService.createItem("TeamMember", dto);
          }
        }
        if (updatedItem.Type) {
          dto = await convertProjectsToDTO({
            tableType: "Type",
            data: updatedItem.Type,
          });
          dto.typeId = null;
          result = await itemService.createItem("Type", dto);
          updatedItem.typeId = result.data.typeId;
        }

        if (updatedItem.TypeCorrelation) {
          updatedItem.TypeCorrelation.idType = updatedItem.typeId;
          result = await itemService.createItem(
            "TypeCorrelation",
            updatedItem.TypeCorrelation
          );
        }
        if (updatedItem.StatusOption) {
          updatedItem.StatusOption.statusId = null;
          result = await itemService.createItem(
            "StatusOption",
            updatedItem.StatusOption
          );
          updatedItem.statusId = result.data.statusId;
        }
        dto = await convertProjectsToDTO({
          tableType: itemType,
          data: { ...updatedItem, type: null, status: null, assignee: null },
        });
        result =
          typeof dto.id === "number" && !isNaN(dto.id)
            ? await itemService.updateItem(itemType, dto, "")
            : await itemService.createItem(itemType, dto);
        await onSave(updatedItem);
        setIsChanged(false);
        setEditedItem(updatedItem);
        localStorage.setItem("newData", true);
        return result;
      } catch (error) {
        console.error(`${itemType} 儲存失敗:`, error);
        setError(`${itemType} 儲存失敗: ${error.message}`);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [editedItem, onSave, isNew, isChanged, itemType]
  );

  const setContent = useCallback((content) => {
    try {
      contentRef.current = content;
      setIsChanged(true);
    } catch (error) {
      console.error("設置內容時發生錯誤:", error);
      setError("設置內容時發生錯誤");
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading && !editedItem.id) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={3} sx={{ flexGrow: 1, overflow: "auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", position: "sticky", top: 0, zIndex: 1, backgroundColor: "background.paper" }}>
          <Container maxWidth={false}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="item tabs">
                <Tab label={`編輯${getTableTypeName(itemType)}`} />
                {!isNew && <Tab label="查看歷史" />}
              </Tabs>
              {isChanged && !readOnly && (
                <Box>
                  <Button onClick={onCancel} variant="outlined" sx={{ mr: 1 }}>
                    取消
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={20} />}
                  >
                    {loading ? "儲存中..." : "儲存"}
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
        </Box>

        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
          {activeTab === 0 ? (
            <Box component="form" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
              <FormFields
                editedItem={editedItem}
                setEditedItem={setEditedItem}
                setIsChanged={setIsChanged}
                itemType={itemType}
                setContent={setContent}
                readOnly={readOnly}
                preVersion={preVersion}
                lastVersion={lastVersion}
                access={access}
              />
              {!isNew && itemType !== "TaskAction" && (
                <Box sx={{ mt: 4 }}>
                  <ChildItemsList
                    childItemType={childItemType}
                    setError={setError}
                    parentId={editedItem.id}
                    parentItem={editedItem}
                    readOnly={readOnly}
                    preVersion={
                      itemType === "Project"
                        ? null
                        : `${preVersion}${editedItem.version}`
                    }
                  />
                </Box>
              )}

              {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              )}
            </Box>
          ) : (
            <History
              item={editedItem}
              type={itemType}
              editMode={editMode}
              readOnly={readOnly}
              preVersion={preVersion}
              lastVersion={lastVersion}
              access={access}
            />
          )}
        </Container>
      </Paper>
    </Box>
  );
};

export default ItemEdit;