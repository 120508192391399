import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import DropdownSelector from "./DropdownSelector";
import itemService from "../../../services/db/ItemService";
import { getParentTable } from "../../../utils/utils";

const TypeSelector = React.memo(
  ({
    currentTypeId,
    onTypeChange,
    readOnly,
    itemType,
    parentId,
    height,
    label,
    showDescription = false,
  }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [allTypes, setAllTypes] = useState([]);
    const [parentInfo, setParentInfo] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [error, setError] = useState(null);

    const lastValidPropsRef = useRef({ currentTypeId, readOnly, itemType });
    const lastSelectedStatusRef = useRef(null);

    const fetchStatuses = useCallback(async () => {
      try {
        const typeData = await itemService.getTypes(
          "selector",
          `${itemType}-${getParentTable(itemType)}-${parentId}`
        );
        if (Array.isArray(typeData) && typeData.length > 0) {
          const data = typeData[0];
          let allTypes = data.allTypes.map((type) => ({
            id: type.typeId,
            label: type.typeName,
            description: type.description,
            data: type,
          }));
          setAllTypes(allTypes);

          if (itemType === "Project") setTypes(allTypes);
          else {
            let allowedTypeIds = data.defaultTypes.map((type) => ({
              id: type.typeId,
              label: type.typeName,
              description: type.description,
              data: type,
            }));
            setTypes(allowedTypeIds);
          }
          setParentInfo(data.parentItem);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching statuses:", error);
        setError("載入狀態選項失敗");
        setIsLoading(false);
      }
    }, [itemType, parentId]);

    useEffect(() => {
      fetchStatuses();
    }, [fetchStatuses]);

    useEffect(() => {
      if (
        currentTypeId !== undefined &&
        readOnly !== undefined &&
        itemType !== undefined
      ) {
        lastValidPropsRef.current = { currentTypeId, readOnly, itemType };
      }
    }, [currentTypeId, readOnly, itemType]);

    useEffect(() => {
      if (!isLoading && currentTypeId) {
        const currentStatus =
          types.find((status) => status.id === currentTypeId) ||
          lastSelectedStatusRef.current;
        if (currentStatus) {
          setSelectedType(currentStatus);
          lastSelectedStatusRef.current = currentStatus;
        }
      }
    }, [currentTypeId, types, isLoading]);

    const handleTypeChange = useCallback(
      async (newValue) => {
        if (!newValue.id) {
          const tempId = `temp_${Date.now()}`;
          const newType = {
            id: tempId,
            label: newValue.label,
            description: "",
            Type: {
              typeId: tempId,
              typeName: newValue.label,
              typeCategory: itemType,
              description: "",
              tableType: "Type",
            },
            TypeCorrelation: null,
          };
          if (parentInfo) {
            newType.TypeCorrelation = {
              parentId: parentInfo.typeId,
              idType: tempId,
              tableType: "TypeCorrelation",
            };
          }
          setTypes((prevTypes) => [...prevTypes, newType]);
          setSelectedType(newType);
          onTypeChange(newType);
        } else {
          setSelectedType(newValue);
          const newType = { ...newValue };
          if (typeof newValue.id === "string") {
            newType.Type = {
              typeId: newValue.id,
              typeName: newValue.label,
              typeCategory: itemType,
              description: "",
              tableType: "Type",
            };
          }
          if (parentInfo) {
            newType.TypeCorrelation = {
              parentId: parentInfo.typeId,
              idType: newValue.id,
              tableType: "TypeCorrelation",
            };
          }
          onTypeChange(newType);
        }
      },
      [itemType, parentInfo, onTypeChange]
    );

    const memoizedContent = useMemo(() => {
      const renderReadOnlyStatus = () => {
        if (!selectedType && !lastSelectedStatusRef.current) {
          return null;
        }
        const status = selectedType || lastSelectedStatusRef.current;
        return (
          <span className={`px-2 py-1 rounded-full text-xs ${status.color}`}>
            {status.label}
          </span>
        );
      };

      if (isLoading) {
        return <div className="text-gray-500">正在載入狀態選項...</div>;
      }

      if (error) {
        return <div className="text-red-500">{error}</div>;
      }

      if (readOnly) {
        return renderReadOnlyStatus();
      }

      return (
        <DropdownSelector
          key={types.length}
          currentValue={selectedType || lastSelectedStatusRef.current}
          onValueChange={handleTypeChange}
          readOnly={false}
          allowedOptions={types}
          allOptions={allTypes}
          placeholder="輸入或選擇狀態"
          showDescription={showDescription}
          allowManualInput={true}
          inputHeight={height}
          label={label}
        />
      );
    }, [
      isLoading,
      error,
      selectedType,
      handleTypeChange,
      types,
      readOnly,
      height,
      label,
      allTypes,
      showDescription
    ]);

    return memoizedContent;
  }
);

TypeSelector.propTypes = {
  currentStatusId: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};
export default TypeSelector;
