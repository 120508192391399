import axios from 'axios';
import { getUser, getToken } from '../authService'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
class GcsService {
    constructor() {
        this.endpoints = {
            Upload: '/api/Secure/Upload',
            Read: '/api/Secure/Upload',
        };
        this.axiosInstance = axios.create({
            baseURL: API_BASE_URL,
            timeout: 10000,
        });

        // Bind methods to ensure correct 'this' context
        this.uploadContent = this.uploadContent.bind(this);
        this.readContent = this.readContent.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    async uploadContent(content, url) {
        try {
            let token = getToken();
            let user = getUser();
            if (!token || !user || !user.userId) {
                throw new Error('Authentication information is missing');
            }
            let gcsurl = null;
            if(url && url.endsWith('.html')) gcsurl = url;
            const response = this.axiosInstance.post(this.endpoints.Upload, {'Content':content, 'Url':gcsurl},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'user': user.userId,
                        'Content-Type': 'application/json'
                    }
                }
            );
            let result = await response;
            return result.data;
        } catch (error) {
            this.handleError('上傳內容失敗', error);
        }
    }

    async readContent(objectName) {
        try {
            let token = getToken();
            let user = getUser();
            if (!token || !user || !user.userId) {
                throw new Error('Authentication information is missing');
            }
            
            const response = await this.axiosInstance.get(`${this.endpoints.Read}`,{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user': user.userId,
                    'Content-Type': 'application/json',
                    'Content': objectName
                }
            });
            return response.data;
        } catch (error) {
            this.handleError('讀取內容失敗', error);
            return objectName;
        }
    }

    handleError(message, error) {
        console.error(message, error);
        if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            console.error('No response received:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        throw error;
    }
}

const gcsService = new GcsService();

export default gcsService;