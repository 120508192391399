// TaobaoOrderParser.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography } from '@mui/material';

export const parseTaobaoOrders = (text) => {
  const orders = [];
  const lines = text.split('\n');
  let currentOrder = null;
  let orderContent = [];

  const extractTotalAmount = (content) => {
    const lines = content.split('\n');
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes('(含运费')) {
        if (i > 0) {
          const match = lines[i-1].match(/￥([\d.]+)/);
          if (match) {
            return match[1];
          }
        }
        break;
      }
    }
    return '';
  };

  const extractItems = (content) => {
    const items = [];
    const lines = content.split('\n');
    let currentItem = null;
    let specificationBuffer = [];
    let captureSpec = false;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line.includes('[交易快照]')) {
        if (currentItem) {
          items.push(currentItem);
        }
        currentItem = { 
          productName: line.replace('[交易快照]', '').trim(),
          specification: null,
          price: null,
          quantity: null
        };
        captureSpec = true;
        specificationBuffer = [];
      } else if (currentItem) {
        if (captureSpec && !line.startsWith('￥')) {
          if (line && !line.includes('发货时间：')) {
            specificationBuffer.push(line);
          }
        } else if (line.startsWith('￥') && currentItem.price === null) {
          captureSpec = false;
          if (specificationBuffer.length > 0) {
            let spec = specificationBuffer.join(' ');
            const colonIndex = spec.indexOf(':');
            if (colonIndex !== -1) {
              spec = spec.substring(colonIndex + 1).trim();
            }
            currentItem.specification = spec || null;
          }
          currentItem.price = line.replace('￥', '').trim();
        } else if (line.match(/^\d+$/) && currentItem.quantity === null) {
          currentItem.quantity = line;
        }
      }
    }

    if (currentItem) {
      if (captureSpec && specificationBuffer.length > 0) {
        let spec = specificationBuffer.join(' ');
        const colonIndex = spec.indexOf(':');
        if (colonIndex !== -1) {
          spec = spec.substring(colonIndex + 1).trim();
        }
        currentItem.specification = spec || null;
      }
      items.push(currentItem);
    }

    return items;
  };

  for (const line of lines) {
    const orderMatch = line.match(/(\d{4}-\d{2}-\d{2})订单号:\s*(\d+)/);
    if (orderMatch) {
      if (currentOrder) {
        currentOrder.content = orderContent.filter(l => l.trim() !== '').join('\n');
        currentOrder.totalAmount = extractTotalAmount(currentOrder.content);
        currentOrder.orderItems = extractItems(currentOrder.content);
        currentOrder.source = "淘寶";
        currentOrder.toWarehouse = true;
        orders.push(currentOrder);
      }
      const [, date, number] = orderMatch;
      currentOrder = {
        orderDate: date.trim(),
        orderNumber: number.trim(),
        content: '',
        totalAmount: '',
        source : "淘寶",
        toWarehouse : true,
        orderItems: []
      };
      orderContent = [line];
    } else if (currentOrder) {
      orderContent.push(line);
    }
  }

  if (currentOrder) {
    currentOrder.content = orderContent.filter(l => l.trim() !== '').join('\n');
    currentOrder.totalAmount = extractTotalAmount(currentOrder.content);
    currentOrder.orderItems = extractItems(currentOrder.content);
    currentOrder.source = "淘寶";
    currentOrder.toWarehouse = true;
    orders.push(currentOrder);
  }

  return orders;
};

export const TaobaoOrderInputDialog = ({ open, onClose, onOrdersParsed }) => {
  const [textContent, setTextContent] = useState('');
  const [error, setError] = useState('');

  const handleParse = () => {
    setError('');
    try {
      const parsedOrders = parseTaobaoOrders(textContent);
      if (parsedOrders.length === 0) {
        setError('沒有找到任何訂單，請確保貼上了正確的內容。');
      } else {
        onOrdersParsed(parsedOrders);
        onClose();
      }
    } catch (err) {
      setError('解析訂單時出錯: ' + err.message);
      console.error('解析錯誤:', err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>輸入淘寶訂單</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          label="貼上淘寶訂單文字內容"
          value={textContent}
          onChange={(e) => setTextContent(e.target.value)}
          sx={{ mb: 2, mt: 1 }}
        />
        {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleParse} variant="contained" disabled={!textContent}>
          解析訂單
        </Button>
      </DialogActions>
    </Dialog>
  );
};