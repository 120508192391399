import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Button,
  Box,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { TaobaoOrderInputDialog } from "../Order/TaobaoOrderParser";
import { JLCOrderInputDialog } from "../Order/JLCOrderParser";
import { JLC3DPrintOrderDialog } from "../Order/JLC3DPrintOrderParser";
import { ConsolidatedShippingInputDialog } from "../Order/ConsolidatedShippingOrderParser";
import TreeList from "../Order/TreeList";
import EditableTreeList from "../Order/EditableTreeList";
import UpdateOrderDialog from "../Order/UpdateOrderDialog";
import itemService from "../../services/db/ItemService";

const OrderManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const [existingOrders, setExistingOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [openTaobaoDialog, setOpenTaobaoDialog] = useState(false);
  const [openJLCDialog, setOpenJLCDialog] = useState(false);
  const [openJLC3DDialog, setOpenJLC3DDialog] = useState(false);
  const [openConsolidatedShippingDialog, setOpenConsolidatedShippingDialog] =
    useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchExistingOrders();
  }, []);

  const fetchExistingOrders = async () => {
    setIsLoading(true);
    try {
      const orders = await itemService.getOrderString("ninclude", "closed");
      setExistingOrders(orders);
    } catch (error) {
      console.error("Failed to fetch existing orders:", error);
    }
    setIsLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTaobaoOrdersParsed = (parsedOrders) => {
    setNewOrders([...newOrders, ...parsedOrders]);
  };

  const handleJLCOrdersParsed = (parsedOrders) => {
    setNewOrders([...newOrders, ...parsedOrders]);
  };

  const handleJLC3DOrdersParsed = (parsedOrders) => {
    setNewOrders([...newOrders, ...parsedOrders]);
  };

  const handleConsolidatedShippingOrdersParsed = (parsedOrders) => {
    setNewOrders([...newOrders, ...parsedOrders]);
  };

  const handleAddNewOrder = () => {
    const newOrder = {
      orderId: `NEW-${Date.now()}`,
      source: "",
      orderNumber: "",
      orderDate: new Date().toISOString().split("T")[0],
      totalAmount: 0,
      weight: 0,
      volume: 0,
      logisticNumber: "",
      toWarehouse: false,
      orderItems: [],
      logistics: {
        trackingNumber: "",
        logisticsCompany: "",
        shippingDate: "",
        estimatedDeliveryDate: "",
        actualDeliveryDate: "",
      },
    };
    setNewOrders([...newOrders, newOrder]);
  };

  const handleUpdateExistingOrder = (order) => {
    setSelectedOrder(order);
    setOpenUpdateDialog(true);
  };

  const handleUpdateNewOrder = (updatedOrder) => {
    setNewOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.orderId === updatedOrder.orderId ? updatedOrder : order
      )
    );
  };

  const submitNewData = async () => {
    setIsLoading(true);
    const updatedNewOrders = [...newOrders];
    for (let i = updatedNewOrders.length - 1; i >= 0; i--) {
      try {
        const result = await itemService.createItem(
          "Order",
          updatedNewOrders[i]
        );
        if (result.success) {
          updatedNewOrders.splice(i, 1);
        }
      } catch (error) {
        console.error("Failed to create order:", error);
      }
    }
    setNewOrders(updatedNewOrders);
    fetchExistingOrders();
    setIsLoading(false);
  };

  const handleOrderUpdated = async (updatedOrder) => {
    try {
      setExistingOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.orderId === updatedOrder.orderId ? updatedOrder : order
        )
      );
    } catch (error) {
      console.error("Failed to update order:", error);
    }
    setOpenUpdateDialog(false);
  };

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="現有訂單" />
          <Tab label="新增訂單列表" />
        </Tabs>
      </Box>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && tabValue === 0 && (
        <Paper sx={{ p: 2 }}>
          <TreeList
            orders={existingOrders}
            onUpdateOrder={handleUpdateExistingOrder}
          />
        </Paper>
      )}
      {!isLoading && tabValue === 1 && (
        <>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              onClick={handleAddNewOrder}
              sx={{ mr: 1 }}
            >
              新增訂單
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenTaobaoDialog(true)}
              sx={{ mr: 1 }}
            >
              導入淘寶訂單
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenJLCDialog(true)}
              sx={{ mr: 1 }}
            >
              導入嘉立創PCB訂單
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenJLC3DDialog(true)}
              sx={{ mr: 1 }}
            >
              導入嘉立創3D打印訂單
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenConsolidatedShippingDialog(true)}
              sx={{ mr: 1 }}
            >
              導入集運倉訂單
            </Button>
            <Button
              variant="contained"
              onClick={submitNewData}
              disabled={newOrders.length === 0}
            >
              上傳
            </Button>
          </Box>
          <Paper sx={{ p: 2 }}>
            <EditableTreeList
              orders={newOrders}
              onUpdateOrder={handleUpdateNewOrder}
            />
          </Paper>
        </>
      )}
      <TaobaoOrderInputDialog
        open={openTaobaoDialog}
        onClose={() => setOpenTaobaoDialog(false)}
        onOrdersParsed={handleTaobaoOrdersParsed}
      />
      <JLCOrderInputDialog
        open={openJLCDialog}
        onClose={() => setOpenJLCDialog(false)}
        onOrdersParsed={handleJLCOrdersParsed}
      />
      <JLC3DPrintOrderDialog
        open={openJLC3DDialog}
        onClose={() => setOpenJLC3DDialog(false)}
        onOrdersParsed={handleJLC3DOrdersParsed}
      />
      <ConsolidatedShippingInputDialog
        open={openConsolidatedShippingDialog}
        onClose={() => setOpenConsolidatedShippingDialog(false)}
        onOrdersParsed={handleConsolidatedShippingOrdersParsed}
      />
      {selectedOrder && (
        <UpdateOrderDialog
          open={openUpdateDialog}
          order={selectedOrder}
          onClose={() => setOpenUpdateDialog(false)}
          onUpdate={handleOrderUpdated}
        />
      )}
    </Container>
  );
};

export default OrderManagement;
